import React, { useEffect, useMemo } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { options as defaultOptions } from './options';
import { useDispatch, useSelector } from 'react-redux';
import {  getTempDistribution } from '../../../store/selectors/charts/tempDistribution/tempDistributionSelector';
import zip from 'lodash/zip';
import { max } from 'lodash';
import { setTempDistributionData } from '../../../store/actions/charts/tempDistribution/tempDistribution';
import { Spinner } from '../../common/Spinner/Spinner';
import { makeStyles } from '@material-ui/core';
import Socket from '../../../socket/socket';
import { getHeaderTopMenuBtnStatus } from '../../../store/selectors/header/headerSelector';
import { getStatusRightPanel } from '../../../store/selectors/rightPanel/rightPanel';
import { getStatusLeftPanel } from '../../../store/selectors/leftPanel/leftPanelSelector';

const useStyles = makeStyles({
  spinnerContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
  }
});

export const TempDistribution = (): JSX.Element => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const tempDistribution = useSelector(getTempDistribution);
  const lengthX = tempDistribution.lengthX;
  const maxLengthX = useMemo(() => max(tempDistribution.lengthX), [tempDistribution.lengthX]);

  const topMenuIsHide = useSelector(getHeaderTopMenuBtnStatus);
  const rightMenuIsHide = useSelector(getStatusRightPanel);
  const leftMenuIsHide = useSelector(getStatusLeftPanel);


  useEffect(() => {
    const socket = new Socket();

    socket.start()
      .then(() => {
        socket.emmit('SendWidthTDistributions', 'HTS_0,HTS_1,HTS_2,HTS_3,HTS_4,HTS_5,HTS_6,HTS_7,TSolidusThicknessValues', 0.999, 1.002)
          .catch(function (err) {
            return console.error(err.toString());
          }); 
        socket.on(dispatch, setTempDistributionData, 'ReceiveWidthTDistributions');
      });

    return () => {
      socket.disconnect();
    };
    
  }, [dispatch]);



  const lineHts0 = useMemo(() => ({
    name: 'Temperature Curves - Mold',
    type: 'spline',
    color: 'green',
    tooltip: {
      headerFormat: '',
      pointFormat: '{point.y}',
    },
    marker: {
      enabled: false
    },
    data: tempDistribution.data?.HTS_0 && zip(lengthX, tempDistribution.data?.HTS_0)
  }), [tempDistribution.data?.HTS_0, lengthX]); 

  const lineHts1 = useMemo(() => ({
    name: 'Temperature Curves - Ring',
    type: 'spline',
    color: '#B8860B',
    tooltip: {
      headerFormat: '',
      pointFormat: '{point.y}',
    },
    marker: {
      enabled: false
    },
    data: tempDistribution.data?.HTS_1 && zip(lengthX, tempDistribution.data?.HTS_1)
    
  }), [lengthX, tempDistribution.data?.HTS_1]);

  const lineHts2 = useMemo(() => ({
    name: 'Temperature Curves - S1',
    type: 'spline',
    color: '#000000',
    tooltip: {
      headerFormat: '',
      pointFormat: '{point.y}',
    },
    marker: {
      enabled: false
    },
    data: tempDistribution.data?.HTS_2 && zip(lengthX, tempDistribution.data?.HTS_2)
    
  }), [lengthX, tempDistribution.data?.HTS_2]);

  const lineHts3 = useMemo(() => ({
    name: 'Temperature Curves - S2-3',
    type: 'spline',
    color: '#9932CC',
    tooltip: {
      headerFormat: '',
      pointFormat: '{point.y}',
    },
    marker: {
      enabled: false
    },
    data: tempDistribution.data?.HTS_3 && zip(lengthX, tempDistribution.data?.HTS_3)
    
  }), [lengthX, tempDistribution.data?.HTS_3]);

  const lineHts4 = useMemo(() => ({
    name: 'Temperature Curves - S4-6',
    type: 'spline',
    color: '#AA0000',
    tooltip: {
      headerFormat: '',
      pointFormat: '{point.y}',
    },
    marker: {
      enabled: false
    },
    data: tempDistribution.data?.HTS_4 && zip(lengthX, tempDistribution.data?.HTS_4)
    
  }), [lengthX, tempDistribution.data?.HTS_4]);

  const lineHts5 = useMemo(() => ({
    name: 'Temperature Curves - S7-8',
    type: 'spline',
    color: '#006400',
    tooltip: {
      headerFormat: '',
      pointFormat: '{point.y}',
    },
    marker: {
      enabled: false
    },
    data: tempDistribution.data?.HTS_5 && zip(lengthX, tempDistribution.data?.HTS_5)
    
  }), [lengthX, tempDistribution.data?.HTS_5]);

  const lineHts6 = useMemo(() => ({
    name: 'Temperature Curves - S9-10',
    type: 'spline',
    color: '#FF8C00',
    tooltip: {
      headerFormat: '',
      pointFormat: '{point.y}',
    },
    marker: {
      enabled: false
    },
    data: tempDistribution.data?.HTS_6 && zip(lengthX, tempDistribution.data?.HTS_6)
    
  }), [lengthX, tempDistribution.data?.HTS_6]);

  const lineHts7 = useMemo(() => ({
    name: 'Curves Mahine End',
    type: 'spline',
    color: '#000000',
    tooltip: {
      headerFormat: '',
      pointFormat: '{point.y}',
    },
    marker: {
      enabled: false
    },
    data: tempDistribution.data?.HTS_7 && zip(lengthX, tempDistribution.data?.HTS_7)
    
  }), [lengthX, tempDistribution.data?.HTS_7]);


  const solidLine = useMemo(() => ({
    name: 'Solidification Front',
    type: 'spline',
    color: 'red',
    tooltip: {
      headerFormat: '',
      pointFormat: '{point.y}',
    },
    marker: {
      enabled: false
    },
    dashStyle: 'dash',
    data: tempDistribution.data?.TSolidusThicknessValues && zip(tempDistribution.lengthXForSolid, tempDistribution.data?.TSolidusThicknessValues),
    yAxis: 1
    
    
  }), [tempDistribution.data?.TSolidusThicknessValues, tempDistribution.lengthXForSolid]);

  const scannerAct2 = useMemo(() => ({
    name: 'ScannerAct-2',
    type: 'spline',
    color: '#000099',
    tooltip: {
      headerFormat: '',
      pointFormat: '{point.y}',
    },
    data: [[ 0, tempDistribution.scannerAct2]]
  }), [tempDistribution.scannerAct2]);

  const scannerAct2_v1 = useMemo(() => ({
    name: 'Scanner-2.Version-1',
    type: 'spline',
    color: '#0000AA',
    tooltip: {
      headerFormat: '',
      pointFormat: '{point.y}',
    },
    data: [[20, tempDistribution.scannerAct2_v1]]
  }), [tempDistribution.scannerAct2_v1]);


  const scannerAct2_v2 = useMemo(() => ({
    name: 'Scanner-2.Version-2',
    type: 'spline',
    color: '#0000СС',
    tooltip: {
      headerFormat: '',
      pointFormat: '{point.y}',
    },
    data: [[0, tempDistribution.scannerAct2_v2]]
  }), [tempDistribution.scannerAct2_v2]);

  const scannerAct2_v3 = useMemo(() => ({
    name: 'Scanner-2.Version-3',
    type: 'spline',
    color: '#0000FF',
    tooltip: {
      headerFormat: '',
      pointFormat: '{point.y}',
    },
    data: [[0, tempDistribution.scannerAct2_v3]]
  }), [tempDistribution.scannerAct2_v3]);

  const temperatureSetpoint = useMemo(() => ({
    name: 'Temperature setpoint',
    type: 'spline',
    color: '#800080',
    tooltip: {
      headerFormat: '',
      pointFormat: '{point.y}',
    },
    data: [[0, tempDistribution.temperatureSetpoint]]
  }), [tempDistribution.temperatureSetpoint]);

  const scannerMax = useMemo(() => ({
    name: 'Scanner Max',
    type: 'spline',
    color: '#000000',
    tooltip: {
      headerFormat: '',
      pointFormat: '{point.y}',
    },
    data: [[0, tempDistribution.scannerMax]]
  }), [tempDistribution.scannerMax]);
  
  

  const tempDistributionOptions = useMemo(() => ({
    ...defaultOptions,
    xAxis: defaultOptions.xAxis.map(item => ({
      ...item,
      max: maxLengthX && Math.floor(maxLengthX),
    })),
    series: [lineHts0, lineHts1, lineHts2, lineHts3, lineHts4, lineHts5, lineHts6, lineHts7, solidLine, scannerAct2, scannerAct2_v1, scannerAct2_v2, scannerAct2_v3, temperatureSetpoint, scannerMax],
  }), [maxLengthX, lineHts0, lineHts1, lineHts2, lineHts3, lineHts4, lineHts5, lineHts6, lineHts7, solidLine, scannerAct2, scannerAct2_v1, scannerAct2_v2, scannerAct2_v3, temperatureSetpoint, scannerMax ]);

  if (!tempDistribution.data) {
    return <div className={classes.spinnerContainer}><Spinner /></div>;
  }

  const styles = topMenuIsHide ? { height: 'calc( 100vh - 100px )', minHeight: '650px'} : { height: 'calc( 100vh - 260px )', minHeight: '650px'};
  const chartKey = `${topMenuIsHide}${rightMenuIsHide}${leftMenuIsHide}`;

  return (  
    <div className="tempDistribution">
      <HighchartsReact
        key={chartKey}
        containerProps={{ style: styles }} 
        highcharts={Highcharts}  
        options={tempDistributionOptions}
      /> 
    </div>

  );
};
