import React, { useState, useMemo, useEffect } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import classNames from 'classnames';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { IRightPanelItem } from '../../interfaces/config/IRightPanelItem';
import { useAppSelector } from '../../hooks/useAppSelector';
import { getStatusRightPanel } from '../../store/selectors/rightPanel/rightPanel';
import { useDispatch } from 'react-redux';
import { closeRightPanel, openRightPanel } from '../../store/actions/rightPanel/rightPanelAction';
import ConfigParser from '../../utils/helper/configParser';

const useStyles = makeStyles({
  toggleBtn: {
    minWidth: 50,
    height: 50,
    borderRadius: 0,
  },
  asidePanelShadow: {
    boxShadow: '-1px 2px 4px rgb(0, 0, 0, .5 )'
  },
  buttonsList: {
    padding: '20px  15px',
  },
  buttonsListItem: {
    marginBottom: 15,
    '&:last-child': { 
      marginBottom: 0,
    },
    '& > a': {
      width: '100%'
    }
  }
});

const RightPanel: React.FC = () => {
  const config = ConfigParser.getInstance();

  const isOpen = useAppSelector(getStatusRightPanel);
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const currentBottomItem = useMemo(() => { 
    return config.getBottomPanelItemByPath(location.pathname);
  }, [config, location.pathname]);

  const rightPanelItems = useMemo(() => {
    return currentBottomItem && config.getRightPanelItems(currentBottomItem.name);
  }, [config, currentBottomItem]); 

  const activeItem = useMemo(() => {
    return rightPanelItems?.find(item => location.pathname.includes(item.name));
  }, [rightPanelItems, location]);

  const [rightPanelItem, setRightPanelItem] = useState(activeItem); 

  useEffect(() => {
    setRightPanelItem(activeItem);
  }, [activeItem]);


 
  const isActiveButton = (path: string): boolean =>location.pathname.includes(path);
  const isDisableToogle = !(activeItem?.buttons.length || activeItem?.panel.length) || false;

  const handleToggle = () => { 
    if (!isDisableToogle && !isOpen) { 
      dispatch(openRightPanel());
    } else {
      dispatch(closeRightPanel());
    }
  };

  const handleTabClick = (item: IRightPanelItem) => {
    if (item.url) { 
      if (!item.buttons.length) {
        
        history.push(item.url);    
        dispatch(closeRightPanel());

      } else {  
        dispatch(openRightPanel());
      }
      setRightPanelItem(item);

    }
  };

  const rightPanelContent = () => { 

    if (rightPanelItem?.buttons?.length) {
      return (
        <div className={classes.buttonsList}>
          {
            rightPanelItem.buttons.map((item, index) => (
              <div key={index} className={classes.buttonsListItem}>
                <Button
                  component={Link}
                  to={item.url}
                  color={isActiveButton(item.url) ?  'primary' : 'default'}
                  variant="contained"
                  disableRipple
                  disableFocusRipple
                >
                  {item.text}
                </Button>
              </div>
            ))  
          }
        </div>
      );
    }

    if (activeItem?.panel.length) {
      return (
        <Grid container spacing={0}>
          {
            activeItem.panel.map((item, index) => {
              const { col, component: Component } = item;
        
              return (
                <Grid key={index} item lg={col}>
                  {!!Component && <Component />}
                </Grid>
              );
            })
          }
        </Grid>
      );
    }

    return null;
  };
  
  return (
    <div className={classNames('layout-aside', {open: isOpen})}>
      <div className="layout-aside__inner">
        <div className={classNames('aside-panel', classes.asidePanelShadow)}>
          <div className="vertical-autoscroll">
            <div className="aside-panel_inner">
              {rightPanelContent()}
            </div>
          </div>
        </div>
        <div className="aside-line">
          <div className="vertical-autoscroll">
            <Button 
              className={classes.toggleBtn}
              onClick={handleToggle}
              disableFocusRipple
              disableRipple
              disabled={isDisableToogle}
            >
              { isOpen ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </Button>
            <div className="aside-menu">
              {
                !!rightPanelItems && rightPanelItems.map(item =>
                  <div className="aside-menu__item"  key={item.name}>
                    <Button
                      color="inherit"
                      className={classNames(
                        'aside-menu__link', 
                        { active: rightPanelItem ? rightPanelItem.name === item.name : false }
                      )}
                      onClick={() => handleTabClick(item)} 
                    >
                      {item.text} 
                    </Button>
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RightPanel;