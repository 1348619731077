export type ScaleCompositionState = {
  lengthX: number[] | null,
  scaleFayalit: number[] | null,
  scaleWustit: number[] | null,
  scaleMagnetit: number[] | null,
  scaleHematit: number[] | null,
  straighteningArea: number | null,
  coolZones: number[][] | null,
  segmentLine: number[][] | null,
  segments: number[] | null,
  solidification: number | null,
  warning: number | null,
  warm: number | null,
  endOfStrandGuide: number | null,
  coolZone: {label: string, value: number}[] | null
} 

export enum ScaleCompositionActionTypes {
  SET_SCALE_FAYALIT_DATA = 'SET_SCALE_FAYALIT_DATA',
  SET_SCALE_WUSTIT_DATA = 'SET_SCALE_WUSTIT_DATA',
  SET_SCALE_MAGNETIT_DATA = 'SET_SCALE_MAGNETIT_DATA',
  SET_SCALE_HEMATIT_DATA = 'SET_SCALE_HEMATIT_DATA',
}

type SetScaleFayalitData = {
  type: ScaleCompositionActionTypes.SET_SCALE_FAYALIT_DATA,
  payload: number[]
};
type SetScaleWustitData = {
  type: ScaleCompositionActionTypes.SET_SCALE_WUSTIT_DATA,
  payload: number[]
};
type SetScaleMagnetitData = {
  type: ScaleCompositionActionTypes.SET_SCALE_MAGNETIT_DATA,
  payload: number[]
};
type SetScaleHematitData = {
  type: ScaleCompositionActionTypes.SET_SCALE_HEMATIT_DATA,
  payload: number[]
};


export type ScaleCompositionAction = 
  SetScaleFayalitData |
  SetScaleWustitData |
  SetScaleMagnetitData|
  SetScaleHematitData;