import React, { useEffect, useMemo } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { SolidificationArrowChart } from '../SolidificationArrowChart/SolidificationArrowChart';
import { defaultOptions } from './defaultOptions';
import { defaultOptions as optionsArrowChart } from '../SolidificationArrowChart/defaultOptions'; 
import { useDispatch, useSelector } from 'react-redux';
import {
  getSolidificationArrowChartLine1,
  getSolidificationArrowChartLine2,
  getSolidificationArrowChartLine3,
  getSolidificationArrowChartLine4,
  getSolidificationArrowChartLine5,
  // getSolidificationArrowChartArrow,
} from '../../../store/selectors/charts/solidificationArrowChart/solidificationArrowChartSelector';
import zip from 'lodash/zip';
import { generateConstraintLines } from '../../../utils/charts/generateConstraintLines';
import NavigationTwoToneIcon from '../../../assets/images/arrow_solid_mini_chart.svg';
import { fetchShellFSRequest } from '../../../store/actions/charts/shellFS/shellFSActions';
import { getStrandShellThickness } from '../../../store/selectors/charts/strandShellThickness/strandShellThicknessSelector';
import { max } from 'lodash';
import { Spinner } from '../../common/Spinner/Spinner';
import { makeStyles } from '@material-ui/core';
import { getCommonLines } from '../../../store/selectors/charts/commonLines/commonLinesSelector';
import { getHeaderTopMenuBtnStatus } from '../../../store/selectors/header/headerSelector';
import { getStatusRightPanel } from '../../../store/selectors/rightPanel/rightPanel';
import { getStatusLeftPanel } from '../../../store/selectors/leftPanel/leftPanelSelector';
import { isFullHD } from '../../../utils/helper/detectScreen';

const useStyles = makeStyles({
  spinnerContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
  }
});
   

export const StrandShellThickness = (): JSX.Element => {  

  // solidificationArrowChartSelector
  const line1MiniChartData = useSelector(getSolidificationArrowChartLine1);
  const line2MiniChartData = useSelector(getSolidificationArrowChartLine2);
  const line3MiniChartData = useSelector(getSolidificationArrowChartLine3);
  const line4MiniChartData = useSelector(getSolidificationArrowChartLine4);
  const line5MiniChartData = useSelector(getSolidificationArrowChartLine5);

  const classes = useStyles();
  const dispatch = useDispatch();
  const strandShellThickness = useSelector(getStrandShellThickness);
  const maxLengthX = useMemo(() => max(strandShellThickness.data?.Length), [strandShellThickness.data?.Length]);
  const commonLines = useSelector(getCommonLines);

  const topMenuIsHide = useSelector(getHeaderTopMenuBtnStatus);
  const rightMenuIsHide = useSelector(getStatusRightPanel);
  const leftMenuIsHide = useSelector(getStatusLeftPanel);

  useEffect(() => {

    if (strandShellThickness.data === null) {
      dispatch(fetchShellFSRequest());
    }
  }, [dispatch, strandShellThickness.data]);  

  const lineFL0 = useMemo(() => ({
    name: 'Fs 1.00',
    type: 'spline', 
    color: '#A52A2A',
    data: strandShellThickness.data?.Length  &&
     zip(strandShellThickness.data?.Length, strandShellThickness.data?.ThicknessTopFl0_00)
  }), [
    strandShellThickness.data?.ThicknessTopFl0_00,
    strandShellThickness.data?.Length
  ]); 

  const lineFL1 = useMemo(() => ({
    name: 'Fs 0.80',
    type: 'spline',
    color: '#8B4513',
    data: strandShellThickness.data?.ThicknessTopFl0_20 &&
     zip(strandShellThickness.data?.Length, strandShellThickness.data?.ThicknessTopFl0_20)
  }), [
    strandShellThickness.data?.ThicknessTopFl0_20,
    strandShellThickness.data?.Length
  ]);

  const lineFL2 = useMemo(() => ({
    name: 'Fs 0.60',
    type: 'spline',
    color: '#D2691E',
    data: strandShellThickness.data?.ThicknessTopFl0_40 && 
    zip(strandShellThickness.data?.Length, strandShellThickness.data?.ThicknessTopFl0_40)
  }), [
    strandShellThickness.data?.ThicknessTopFl0_40,
    strandShellThickness.data?.Length
  ]);

  const lineFL3 = useMemo(() => ({
    name: 'Fs 0.40',
    type: 'spline',
    color: '#BC8F8F', 
    data: strandShellThickness.data?.ThicknessTopFl0_60 &&
     zip(strandShellThickness.data?.Length, strandShellThickness.data?.ThicknessTopFl0_60)
  }), [
    strandShellThickness.data?.ThicknessTopFl0_60,
    strandShellThickness.data?.Length
  ]); 

  const lineFL4 = useMemo(() => ({
    name: 'Fs 0.20',
    type: 'spline',
    color: '#BC8F8F',
    data: strandShellThickness.data?.ThicknessTopFl0_80 && 
    zip(strandShellThickness.data?.Length, strandShellThickness.data?.ThicknessTopFl0_80)
  }), [
    strandShellThickness.data?.ThicknessTopFl0_80,
    strandShellThickness.data?.Length
  ]);

  const lineFL5 = useMemo(() => ({
    name: 'Fs 0.00',
    type: 'spline',
    color: '#DEB887',
    data: strandShellThickness.data?.ThicknessTopFl1_00 &&
     zip(strandShellThickness.data?.Length, strandShellThickness.data?.ThicknessTopFl1_00)
  }), [
    strandShellThickness.data?.ThicknessTopFl1_00,
    strandShellThickness.data?.Length
  ]);

  const solidificationLineSeries = useMemo(() => {
    return generateConstraintLines(commonLines?.solidificationLength, 0, 120, 'Solidification', '#BA0392', 'left');
  }, [commonLines?.solidificationLength]);
  const warningLineSeries = useMemo(() => {
    return generateConstraintLines(commonLines?.solidificationWarning, 0, 120, 'Warning', '#BA0392');
  }, [commonLines?.solidificationWarning]);
  const warmLineSeries = useMemo(() => {
    return generateConstraintLines(commonLines?.solidificationAlarm, 0, 120, 'Warm', '#BA0392');
  }, [commonLines?.solidificationAlarm]);
  const endOfStrandLineSeries = useMemo(() => {
    return generateConstraintLines(commonLines?.endOfStrendGuide, 0, 120, 'End Of Strand Guide', '#000000');
  }, [commonLines?.endOfStrendGuide]);

  const straighteningAreaLine = useMemo(() => (
    {
      name: 'Straightening Area', 
      type: 'column',
      color: '#ff0077', 
      opacity: 0.4,
      pointWidth: 50,
      zIndex: -99,
      data: [[strandShellThickness.straighteningArea, 120]]
    }
  ), [strandShellThickness.straighteningArea]);

  const strandShellThicknessOptions = useMemo(() => ({
    ...defaultOptions,
    xAxis: defaultOptions.xAxis.map(item => ({
      ...item,
      max: maxLengthX && Math.floor(maxLengthX),
    })),
    series: [lineFL0, lineFL1, lineFL2, lineFL3, lineFL4, lineFL5, straighteningAreaLine, solidificationLineSeries, warningLineSeries, warmLineSeries, endOfStrandLineSeries],
  }), [lineFL0, lineFL1, lineFL2, lineFL3, lineFL4, lineFL5, maxLengthX, straighteningAreaLine, solidificationLineSeries, warningLineSeries, warmLineSeries, endOfStrandLineSeries]);
 
  const line1 = useMemo(() => ({
    name: 'line1',
    type: 'spline',
    color: '#fffd8f',
    lineWidth: 30,
    data: line1MiniChartData.map(data => [data, 1050])
  }), [line1MiniChartData]);

  const line2 = useMemo(() => ({
    name: 'line2',
    type: 'spline',
    color: '#ebde7f',
    lineWidth: 30,
    data: line2MiniChartData.map(data => [data, 1050])
  }), [line2MiniChartData]);

  const line3 = useMemo(() => ({
    name: 'line3',
    type: 'spline',
    color: '#94602f',
    lineWidth: 30,
    zIndex: 2,
    data: line3MiniChartData.map(data => ({
      x: data,
      y: 1050,
      marker: {
        enabled: true,
        symbol: `url(${NavigationTwoToneIcon})`,
      }
    }))
  }), [line3MiniChartData]);

  const line4 = useMemo(() => ({
    name: 'line4',
    type: 'spline',
    color: '#ebde7f',
    zIndex: 1,
    lineWidth: 30,
    data: line4MiniChartData.map(data => [data, 1050])
      
  }), [line4MiniChartData]);

  const line5 = useMemo(() => ({
    name: 'line5',
    type: 'spline',
    color: '#43a894',
    lineWidth: 30,
    data: line5MiniChartData.map(data => [data, 950])
  }), [line5MiniChartData]);

  // const arrowMiniChart = useMemo(() => ({
  //   name: 'arrow',
  //   type: 'spline',
  //   color: '#43a894',
  //   lineWidth: 30,
  //   data: [arrowMiniChartData, 1050]
  // }), [arrowMiniChartData]);

  const coolZone = useMemo(() => ({
    name: 'coolZone',
    type: 'column',
    color: '#2f6fed', 
    maxPointWidth: 1, 
    zIndex: -98,
    data: commonLines?.coolzonePasslineCoor?.map(item => [item, 1600]),
  }), [commonLines?.coolzonePasslineCoor]);
  const segmentLine = useMemo(() => ({
    name: 'Segment Line',
    type: 'column',
    color: '#edc928',
    maxPointWidth: 0.1, 
    zIndex: -99,
    data: commonLines?.segmentPasslineCoor?.map(item => [item, 1600]),
  }), [commonLines?.segmentPasslineCoor]);


  const topMiniChartOptions = useMemo(() => ({
    ...optionsArrowChart,
    title: {
      ...optionsArrowChart.title,
      text: 'Strand Shell Thickness and Solid Fractions'
    },
    series: [
      segmentLine,
      coolZone,
      line1,
      line2,
      line3,
      line4,
      line5,
    ]
  }), [
    segmentLine,
    coolZone,
    line1,
    line2,
    line3,
    line4,
    line5,
  ]);

  if (!strandShellThickness.data) {
    return <div className={classes.spinnerContainer}><Spinner /></div>;
  }
  
  const styles = topMenuIsHide ? { height: 'calc( 100vh - 260px )', minHeight: '500px'} : { height: 'calc( 100vh - 155px - 260px )', minHeight: '500px'};
  const chartKey = `${topMenuIsHide}${rightMenuIsHide}${leftMenuIsHide}`;
  
  return ( 
    <div className="shellThicknessChart">
      <div className="shellThicknessChart__first">
        <SolidificationArrowChart key={`${chartKey}_top`} otherOptions={topMiniChartOptions} />
      </div>
      <div className="shellThickness__second">
        <HighchartsReact 
          key={chartKey}
          containerProps={{ style: styles }} 
          highcharts={Highcharts}  
          options={strandShellThicknessOptions}
        /> 
      </div>
    </div>
  );
};
