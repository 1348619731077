import i18next from 'i18next';
import i18n from '../../i18n/i18n';
import { IDialogTranslation } from '../../interfaces/IDialogTranslation';

 
export const transformObjForLanguage = (language?: string | undefined | unknown): IDialogTranslation[] => {       
     
  let result: IDialogTranslation[] = [];     
     
  const arrForAllValue: IDialogTranslation[] = []; 
 
  Object.keys(i18next.services.resourceStore.data).forEach((item: string) => {
    
    const currentLanguage = i18n.getDataByLanguage(item)?.translation; 
  
    if (language === item) {  
      result = transformForSingleValue(currentLanguage, item);
    }  

    if (!language && currentLanguage != undefined) {  

      transformForAllValue(currentLanguage, arrForAllValue, item);  
      result = arrForAllValue;  
    } 
        
  });    
   
  return result;   
};  

const transformForSingleValue = (languageData: {[key: string]: string} | undefined, currentLanguage: string) =>{ 
 
  const newObj = [];  
  let index = 1;    
  for (const key in languageData) {   
    newObj.push({  
      id: index,
      locale: currentLanguage,    
      code: key,     
      value: languageData[key]      
    }); 

    index++; 
  }  

  return newObj;   
}; 

const transformForAllValue = (languageData: {[key: string]: string}, arrForAllValue: IDialogTranslation[], currentLanguage: string) => {

  return Object.keys(languageData).forEach((key: string) => arrForAllValue.push({id: arrForAllValue.length + 1, locale: currentLanguage, code: key, value: languageData[key]})); 
};  