import { RightPanelAction, RightPanelActionTypes, RightPanelState } from '../../types/rightPanel';

const initState: RightPanelState = {
  isOpen: false,
};

export const rightPanelReducer = (state = initState, action: RightPanelAction): RightPanelState => {
  switch (action.type) {
  case RightPanelActionTypes.OPEN_RIGHT_PANEL:
    return {...state, isOpen: true};
  case RightPanelActionTypes.CLOSE_RIGHT_PANEL:
    return {...state, isOpen: false};
  default:
    return state;
  }
};