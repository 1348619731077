import React from 'react';
import { makeStyles} from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Button from '@material-ui/core/Button';
import { VALIDATION_MESSAGE } from '../../constants/ValidationTypes/validationTypes';
import { useTranslation } from 'react-i18next';
import { FormRegistrationValues } from '../../interfaces/IFormRegistrationValues';
import { ValidationErrorMessage } from '../common/ValidationErrorMessage/ValidationErrorMessage';

const useStyles = makeStyles({
  paper: {
    background: '#fff',   
    borderRadius: '10px',
    padding: '25px 15px 31px 15px'
  },
 
  title: { 
    color: '#0076BD',
    fontSize: '14px',
  },

  inputs: {
    width: '100%',
    '& .MuiInputBase-input': {
      background: '#fff',
      border: '0.5px solid #979797',
      boxSizing: 'border-box',
      borderRadius: '4px',
      padding: '20px 15px',
      color: 'black',
      letterSpacing: '0.5px',
      fontFamily: 'Arial',
      fontSize: '14px',
    },
    '& .MuiSelect-select': {
      padding: '10px 15px',
    }    
  },
  validText: {
    color: 'red',
  },
  formClass: {
    paddingTop: '20px',
  },
  text: {
    color: '#000',
    fontSize: '14px',
    lineHeight: '16px',
    marginTop: '10px',

  },
  inputError: {
    color: 'red',
    padding: '5px',
    fontSize: '14px',
  },
});


const initialValue: FormRegistrationValues = {
  id: '',
  name: '',
  password: '',
  securePassword: '',
  sessionId: '',
  useRole: 'Admin',
  locale: 'de-DE'
};


const authShema = Yup.object().shape({
  name: Yup.string()
    .min(2, VALIDATION_MESSAGE.STRING_MIN_TEXT)
    .required(VALIDATION_MESSAGE.REQUIRED),
  id: Yup.number()
    .required(VALIDATION_MESSAGE.REQUIRED)
    .typeError(VALIDATION_MESSAGE.INTEGER_TYPE_ERROR)
    .positive(VALIDATION_MESSAGE.INTEGER_POSITIVE)
    .integer(VALIDATION_MESSAGE.INTEGER),
  password: Yup.string()
    .required(VALIDATION_MESSAGE.REQUIRED),
  securePassword: Yup.string() 
    .required(VALIDATION_MESSAGE.REQUIRED),
  sessionId: Yup.number()
    .required(VALIDATION_MESSAGE.REQUIRED)
    .typeError(VALIDATION_MESSAGE.INTEGER_TYPE_ERROR)
    .positive(VALIDATION_MESSAGE.INTEGER_POSITIVE)
    .integer(VALIDATION_MESSAGE.INTEGER),
});

const FormRegistration: React.FC = () => {

  const classes = useStyles({});
  const { t } = useTranslation();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSubmit = (values: FormRegistrationValues, {resetForm}: any): void => {
    console.log(JSON.stringify(values, null, 2));
    resetForm({values: {
      id: '', 
      name: '',
      password: '',
      securePassword: '',
      sessionId: '',
      useRole: 'Admin', 
      locale: 'de-DE'
    }}); 
  }; 

  return (
    <Container>
      <div className={classes.paper}>
        <Typography className={classes.title} component="h5" variant="h5">
          User
        </Typography>
        <Formik 
          initialValues={initialValue}
          onSubmit={handleSubmit}
          validationSchema={authShema}
        >
          {({values, handleChange, isValid, dirty})=> {
            return (
              (
                <Form className={classes.formClass}>
                  <Grid container  spacing={1}>
                    <Grid
                      item xs={12} sm={12}
                      lg={6}
                    >
                      <Grid container>
                        <Grid
                          item xs={12} sm={12}
                          lg={6}
                          container
                          alignItems="flex-start"
                           
                        >
                          <div className={classes.text}>{`${t('FORM_ID')}:`}</div>
                        </Grid>
                        <Grid
                          item xs={12} sm={12}
                          lg={6}
                        >
                          <Field 
                            as={TextField}
                            type="text"
                            className={classes.inputs} 
                            id="standard-basic" 
                            placeholder="90"
                            name="id"
                            value={values.id}
                            onChange={handleChange}
                            helperText={<ValidationErrorMessage name="id" />}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item xs={12} sm={12}
                      lg={6}
                    >

                      <Grid container>
                        <Grid
                          item xs={12} sm={12}
                          lg={6}
                          container
                          alignItems="flex-start"
                        >
                          <div className={classes.text}>{`${t('FORM_NAME')}:`}</div>
                        </Grid>
                        <Grid
                          item xs={12} sm={12}
                          lg={6}
                        >
                          <Field 
                            as={TextField}
                            type="text"
                            className={classes.inputs} 
                            id="standard-basic" 
                            placeholder="login"
                            name="name"
                            value={values.name}
                            onChange={handleChange}
                            helperText={<ValidationErrorMessage name="name" />}
                          />
                        </Grid>
                        
                      </Grid>
                    </Grid>
                    <Grid
                      item xs={12} sm={12}
                      lg={6}
                    >
                      <Grid container>
                        <Grid
                          item xs={12} sm={12}
                          lg={6}
                          container
                          alignItems="flex-start"
                        >
                          <div className={classes.text}>{`${t('FORM_PASSWORD')}:`}</div>
                        </Grid> 
                        <Grid
                          item xs={12} sm={12}
                          lg={6}
                        >
                          <Field 
                            as={TextField}
                            type="password"
                            className={classes.inputs} 
                            id="standard-basic" 
                            placeholder="12345"
                            name="password"
                            value={values.password}
                            onChange={handleChange}
                            helperText={<ValidationErrorMessage name="password" />}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item xs={12} sm={12}
                      lg={6}
                    >
                      <Grid container>
                        <Grid
                          item xs={12} sm={12}
                          lg={6}
                          container
                          alignItems="flex-start"
                        >
                          <div className={classes.text}>{`${t('FORM_SECURE_PASSWORD')}:`}</div>
                        </Grid>
                        <Grid
                          item xs={12} sm={12}
                          lg={6}
                        >
                          <Field 
                            as={TextField}
                            type="password"
                            className={classes.inputs} 
                            id="standard-basic" 
                            placeholder="IDDQD"
                            name="securePassword"
                            value={values.securePassword}
                            onChange={handleChange}
                            helperText={<ValidationErrorMessage name="securePassword" />}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item xs={12} sm={12}
                      lg={12}
                    >
                      <Grid container>
                        <Grid
                          item xs={12} sm={12}
                          lg={3}
                          container
                          alignItems="flex-start"
                        >
                          <div className={classes.text}>{`${t('FORM_SESSION_ID')}:`}</div>
                        </Grid>
                        <Grid
                          item xs={12} sm={12}
                          lg={9}
                        >
                          <Field 
                            as={TextField}
                            type="text"
                            className={classes.inputs} 
                            id="standard-basic" 
                            placeholder="0"
                            name="sessionId"
                            value={values.sessionId}
                            onChange={handleChange}
                            helperText={<ValidationErrorMessage name="sessionId" />}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item xs={12} sm={12}
                      lg={12}
                    >
                        
                      <Grid container>
                        <Grid
                          item xs={12} sm={12}
                          lg={3}
                          container
                          alignItems="flex-start"
                        >
                          <div className={classes.text}>{`${t('FORM_USE_ROLE')}:`}</div>
                        </Grid>
                        <Grid
                          item xs={12} sm={12}
                          lg={9}
                        > 
                          <Select
                            name="useRole"
                            className={classes.inputs}
                            value={values.useRole}
                            onChange={handleChange}
                          >
                            
                            <MenuItem value={'Admin'}>
                              <em>{t('ADMIN')}</em>
                            </MenuItem>
                            <MenuItem value={'User1'}>User1</MenuItem>
                            <MenuItem value={'User2'}>User2</MenuItem>
                            <MenuItem value={'User3'}>User3</MenuItem>
                          </Select>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item xs={12} sm={12}
                      lg={12}
                    >
                      <Grid container>
                        <Grid
                          item xs={12} sm={12}
                          lg={3}
                          container
                          alignItems="flex-start"
                        >
                          <div className={classes.text}>{`${t('FORM_LOCALE')}:`}</div>
                        </Grid>
                        <Grid
                          item xs={12} sm={12}
                          lg={9}
                        >
                          <Select
                            className={classes.inputs}
                            name="locale"
                            value={values.locale}
                            onChange={handleChange}
                          >
                            <MenuItem value={'de-DE'}>
                              <em>de-DE</em>
                            </MenuItem>
                            <MenuItem value={'User1'}>de-DF</MenuItem>
                            <MenuItem value={'User2'}>fs-DD</MenuItem>
                            <MenuItem value={'User3'}>rf-FN</MenuItem>
                          </Select>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Button disabled={!isValid || !dirty} type="submit" variant="contained">{t('SUBMIT')}</Button>
                </Form>
              )
            ); 
          }} 
        </Formik>
      </div>
    </Container>
  );
};

export default FormRegistration;


