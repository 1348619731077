import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga'; 
import { rootReducer } from './reducers';
import {rootWatcher} from '../saga';

const sagaMiddleware = createSagaMiddleware();

export const store = createStore(rootReducer, composeWithDevTools(
  applyMiddleware(sagaMiddleware)
));   

sagaMiddleware.run(rootWatcher);

export type RootReducer = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch; 