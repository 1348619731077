import { ScaleCompositionAction, ScaleCompositionActionTypes, ScaleCompositionState } from '../../../types/charts/scaleComposition'; 
   
const initState: ScaleCompositionState = {
  lengthX: [ 
    0.0,
    0.079999998211860657,
    0.15999999642372131,
    0.23999999463558197,
    0.31999999284744263,
    0.39999997615814209,
    0.47999998927116394,
    0.559999942779541,
    0.63999998569488525,
    0.72000002861022949,
    0.80000007152557373,
    0.81934010982513428,
    0.84566003084182739,
    0.87208002805709839,
    0.89850002527236938,
    0.9244999885559082,
    0.92499995231628418,
    0.92549991607666016,
    0.95241999626159668,
    0.9793400764465332,
    1.0056599378585815,
    1.034579873085022,
    1.0634998083114624,
    1.0895000696182251,
    1.0900000333786011,
    1.0904999971389771,
    1.1220899820327759,
    1.1536799669265747,
    1.1913200616836548,
    1.2195000648498535,
    1.2545000314712524,
    1.2549999952316284,
    1.2554999589920044,
    1.2870899438858032,
    1.3186799287796021,
    1.3563201427459717,
    1.3845000267028809,
    1.4194999933242798,
    1.4199999570846558,
    1.4204999208450317,
    1.4520899057388306,
    1.4836798906326294,
    1.5213199853897095,
    1.5494998693466187,
    1.5844999551773071,
    1.5849999189376831,
    1.5854998826980591,
    1.6170898675918579,
    1.6486798524856567,
    1.6863200664520264,
    1.714499831199646,
    1.7494999170303345,
    1.7499998807907105,
    1.7504998445510864,
    1.7820899486541748,
    1.8136800527572632,
    1.8513201475143433,
    1.8794999122619629,
    1.9144998788833618,
    1.9149998426437378,
    1.9154998064041138,
    1.9470897912979126,
    1.9786797761917114,
    2.0163199901580811,
    2.0444996356964111,
    2.0794999599456787,
    2.0799999237060547,
    2.0804998874664307,
    2.1175899505615234,
    2.1546800136566162,
    2.1923201084136963,
    2.231400728225708,
    2.2664010524749756,
    2.2669999599456787,
    2.2675988674163818,
    2.3111395835876465,
    2.3546802997589111,
    2.3923206329345703,
    2.4443533420562744,
    2.4793531894683838,
    2.4800000190734863,
    2.4806468486785889,
    2.5241634845733643,
    2.5676801204681396,
    2.6053202152252197,
    2.6573257446289062,
    2.6923258304595947,
    2.6930000782012939,
    2.6936743259429932,
    2.7371771335601807,
    2.7806799411773682,
    2.8183200359344482,
    2.8702991008758545,
    2.9052994251251221,
    2.9060001373291016,
    2.9067008495330811,
    2.950190544128418,
    2.9936802387237549,
    3.031320333480835,
    3.0832741260528564,
    3.1182742118835449,
    3.1190001964569092,
    3.1197261810302734,
    3.163203239440918,
    3.2066802978515625,
    3.2443203926086426,
    3.2962498664855957,
    3.3312499523162842,
    3.3320002555847168,
    3.3327505588531494,
    3.3762152194976807,
    3.4196798801422119,
    3.4573202133178711,
    3.5091800689697266,
    3.5441806316375732,
    3.5450003147125244,
    3.5458199977874756,
    3.6022500991821289,
    3.6586802005767822,
    3.6963202953338623,
    3.7609419822692871,
    3.8089420795440674,
    3.81000018119812,
    3.8110582828521729,
    3.8718690872192383,
    3.9326801300048828,
    3.9703202247619629,
    4.0438857078552246,
    4.091886043548584,
    4.0930004119873047,
    4.0941147804260254,
    4.1548976898193359,
    4.2156805992126465,
    4.2533202171325684,
    4.32684850692749,
    4.3748483657836914,
    4.37600040435791,
    4.3771524429321289,
    4.4379167556762695,
    4.49868106842041,
    4.536320686340332,
    4.6098132133483887,
    4.6578125953674316,
    4.6590003967285156,
    4.6601881980896,
    4.7209343910217285,
    4.7816805839538574,
    4.8193202018737793,
    4.8927617073059082,
    4.9407615661621094,
    4.9420003890991211,
    4.9432392120361328,
    5.0077099800109863,
    5.07218074798584,
    5.1098203659057617,
    5.1907262802124023,
    5.2387256622314453,
    5.2400002479553223,
    5.2412748336791992,
    5.3019776344299316,
    5.3626804351806641,
    5.4003200531005859,
    5.4737105369567871,
    5.52170991897583,
    5.5230002403259277,
    5.5242905616760254,
    5.5849857330322266,
    5.6456809043884277,
    5.68332052230835,
    5.7566800117492676,
    5.8046789169311523,
    5.8060002326965332,
    5.8073215484619141,
    5.8680009841918945,
    5.928680419921875,
    5.9663200378417969,
    6.03964900970459,
    6.087648868560791,
    6.0890002250671387,
    6.0903515815734863,
    6.1510157585144043,
    6.2116799354553223,
    6.2493195533752441,
    6.322601318359375,
    6.3706016540527344,
    6.3720002174377441,
    6.3733987808227539,
    6.4377894401550293,
    6.5021800994873047,
    6.5398197174072266,
    6.6205706596374512,
    6.6685714721679688,
    6.6700000762939453,
    6.6714286804199219,
    6.7320547103881836,
    6.7926807403564453,
    6.8303203582763672,
    6.9035625457763672,
    6.9515624046325684,
    6.9530000686645508,
    6.9544377326965332,
    7.0150594711303711,
    7.075681209564209,
    7.1133208274841309,
    7.18653678894043,
    7.2345361709594727,
    7.2360000610351562,
    7.23746395111084,
    7.298072338104248,
    7.3586807250976562,
    7.3963203430175781,
    7.469510555267334,
    7.5175104141235352,
    7.5190000534057617,
    7.5204896926879883,
    7.581085205078125,
    7.6416807174682617,
    7.6793203353881836,
    7.7524657249450684,
    7.8004655838012695,
    7.8020000457763672,
    7.8035345077514648,
    7.8678579330444336,
    7.9321808815002441,
    7.969820499420166,
    8.05043888092041,
    8.0984401702880859,
    8.1000003814697266,
    8.1015605926513672,
    8.1621208190917969,
    8.2226810455322266,
    8.2603216171264648,
    8.3334379196167,
    8.3814373016357422,
    8.383000373840332,
    8.3845634460449219,
    8.4451217651367188,
    8.5056800842285156,
    8.5433206558227539,
    8.6164150238037109,
    8.6644144058227539,
    8.6660003662109375,
    8.6675863265991211,
    8.7281332015991211,
    8.7886800765991211,
    8.82632064819336,
    8.8993930816650391,
    8.9473934173583984,
    8.949000358581543,
    8.9506072998046875,
    9.011143684387207,
    9.0716800689697266,
    9.1093206405639648,
    9.18234920501709,
    9.2303504943847656,
    9.2320003509521484,
    9.2336502075195312,
    9.2979145050048828,
    9.3621788024902344,
    9.3998193740844727,
    9.4803276062011719,
    9.5283288955688477,
    9.5300006866455078,
    9.531672477722168,
    9.59217643737793,
    9.6526803970336914,
    9.69032096862793,
    9.763331413269043,
    9.8113307952880859,
    9.8130006790161133,
    9.81467056274414,
    9.8751754760742188,
    9.9356803894042969,
    9.9733209609985352,
    10.04631233215332,
    10.094311714172363,
    10.096000671386719,
    10.097689628601074,
    10.158185005187988,
    10.218680381774902,
    10.256320953369141,
    10.329294204711914,
    10.377293586730957,
    10.379000663757324,
    10.380707740783691,
    10.441193580627441,
    10.501679420471191,
    10.53931999206543,
    10.612217903137207,
    10.660218238830566,
    10.66200065612793,
    10.663783073425293,
    10.733731269836426,
    10.803679466247559,
    10.841320037841797,
    10.926018714904785,
    10.981019020080566,
    10.983000755310059,
    10.984982490539551,
    11.054331779479981,
    11.12368106842041,
    11.161321640014648,
    11.245001792907715,
    11.30000114440918,
    11.302000999450684,
    11.304000854492188,
    11.373340606689453,
    11.442680358886719,
    11.480321884155273,
    11.563982009887695,
    11.61898136138916,
    11.621001243591309,
    11.623021125793457,
    11.692350387573242,
    11.761679649353027,
    11.799320220947266,
    11.882961273193359,
    11.937961578369141,
    11.940001487731934,
    11.942041397094727,
    12.011361122131348,
    12.080680847167969,
    12.118321418762207,
    12.201908111572266,
    12.256906509399414,
    12.259001731872559,
    12.261096954345703,
    12.336138725280762,
    12.41118049621582,
    12.448821067810059,
    12.543889045715332,
    12.598886489868164,
    12.601000785827637,
    12.603115081787109,
    12.672397613525391,
    12.741680145263672,
    12.77932071685791,
    12.862906455993652,
    12.917906761169434,
    12.920001029968262,
    12.92209529876709,
    12.991388320922852,
    13.060681343078613,
    13.098321914672852,
    13.181892395019531,
    13.236891746520996,
    13.239001274108887,
    13.241110801696777,
    13.310395240783691,
    13.379679679870606,
    13.417320251464844,
    13.500875473022461,
    13.555876731872559,
    13.558001518249512,
    13.560126304626465,
    13.629403114318848,
    13.698679924011231,
    13.736320495605469,
    13.819822311401367,
    13.874823570251465,
    13.877001762390137,
    13.879179954528809,
    13.954179763793945,
    14.029179573059082,
    14.06682014465332,
    14.161808967590332,
    14.216808319091797,
    14.219000816345215,
    14.221193313598633,
    14.290436744689941,
    14.35968017578125,
    14.397320747375488,
    14.480833053588867,
    14.535834312438965,
    14.53800106048584,
    14.540167808532715,
    14.609423637390137,
    14.678679466247559,
    14.716320037841797,
    14.799819946289063,
    14.854822158813477,
    14.857001304626465,
    14.859180450439453,
    14.92842960357666,
    14.997678756713867,
    15.035319328308106,
    15.118809700012207,
    15.173810958862305,
    15.17600154876709,
    15.178192138671875,
    15.2474365234375,
    15.316680908203125,
    15.354321479797363,
    15.437762260437012,
    15.492761611938477,
    15.495001792907715,
    15.497241973876953,
    15.572210311889648,
    15.647178649902344,
    15.684820175170898,
    15.779749870300293,
    15.834750175476074,
    15.837000846862793,
    15.839251518249512,
    15.908466339111328,
    15.977680206298828,
    16.01531982421875,
    16.098779678344727,
    16.153779983520508,
    16.1560001373291,
    16.158220291137695,
    16.227449417114258,
    16.29667854309082,
    16.334318161010742,
    16.417768478393555,
    16.4727725982666,
    16.475000381469727,
    16.477228164672852,
    16.546453475952148,
    16.615678787231445,
    16.653318405151367,
    16.736761093139648,
    16.791765213012695,
    16.794000625610352,
    16.796236038208008,
    16.865457534790039,
    16.93467903137207,
    16.972318649291992,
    17.055667877197266,
    17.11067008972168,
    17.113000869750977,
    17.115331649780273,
    17.2065486907959,
    17.297767639160156,
    17.388986587524414,
    17.480203628540039,
    17.48280143737793,
    17.48539924621582,
    17.575349807739258,
    17.665302276611328,
    17.755252838134766,
    17.845205307006836,
    17.847801208496094,
    17.850397109985352,
    17.940347671508789,
    18.030298233032227,
    18.120248794555664,
    18.2101993560791,
    18.212800979614258,
    18.215402603149414,
    18.305351257324219,
    18.395298004150391,
    18.485246658325195,
    18.5751953125,
    18.577800750732422,
    18.580406188964844,
    18.670345306396484,
    18.760286331176758,
    18.8502254486084,
    18.940164566040039,
    18.942800521850586,
    18.945436477661133,
    19.039117813110352,
    19.13279914855957,
    19.226480484008789,
    19.320163726806641,
    19.32280158996582,
    19.325439453125,
    19.415376663208008,
    19.505313873291016,
    19.595251083374023,
    19.685188293457031,
    19.687801361083984,
    19.690414428710938,
    19.780357360839844,
    19.87030029296875,
    19.960243225097656,
    20.050186157226562,
    20.052801132202148,
    20.055416107177734,
    20.145359039306641,
    20.235301971435547,
    20.325244903564453,
    20.415185928344727,
    20.417800903320312,
    20.4204158782959,
    20.51036262512207,
    20.600309371948242,
    20.690256118774414,
    20.780202865600586,
    20.782800674438477,
    20.785398483276367,
    20.872854232788086,
    20.960309982299805,
    21.047765731811523,
    21.135223388671875,
    21.137802124023438,
    21.140380859375,
    21.227840423583984,
    21.3153018951416,
    21.402761459350586,
    21.49022102355957,
    21.492801666259766,
    21.495382308959961,
    21.582841873168945,
    21.67030143737793,
    21.757762908935547,
    21.8452205657959,
    21.847801208496094,
    21.850381851196289,
    21.937841415405273,
    22.025300979614258,
    22.112760543823242,
    22.200220108032227,
    22.202800750732422,
    22.205381393432617,
    22.2928409576416,
    22.380300521850586,
    22.46776008605957,
    22.555219650268555,
    22.55780029296875,
    22.560380935668945,
    22.64784049987793,
    22.735300064086914,
    22.8227596282959,
    22.910221099853516,
    22.912801742553711,
    22.915382385253906,
    23.002841949462891,
    23.090301513671875,
    23.177761077880859,
    23.265220642089844,
    23.267801284790039,
    23.270381927490234,
    23.357841491699219,
    23.445301055908203,
    23.532760620117188,
    23.620220184326172,
    23.622800827026367,
    23.625381469726562,
    23.712841033935547,
    23.800300598144531,
    23.887758255004883,
    23.975217819213867,
    23.977800369262695,
    23.980382919311523,
    24.067842483520508,
    24.155300140380859,
    24.242759704589844,
    24.330217361450195,
    24.332799911499023,
    24.335382461547852,
    24.422842025756836,
    24.510299682617188,
    24.597759246826172,
    24.685201644897461,
    24.687801361083984,
    24.690401077270508,
    24.777843475341797,
    24.865301132202148,
    24.952760696411133,
    25.040201187133789,
    25.042800903320312,
    25.045400619506836,
    25.132843017578125,
    25.220300674438477,
    25.307760238647461,
    25.395200729370117,
    25.397800445556641,
    25.400400161743164,
    25.48784065246582,
    25.575300216674805,
    25.662757873535156,
    25.750200271606445,
    25.752799987792969,
    25.755399703979492,
    25.842840194702148,
    25.930299758911133,
    26.017757415771484,
    26.105199813842773,
    26.107799530029297,
    26.11039924621582,
    26.197839736938477,
    26.285299301147461,
    26.372756958007812,
    26.460201263427734,
    26.462800979614258,
    26.465400695800781,
    26.552841186523438,
    26.640300750732422,
    26.727758407592773,
    26.815200805664062,
    26.817800521850586,
    26.820400238037109,
    26.9078426361084,
    26.99530029296875,
    27.082759857177734,
    27.170200347900391,
    27.172800064086914,
    27.175399780273438,
    27.262842178344727,
    27.350299835205078,
    27.437759399414062,
    27.525199890136719,
    27.527799606323242,
    27.530399322509766,
    27.617841720581055,
    27.705299377441406,
    27.792757034301758,
    27.880199432373047,
    27.88279914855957,
    27.885398864746094,
    27.972841262817383,
    28.060298919677734,
    28.147756576538086,
    28.235200881958008,
    28.237800598144531,
    28.240400314331055,
    28.327842712402344,
    28.415300369262695,
    28.502758026123047,
    28.590200424194336,
    28.592800140380859,
    28.595399856567383,
    28.682842254638672,
    28.770299911499023,
    28.857757568359375,
    28.945199966430664,
    28.947799682617188,
    28.950399398803711,
    29.037841796875,
    29.125299453735352,
    29.212757110595703,
    29.300199508666992,
    29.302799224853516,
    29.305398941040039,
    29.392841339111328,
    29.48029899597168,
    29.567756652832031,
    29.65519905090332,
    29.657798767089844,
    29.660398483276367,
    29.747840881347656,
    29.835298538208008,
    29.922754287719727,
    30.010200500488281,
    30.012800216674805,
    30.015399932861328,
    30.10284423828125,
    30.1903018951416,
    30.27775764465332,
    30.365200042724609,
    30.367799758911133,
    30.370399475097656,
    30.457843780517578,
    30.545299530029297,
    30.632757186889648,
    30.720199584960938,
    30.722799301147461,
    30.725399017333984,
    30.812843322753906,
    30.900299072265625,
    30.987756729125977,
    31.075199127197266,
    31.077798843383789,
    31.080398559570312,
    31.167842864990234,
    31.255298614501953,
    31.342756271362305,
    31.430198669433594,
    31.432798385620117,
    31.435398101806641,
    31.522842407226562,
    31.610298156738281,
    31.697755813598633,
    31.785200119018555,
    31.787799835205078,
    31.7903995513916,
    31.877843856811523,
    31.965299606323242,
    32.052757263183594,
    32.14019775390625,
    32.142799377441406,
    32.145401000976562,
    32.232841491699219,
    32.3203010559082,
    32.407756805419922,
    32.495197296142578,
    32.497798919677734,
    32.500400543212891,
    32.587841033935547,
    32.675296783447266,
    32.76275634765625,
    32.850196838378906,
    32.852798461914062,
    32.855400085449219,
    32.942840576171875,
    33.030296325683594,
    33.117755889892578,
    33.205196380615234,
    33.207798004150391,
    33.210399627685547,
    33.2978401184082,
    33.385295867919922,
    33.472755432128906,
    33.560199737548828,
    33.562801361083984,
    33.565402984619141,
    33.6528434753418,
    33.740299224853516,
    33.8277587890625,
    33.915199279785156,
    33.917800903320312,
    33.920402526855469,
    34.007843017578125,
    34.095298767089844,
    34.182754516601562,
    34.270198822021484,
    34.272800445556641,
    34.2754020690918,
    34.362842559814453,
    34.450298309326172,
    34.537754058837891,
    34.625198364257812,
    34.627799987792969,
    34.630401611328125,
    34.717842102050781,
    34.8052978515625,
    34.892753601074219,
    34.980197906494141,
    34.9827995300293,
    34.985401153564453,
    35.072841644287109,
    35.160297393798828,
    35.247753143310547,
    35.335201263427734,
    35.337802886962891,
    35.340404510498047,
    35.4278450012207,
    35.515300750732422,
    35.602756500244141,
    35.690200805664062,
    35.692802429199219,
    35.695404052734375,
    35.782844543457031,
    35.87030029296875,
    35.957756042480469,
    36.045200347900391,
    36.047801971435547,
    36.0504035949707,
    36.137844085693359,
    36.225299835205078,
    36.3127555847168,
    36.400199890136719,
    36.402801513671875,
    36.405403137207031,
    36.492847442626953,
    36.580303192138672,
    36.667758941650391,
    36.755199432373047,
    36.7578010559082,
    36.760402679443359,
    36.847846984863281,
    36.935302734375,
    37.022758483886719,
    37.110202789306641,
    37.1128044128418,
    37.115406036376953,
    37.202850341796875,
    37.290306091308594,
    37.377761840820312,
    37.465202331542969,
    37.467803955078125,
    37.470405578613281,
    37.5578498840332,
    37.645305633544922,
    37.732761383056641,
    37.8202018737793,
    37.822803497314453,
    37.825405120849609,
    37.912849426269531,
    38.00030517578125,
    38.087760925292969,
    38.175201416015625,
    38.177803039550781,
    38.180404663085938,
    38.267848968505859,
    38.355304718017578,
    38.4427604675293,
    38.530200958251953,
    38.532802581787109,
    38.535404205322266,
    38.622848510742188,
    38.710304260253906,
    38.797760009765625,
    38.885204315185547,
    38.8878059387207,
    38.890407562255859,
    38.977851867675781,
    39.0653076171875,
    39.152763366699219,
    39.240203857421875,
    39.242805480957031,
    39.245407104492188,
    39.332851409912109,
    39.420307159423828,
    39.507762908935547,
    39.5952033996582,
    39.597805023193359,
    39.600406646728516,
    39.687850952148438,
    39.775306701660156,
    39.862762451171875,
    39.950202941894531,
    39.952804565429688,
    39.955406188964844,
    40.042850494384766,
    40.130306243896484,
    40.2177619934082,
    40.305202484130859,
    40.307804107666016,
    40.310405731201172,
    40.397850036621094,
    40.485305786132812,
    40.572761535644531,
    40.660205841064453,
    40.662807464599609,
    40.665409088134766,
    40.752853393554688,
    40.840309143066406,
    40.927761077880859,
    41.015205383300781,
    41.017807006835938,
    41.020408630371094,
    41.107852935791016,
    41.195308685302734,
    41.282760620117188,
    41.370204925537109,
    41.372806549072266,
    41.375408172607422,
    41.462852478027344,
    41.550308227539062,
    41.637760162353516,
    41.725204467773438,
    41.727806091308594,
    41.73040771484375,
    41.817852020263672,
    41.905307769775391,
    41.992759704589844,
    42.080204010009766,
    42.082805633544922,
    42.085407257080078,
    42.1728515625,
    42.260307312011719,
    42.347759246826172,
    42.435207366943359,
    42.437808990478516,
    42.440410614013672,
    42.527854919433594,
    42.615306854248047,
    42.702762603759766,
    42.790206909179688,
    42.792808532714844,
    42.79541015625,
    42.882854461669922,
    42.970306396484375,
    43.057762145996094,
    43.145206451416016,
    43.147808074951172,
    43.150409698486328,
    43.23785400390625,
    43.3253059387207,
    43.412761688232422,
    43.500205993652344,
    43.5028076171875,
    43.505409240722656,
    43.592853546142578,
    43.680305480957031,
    43.76776123046875,
    43.855205535888672,
    43.857807159423828,
    43.860408782958984,
    43.9402961730957,
    44.020195007324219,
    44.100090026855469,
    44.179988861083984,
    44.259883880615234,
    44.339778900146484,
    44.419677734375,
    44.49957275390625,
    44.579471588134766,
    44.659366607666016,
    44.739261627197266,
    44.819160461425781,
    44.899055480957031,
    44.978950500488281,
    45.0588493347168,
    45.138744354248047,
    45.218643188476562,
    45.298538208007812,
    45.378433227539062,
    45.458332061767578,
    45.538227081298828,
    45.618125915527344,
    45.698020935058594,
    45.777915954589844
  ],
  scaleFayalit: [
    0.0,
    0.0,
    0.0,
    0.0,
    0.0,
    0.0,
    0.0,
    0.0,
    0.0,
    0.0,
    3.465959889581427E-05,
    1.505883574282052E-05,
    2.9064573027426377E-05,
    4.3365245801396668E-05,
    5.5271648307098076E-05,
    5.5436750699300319E-05,
    5.5570115364389494E-05,
    6.5771957451943308E-05,
    7.6769931183662266E-05,
    0.0,
    2.6463367248652503E-05,
    4.2711766582215205E-05,
    5.4230811656452715E-05,
    5.4390347941080108E-05,
    5.4518841352546588E-05,
    6.6176864493172616E-05,
    7.8402430517598987E-05,
    0.0,
    1.9400458768359385E-05,
    3.3870470360852778E-05,
    3.39964717568364E-05,
    3.409405326237902E-05,
    4.4265758333494887E-05,
    5.5201391660375521E-05,
    0.0,
    1.660523957980331E-05,
    2.9080325475661084E-05,
    2.918747668445576E-05,
    2.9269431252032518E-05,
    3.8107820728328079E-05,
    4.7819583414820954E-05,
    0.0,
    1.3798232430417556E-05,
    2.4671366190887056E-05,
    2.4762892280705273E-05,
    2.4832017516018823E-05,
    3.26062181557063E-05,
    4.1282262827735394E-05,
    0.0,
    1.2844106095144525E-05,
    2.2702079149894416E-05,
    2.2784788598073646E-05,
    2.2846794308861718E-05,
    2.9811786589561962E-05,
    3.7599493225570768E-05,
    0.0,
    1.0797131835715845E-05,
    1.9368620996829122E-05,
    1.9439286916167475E-05,
    1.9491621060296893E-05,
    2.5584484319551848E-05,
    3.2508654840057716E-05,
    0.0,
    1.0047092473541852E-05,
    1.7932990886038169E-05,
    1.7997706891037524E-05,
    1.8045329852611758E-05,
    2.4974362531793304E-05,
    3.2700128940632567E-05,
    0.0,
    1.4502958038065117E-05,
    2.1706206098315306E-05,
    2.1780810129712336E-05,
    2.18349632632453E-05,
    2.9559007089119405E-05,
    3.8009929994586855E-05,
    0.0,
    1.9383662220207043E-05,
    2.6054478439618833E-05,
    2.6132160201086663E-05,
    2.6188334231846966E-05,
    3.3300617360509932E-05,
    4.1168765164911747E-05,
    0.0,
    1.8532835383666679E-05,
    2.4941584342741407E-05,
    2.5018460291903466E-05,
    2.5073435608646832E-05,
    3.1857376598054543E-05,
    3.9395723433699459E-05,
    0.0,
    1.8423070287099108E-05,
    2.4713190214242786E-05,
    2.4791044779703952E-05,
    2.4846265660016797E-05,
    3.1410927476827055E-05,
    3.8702190067851916E-05,
    0.0,
    1.7179472706629895E-05,
    2.3089018213795498E-05,
    2.31637623073766E-05,
    2.3216101908474229E-05,
    2.934761687356513E-05,
    3.6199762689648196E-05,
    0.0,
    1.6755564502091147E-05,
    2.2447613446274772E-05,
    2.2521415303344838E-05,
    2.257261257909704E-05,
    2.8410166123649105E-05,
    3.4958091418957338E-05,
    0.0,
    1.5397936294903047E-05,
    2.0713097910629585E-05,
    2.0786377717740834E-05,
    2.0835968825849704E-05,
    2.8438933441066183E-05,
    3.6636803997680545E-05,
    0.0,
    1.9027938833460212E-05,
    2.5753721274668351E-05,
    2.5837227440206334E-05,
    2.5891567929647863E-05,
    3.3190604881383479E-05,
    4.1244449676014483E-05,
    0.0,
    2.1803682102472521E-05,
    2.8322838261374272E-05,
    2.8409105652826838E-05,
    2.8464957722462714E-05,
    3.5472992749419063E-05,
    4.3235235352767631E-05,
    0.0,
    2.1107192878844216E-05,
    2.74331741820788E-05,
    2.7518679416971281E-05,
    2.75734255410498E-05,
    3.4325661545153707E-05,
    4.1823910578386858E-05,
    0.0,
    2.1031699361628853E-05,
    2.7267491532256827E-05,
    2.7353755285730585E-05,
    2.7408535970607772E-05,
    3.3985506888711825E-05,
    4.1289866203442216E-05,
    0.0,
    1.9911754861823283E-05,
    2.5859246306936257E-05,
    2.5943565560737625E-05,
    2.5996276235673577E-05,
    3.2736090361140668E-05,
    4.0177015762310475E-05,
    0.0,
    2.0675211999332532E-05,
    2.6209047064185143E-05,
    2.6289901143172756E-05,
    2.6340025215176865E-05,
    3.2151681807590649E-05,
    3.8715443224646151E-05,
    0.0,
    1.8890073988586664E-05,
    2.447340011713095E-05,
    2.4554472474846989E-05,
    2.460429459461011E-05,
    3.037464375665877E-05,
    3.6859080864815041E-05,
    0.0,
    1.7669221051619388E-05,
    2.2945388991502114E-05,
    2.3022690584184602E-05,
    2.3069565941113979E-05,
    2.8515074518509209E-05,
    3.4678847441682592E-05,
    0.0,
    1.7291624317294918E-05,
    2.2414104023482651E-05,
    2.2490208721137606E-05,
    2.253594902867917E-05,
    2.7779400625149719E-05,
    3.3733093005139381E-05,
    0.0,
    1.6159483493538573E-05,
    2.1002853827667423E-05,
    2.107598447764758E-05,
    2.1120144083397463E-05,
    2.648590634635184E-05,
    3.2533414923818782E-05,
    0.0,
    1.95527736650547E-05,
    2.4478005798300728E-05,
    2.4555798518122174E-05,
    2.4602146368124522E-05,
    2.9483097023330629E-05,
    3.5013974411413074E-05,
    0.0,
    1.8969840311910957E-05,
    2.4181672415579669E-05,
    2.4263770683319308E-05,
    2.4312645109603181E-05,
    2.9371964046731591E-05,
    3.503411790006794E-05,
    0.0,
    1.8688377167563885E-05,
    2.384294020885136E-05,
    2.3925231289467774E-05,
    2.3974016585270874E-05,
    2.8962873329874128E-05,
    3.45493717759382E-05,
    0.0,
    1.8815026123775169E-05,
    2.3966980734257959E-05,
    2.405035229458008E-05,
    2.409957414784003E-05,
    2.9047800126136281E-05,
    3.4585631510708481E-05,
    0.0,
    1.8310427549295127E-05,
    2.3352758944383822E-05,
    2.3435804905602708E-05,
    2.3484310077037662E-05,
    2.8693000786006451E-05,
    3.4467124351067469E-05,
    0.0,
    1.9268676624051295E-05,
    2.4058557755779475E-05,
    2.4139177185134031E-05,
    2.4186090740840882E-05,
    2.8782651497749612E-05,
    3.39848775183782E-05,
    0.0,
    1.7981135897571221E-05,
    2.2894097128300928E-05,
    2.2975889805820771E-05,
    2.3023287212708965E-05,
    2.7673035219777375E-05,
    3.2903044484555721E-05,
    0.0,
    1.7333206415059976E-05,
    2.2100814021541737E-05,
    2.2180596715770662E-05,
    2.2227804947760887E-05,
    2.6745145078166388E-05,
    3.1835556001169607E-05,
    0.0,
    1.72049713000888E-05,
    2.1909580027568154E-05,
    2.1988997104926966E-05,
    2.2036525479052216E-05,
    2.6468360374565236E-05,
    3.1466672226088122E-05,
    0.0,
    1.6567264538025483E-05,
    2.11274473258527E-05,
    2.1205443772487342E-05,
    2.1253774320939556E-05,
    2.5886231014737859E-05,
    3.1062227208167315E-05,
    0.0,
    1.7319440303253941E-05,
    2.1620009647449479E-05,
    2.169491199310869E-05,
    2.1741936507169157E-05,
    2.5798639398999512E-05,
    3.0431565392063931E-05,
    0.0,
    1.6060144844232127E-05,
    2.0451074306038208E-05,
    2.052648960670922E-05,
    2.0574827431119047E-05,
    2.4664152078912593E-05,
    2.9307540899026208E-05,
    0.0,
    1.5433406588272192E-05,
    1.96746586880181E-05,
    1.9747676560655236E-05,
    1.9796107153524645E-05,
    2.3739221433061175E-05,
    2.8239013772690669E-05,
    1.3070001841697376E-05,
    2.0095052605029196E-05,
    2.3421745936502703E-05,
    2.3482240067096427E-05,
    2.3522934498032555E-05,
    2.6830555725609884E-05,
    3.0763960239710286E-05,
    1.2556835827126633E-05,
    1.9307353795738891E-05,
    2.2527503460878506E-05,
    2.2587480998481624E-05,
    2.2629079467151314E-05,
    2.6504629204282537E-05,
    3.1012183171696961E-05,
    0.0,
    1.6236421288340352E-05,
    2.0510609829216264E-05,
    2.0582598153850995E-05,
    2.0631956431316212E-05,
    2.4699289497220889E-05,
    2.9343324058572762E-05,
    0.0,
    1.6202160622924566E-05,
    2.0485622371779755E-05,
    2.055818731605541E-05,
    2.0607911210390739E-05,
    2.4651189960422926E-05,
    2.9258775612106547E-05,
    0.0,
    1.576211980136577E-05,
    1.9946892280131578E-05,
    2.0017987480969168E-05,
    2.0067687728442252E-05,
    2.4004077204153873E-05,
    2.8506647140602581E-05,
    1.3339583347260486E-05,
    2.0599145500455052E-05,
    2.3908707589725964E-05,
    2.3968039386090823E-05,
    2.4009834305616096E-05,
    2.7333644538884982E-05,
    3.12903976009693E-05,
    0.0,
    1.5199585504888091E-05,
    1.9235301806475036E-05,
    1.9307191905681975E-05,
    1.9357332348590717E-05,
    2.3526159566245042E-05,
    2.8235705030965619E-05,
    0.0,
    1.6285444871755317E-05,
    2.0067029254278168E-05,
    2.0136141756665893E-05,
    2.0184219465591013E-05,
    2.3716800569673069E-05,
    2.7830354156321846E-05,
    1.3286182365845889E-05,
    1.9906596207874827E-05,
    2.2968699340708554E-05,
    2.3027416318655014E-05,
    2.306826354470104E-05,
    2.6107842131750658E-05,
    2.9775885195704177E-05,
    1.26883496704977E-05,
    1.9095328752882779E-05,
    2.2073936634114943E-05,
    2.2133193851914257E-05,
    2.2173831894178875E-05,
    2.5112163712037727E-05,
    2.8686597943305969E-05,
    1.3212041267252062E-05,
    1.9342591258464381E-05,
    2.2219261154532433E-05,
    2.2277690732153133E-05,
    2.2317535695037805E-05,
    2.5148307031486183E-05,
    2.8614465918508358E-05,
    1.2563182281155605E-05,
    1.8505188563722186E-05,
    2.130816392309498E-05,
    2.1367961380747147E-05,
    2.1408000975497998E-05,
    2.4460661734337918E-05,
    2.8167163691250607E-05,
    1.4404603462025989E-05,
    2.0977966414648108E-05,
    2.3897575374576263E-05,
    2.395605952187907E-05,
    2.3996200980036519E-05,
    2.6672436433727853E-05,
    2.9916807761765085E-05,
    1.4949042451917194E-05,
    2.0687783035100438E-05,
    2.3659184080315754E-05,
    2.3717846488580108E-05,
    2.3758242605254054E-05,
    2.6481096938368864E-05,
    2.9763632483081892E-05,
    1.4392313460120931E-05,
    2.0128229152760468E-05,
    2.3092896299203858E-05,
    2.3152353605837561E-05,
    2.3193015294964425E-05,
    2.5892410121741705E-05,
    2.9153474315535277E-05,
    1.4951520824979525E-05,
    2.0558894902933389E-05,
    2.3469398001907393E-05,
    2.3528175006504171E-05,
    2.356834193051327E-05,
    2.6216184778604656E-05,
    2.9420923965517431E-05,
    1.4380358152266126E-05,
    1.9931789211113937E-05,
    2.2816015189164318E-05,
    2.2876298316987231E-05,
    2.2916963644092903E-05,
    2.5818602807703428E-05,
    2.9287979486980475E-05,
    1.4334870684251655E-05,
    2.0660139853134751E-05,
    2.3465328922611661E-05,
    2.3524326024926268E-05,
    2.3564136427012272E-05,
    2.6077619622810744E-05,
    2.9144968721084297E-05,
    1.4946102965041064E-05,
    2.0251458408893086E-05,
    2.3026235794532113E-05,
    2.3084701751940884E-05,
    2.3124064682633616E-05,
    2.5616303901188076E-05,
    2.8667302103713155E-05,
    1.434230671293335E-05,
    1.9544891983969137E-05,
    2.2275717128650285E-05,
    2.233464147138875E-05,
    2.2373906176653691E-05,
    2.4805087377899326E-05,
    2.7807924197986722E-05,
    1.4943903806852177E-05,
    1.9944720406783745E-05,
    2.259021857753396E-05,
    2.264801696583163E-05,
    2.2686430384055711E-05,
    2.5037796149263158E-05,
    2.7960406441707164E-05,
    1.4293953427113593E-05,
    1.9202223484171554E-05,
    2.1810294128954411E-05,
    2.1870368072995916E-05,
    2.1990883396938443E-05,
    2.5372304662596434E-05,
    2.9343456844799221E-05,
    3.3440337574575096E-05,
    3.7535101000685245E-05,
    3.758958337130025E-05,
    3.76252100977581E-05,
    4.041405554744415E-05,
    4.3632262531900778E-05,
    4.7013840230647475E-05,
    5.0466434913687408E-05,
    5.0515489419922233E-05,
    5.0545455451356247E-05,
    5.3003117500338703E-05,
    5.5841512221377343E-05,
    5.8846464526141062E-05,
    6.1941274907439947E-05,
    6.1986786022316664E-05,
    6.2013226852286607E-05,
    6.4254949393216521E-05,
    6.68436914565973E-05,
    6.9593712396454066E-05,
    7.24384153727442E-05,
    7.2481358074583113E-05,
    7.2505332354921848E-05,
    7.4590258009266108E-05,
    7.6996337156742811E-05,
    7.9556324635632336E-05,
    8.2210695836693048E-05,
    8.2251921412535012E-05,
    8.2274767919443548E-05,
    8.4335049905348569E-05,
    8.6704851128160954E-05,
    8.922522101784125E-05,
    9.1840069217141718E-05,
    9.1879774117842317E-05,
    9.1902125859633088E-05,
    9.3768489023204893E-05,
    9.5915835117921233E-05,
    9.82014462351799E-05,
    0.00010057579493150115,
    0.00010061370994662866,
    0.0001006353777484037,
    0.000102419093309436,
    0.00010446658416185528,
    0.00010664564615581185,
    0.00010891034617088735,
    0.00010894701699726284,
    0.00010896822641370818,
    0.00011068074672948569,
    0.00011264236673014239,
    0.0001147292714449577,
    0.00011689869279507548,
    0.00011693426495185122,
    0.00011695505963871256,
    0.00011860541417263448,
    0.00012049185897922143,
    0.00012249774590600282,
    0.00012458294804673642,
    0.00012461739243008196,
    0.00012463775055948645,
    0.00012618025357369334,
    0.00012794233043678105,
    0.00012981568579562008,
    0.00013176335778553039,
    0.00013179662346374244,
    0.00013181651593185961,
    0.00013330912042874843,
    0.00013501102512236685,
    0.00013681968266610056,
    0.00013870011025574058,
    0.00013873254647478461,
    0.00013875211880076677,
    0.00014019991795066744,
    0.0001418477768311277,
    0.00014359803753904998,
    0.00014541747805196792,
    0.00014544912846758962,
    0.00014546839520335197,
    0.00014687530347146094,
    0.00014847391867078841,
    0.00015017081750556827,
    0.00015193437866400927,
    0.0001519653305877,
    0.00015198429173324257,
    0.00015335356874857098,
    0.00015490673831664026,
    0.00015655449533369392,
    0.00015826652816031128,
    0.00015829682524781674,
    0.00015831552445888519,
    0.00015964993508532643,
    0.0001611610350664705,
    0.00016276320093311369,
    0.00016442743071820587,
    0.00016445710207335651,
    0.00016447556845378131,
    0.00016577747010160238,
    0.00016724933811929077,
    0.00016880891053006053,
    0.00017042839317582548,
    0.00017045751155819744,
    0.00017047571600414813,
    0.00017174700042232871,
    0.00017318208119831979,
    0.000174701664946042,
    0.00017627913621254265,
    0.00017630767251830548,
    0.0001763256877893582,
    0.00017756807210389525,
    0.00017896838835440576,
    0.00018045036995317787,
    0.00018198833276983351,
    0.00018201634520664811,
    0.00018203409854322672,
    0.00018324921256862581,
    0.00018461680156178772,
    0.00018606323283165693,
    0.00018756384088192135,
    0.00018759137310553342,
    0.0001876089081633836,
    0.00018879817798733711,
    0.00019013474229723215,
    0.0001915475440910086,
    0.00019301245629321784,
    0.0001930396247189492,
    0.0001930570142576471,
    0.00019422014884185046,
    0.00019552641606424004,
    0.00019690663611982018,
    0.00019833743863273412,
    0.00019836411229334772,
    0.00019838128355331719,
    0.00019952062575612217,
    0.00020079848763998598,
    0.00020214794494677335,
    0.00020354644220788032,
    0.00020357269386295229,
    0.0002035896759480238,
    0.00020470636081881821,
    0.00020595719979610294,
    0.00020727742230519652,
    0.00020864524412900209,
    0.00020867105922661722,
    0.00020868786668870598,
    0.00020978292741347104,
    0.00021100800950080156,
    0.00021230036509223282,
    0.00021363890846259892,
    0.00021366428700275719,
    0.00021368091984186322,
    0.000214755316847004,
    0.00021595580619759858,
    0.00021722154633607715,
    0.0002185321063734591,
    0.00021855712111573666,
    0.00021857356477994472,
    0.000219628112972714,
    0.00022080505732446909,
    0.00022204534616321325,
    0.000223329130676575,
    0.0002233537525171414,
    0.00022337002155836672,
    0.00022440560860559344,
    0.00022555998293682933,
    0.00022677588276565075,
    0.00022803404135629535,
    0.00022805829939898104,
    0.00022807439381722361,
    0.00022909174731466919,
    0.00023022442474029958,
    0.00023141679412219673,
    0.00023265022900886834,
    0.00023267412325367332,
    0.00023269002849701792,
    0.00023368961410596967,
    0.00023480122035834938,
    0.00023597078688908368,
    0.00023718018201179802,
    0.00023720375611446798,
    0.0002372195158386603,
    0.0002382018428761512,
    0.00023929300368763506,
    0.00024044039309956133,
    0.00024162644695024937,
    0.00024164964270312339,
    0.0002416652423562482,
    0.0002426305873086676,
    0.00024370157916564494,
    0.000244827097048983,
    0.0002459900570102036,
    0.00024601293262094259,
    0.00024602838675491512,
    0.00024697688058950007,
    0.000248027965426445,
    0.00024913187371566892,
    0.00025027204537764192,
    0.00025029460084624588,
    0.00025030982214957476,
    0.00025124155217781663,
    0.00025227278820239007,
    0.00025335524696856737,
    0.00025447280495427549,
    0.00025449495296925306,
    0.00025451005785726011,
    0.0002554250240791589,
    0.00025643655681051314,
    0.00025749771157279611,
    0.00025859277229756117,
    0.00025861460017040372,
    0.00025862947222776711,
    0.00025952773285098374,
    0.00026051962049677968,
    0.00026155947125516832,
    0.00026263220934197307,
    0.00026265365886501968,
    0.00026266841450706124,
    0.00026354979490861297,
    0.0002645219792611897,
    0.00026554064243100584,
    0.000266590912360698,
    0.00026661201263777912,
    0.00026662656455300748,
    0.00026749097742140293,
    0.00026844340027309954,
    0.00026944070123136044,
    0.00027046853210777044,
    0.00027048922493122518,
    0.00027050357311964035,
    0.00027135093114338815,
    0.00027228344697505236,
    0.00027325932751409709,
    0.00027426463202573359,
    0.00027428494649939239,
    0.00027429903275333345,
    0.00027512831729836762,
    0.00027604028582572937,
    0.00027699425118044019,
    0.00027797662187367678,
    0.00027799644158221781,
    0.00027801017859019339,
    0.00027882010908797383,
    0.00027971056988462806,
    0.00028064177604392171,
    0.0002816006017383188,
    0.00028161995578557253,
    0.00028163337265141308,
    0.00028242345433682203,
    0.00028329202905297279,
    0.00028420018497854471,
    0.000285135000012815,
    0.00028515385929495096,
    0.00028516695601865649,
    0.00028593692695721984,
    0.00028678326634690166,
    0.00028766808100044727,
    0.00028857876895926893,
    0.00028859713347628713,
    0.00028860988095402718,
    0.00028935962473042309,
    0.00029018375789746642,
    0.00029104520217515528,
    0.00029193170485086739,
    0.00029194960370659828,
    0.00029196194373071194,
    0.00029269151855260134,
    0.00029349332908168435,
    0.000294331373879686,
    0.00029519374947994947,
    0.0002952111535705626,
    0.00029522317345254123,
    0.0002959325211122632,
    0.00029671212541870773,
    0.00029752691625617445,
    0.00029836531030014157,
    0.000298382161417976,
    0.00029839383205398917,
    0.00029908312717452645,
    0.00029984069988131523,
    0.00030063249869272113,
    0.0003014471149072051,
    0.00030146291828714311,
    0.00030147421057336032,
    0.00030214368598535657,
    0.00030287948902696371,
    0.00030364847043529153,
    0.00030443957075476646,
    0.0003044554905500263,
    0.00030446646269410849,
    0.00030511632212437689,
    0.00030583058833144605,
    0.00030657698516733944,
    0.00030734488973394036,
    0.00030736034386791289,
    0.00030737102497369051,
    0.00030800150125287473,
    0.00030869452166371047,
    0.0003094133862759918,
    0.00031015841523185372,
    0.0003101734328083694,
    0.00031018373556435108,
    0.00031079523614607751,
    0.00031146738911047578,
    0.0003121698391623795,
    0.0003128924872726202,
    0.00031290698098018765,
    0.00031291702180169523,
    0.00031350983772426844,
    0.00031416155979968607,
    0.00031484264764003456,
    0.00031554329325444996,
    0.00031555740861222148,
    0.00031556710018776357,
    0.00031614166800864041,
    0.0003167655086144805,
    0.0003174257290083915,
    0.00031810498330742121,
    0.00031811863300390542,
    0.00031812800443731248,
    0.00031868473161011934,
    0.00031929693068377674,
    0.000319936778396368,
    0.00032059504883363843,
    0.00032060826197266579,
    0.00032061737147159874,
    0.00032115669455379248,
    0.00032174980151467025,
    0.00032236971310339868,
    0.00032300746534019709,
    0.00032302030012942851,
    0.00032302908948622644,
    0.0003235514450352639,
    0.00032412592554464936,
    0.00032472642487846315,
    0.00032534421188756824,
    0.00032535658101551235,
    0.00032536507933400571,
    0.00032587087480351329,
    0.00032642719452269375,
    0.00032700871815904975,
    0.00032760703470557928,
    0.000327618996379897,
    0.00032762726186774671,
    0.00032811687560752034,
    0.00032865552930161357,
    0.00032921863021329045,
    0.00032979802927002311,
    0.00032980964169837534,
    0.00032981758704409003,
    0.00033029154292307794,
    0.0003308130253572017,
    0.00033135825651697814,
    0.0003319192910566926,
    0.00033193049603141844,
    0.00033193820854648948,
    0.0003323968849144876,
    0.00033290166174992919,
    0.00033342945971526206,
    0.00033397256629541516,
    0.000333983451128006,
    0.00033399090170860291,
    0.00033443482243455946,
    0.00033492338843643665,
    0.00033543427707627416,
    0.00033596006687730551,
    0.00033597054425626993,
    0.00033597773290239275,
    0.00033640724723227322,
    0.00033688009716570377,
    0.00033737462945282459,
    0.00033788362634368241,
    0.00033789375447668135,
    0.00033790071029216051,
    0.00033831631299108267,
    0.00033877397072501481,
    0.00033925261232070625,
    0.00033974531106650829,
    0.00033975514816120267,
    0.00033976187114603817,
    0.00034016396966762841,
    0.00034060690086334944,
    0.00034107017563655972,
    0.00034154704189859331,
    0.0003415565297473222,
    0.00034156304900534451,
    0.00034195210901089013,
    0.000342380692018196,
    0.00034282906563021243,
    0.00034329056506976485,
    0.00034329973277635872,
    0.00034330604830756783,
    0.00034368247725069523,
    0.00034409717773087323,
    0.00034453108673915267,
    0.00034497783053666353,
    0.00034498670720495284,
    0.00034499281900934875,
    0.00034535702434368432,
    0.00034575833706185222,
    0.00034617824712768197,
    0.00034661061363294721,
    0.0003466192283667624,
    0.0003466250782366842,
    0.00034697740920819342,
    0.00034736571251414716,
    0.00034777203109115362,
    0.00034819054417312145,
    0.00034819883876480162,
    0.00034820454311557114,
    0.00034854540717788041,
    0.00034892113762907684,
    0.00034931441769003868,
    0.00034971945569850504,
    0.0003497274883557111,
    0.00034973295987583697,
    0.0003500626771710813,
    0.00035042630042880774,
    0.00035080686211586,
    0.00035119886160828173,
    0.00035120663233101368,
    0.00035121192922815681,
    0.00035153093631379306,
    0.00035188277252018452,
    0.00035225108149461448,
    0.00035263047902844846,
    0.0003526379878167063,
    0.00035264311009086668,
    0.00035295169800519943,
    0.00035329212551005185,
    0.000353648531017825,
    0.00035401573404669762,
    0.0003540230100043118,
    0.00035402801586315036,
    0.0003543266502674669,
    0.00035465607652440667,
    0.00035500095691531897,
    0.00035535628558136523,
    0.0003553632996045053,
    0.00035536818904802203,
    0.00035565736470744014,
    0.0003559761680662632,
    0.00035630995989777148,
    0.0003566538798622787,
    0.00035666074836626649,
    0.00035666555049829185,
    0.00035694538382813334,
    0.00035725382622331381,
    0.00035757687874138355,
    0.00035790973925031722,
    0.00035791628761217,
    0.00035792097332887352,
    0.000358164485078305,
    0.00035843419027514756,
    0.0003587170212995261,
    0.000359008670784533,
    0.00035930698504671454,
    0.0003596106544137001,
    0.00035991874756291509,
    0.000360230595106259,
    0.00036054558586329222,
    0.00036086337058804929,
    0.00036118354182690382,
    0.00036150580854155123,
    0.00036182993790134788,
    0.00036215560976415873,
    0.00036248273681849241,
    0.00036281105712987483,
    0.00036314042517915368,
    0.00036347072455100715,
    0.00036380192614160478,
    0.00036413368070498109,
    0.00036446598824113607,
    0.00036479881964623928,
    0.00036513197119347751,
    0.00036546538467518985,
    0.00036546538467518985
  ],
  scaleWustit: [
    0.0,
    0.0,
    0.0,
    0.0,
    0.0,
    0.0,
    0.0,
    0.0,
    0.0,
    0.0,
    0.0017067170701920986,
    -0.003298221854493022,
    -0.0017380636418238282,
    -0.000686389859765768,
    9.0086075942963362E-05,
    0.00010295674292137846,
    0.00011530108895385638,
    0.00077046203659847379,
    0.0013444343348965049,
    0.0,
    0.0028699315153062344,
    0.0040735835209488869,
    0.0048578693531453609,
    0.0048708654940128326,
    0.0048833047039806843,
    0.0056527620181441307,
    0.0063139805570244789,
    0.0,
    0.0032682947348803282,
    0.0049563986249268055,
    0.0049741626717150211,
    0.004990660585463047,
    0.0060590733774006367,
    0.0069546913728117943,
    0.0,
    0.0034677865915000439,
    0.0052908062934875488,
    0.00530973169952631,
    0.0053270910866558552,
    0.0064865876920521259,
    0.0074706524610519409,
    0.0,
    0.0036746051628142595,
    0.0056547597050666809,
    0.0056749023497104645,
    0.00569313857704401,
    0.0069511565379798412,
    0.0080281719565391541,
    0.0,
    0.0037449286319315434,
    0.0057719806209206581,
    0.0057925316505134106,
    0.0058109979145228863,
    0.0071051293052732944,
    0.0082263965159654617,
    0.0,
    0.0038916058838367462,
    0.0060468618758022785,
    0.0060683195479214191,
    0.0060873529873788357,
    0.0074644056148827076,
    0.008670782670378685,
    0.0,
    0.0039423117414116859,
    0.0061369184404611588,
    0.00615865970030427,
    0.006177815143018961,
    0.0078271022066473961,
    0.009228990413248539,
    0.0,
    0.0044556772336363792,
    0.0062567256391048431,
    0.0062795532867312431,
    0.0062996335327625275,
    0.0079910177737474442,
    0.0094331586733460426,
    0.0,
    0.0049462900497019291,
    0.0064578489400446415,
    0.0064795468933880329,
    0.0064986846409738064,
    0.00799037329852581,
    0.0093083204701542854,
    0.0,
    0.00500756548717618,
    0.0065425685606896877,
    0.0065653696656227112,
    0.00658533675596118,
    0.0080974157899618149,
    0.0094394320622086525,
    0.0,
    0.0050140502862632275,
    0.0065469290129840374,
    0.0065705268643796444,
    0.0065911007113754749,
    0.008098050020635128,
    0.0094412388280034065,
    0.0,
    0.0051039918325841427,
    0.00667142029851675,
    0.0066961720585823059,
    0.0067175473086535931,
    0.008255859836935997,
    0.009635203517973423,
    0.0,
    0.0051334402523934841,
    0.0067062592133879662,
    0.0067318156361579895,
    0.0067537501454353333,
    0.0082951076328754425,
    0.00968545489013195,
    0.0,
    0.0052267592400312424,
    0.0068408781662583351,
    0.0068690492771565914,
    0.0068928408436477184,
    0.0089403474703431129,
    0.01069245208054781,
    0.0,
    0.0057019740343093872,
    0.0075792665593326092,
    0.0076096532866358757,
    0.0076349549926817417,
    0.0095193367451429367,
    0.011192806996405125,
    0.0,
    0.005973396822810173,
    0.0077010756358504295,
    0.00773107772693038,
    0.0077560697682201862,
    0.009520641528069973,
    0.011112499050796032,
    0.0,
    0.0060228193178772926,
    0.0077670663595199585,
    0.0077981450594961643,
    0.0078238416463136673,
    0.0096015501767396927,
    0.011210648342967033,
    0.0,
    0.006026530172675848,
    0.0077670863829553127,
    0.0077989650890231133,
    0.00782520230859518,
    0.0095950150862336159,
    0.011202247813344002,
    0.0,
    0.0061058574356138706,
    0.00787467323243618,
    0.0079080630093812943,
    0.0079352371394634247,
    0.00984242931008339,
    0.01156077440828085,
    0.0,
    0.00642491364851594,
    0.008120550774037838,
    0.0081536462530493736,
    0.0081804255023598671,
    0.0099225156009197235,
    0.011534224264323711,
    0.0,
    0.0061770281754434109,
    0.00796225294470787,
    0.0079969605430960655,
    0.0080248480662703514,
    0.0098299421370029449,
    0.011490262113511562,
    0.0,
    0.0062617319636046886,
    0.0080785956233739853,
    0.0081143388524651527,
    0.0081410268321633339,
    0.0099755711853504181,
    0.011671398766338825,
    0.0,
    0.0062863156199455261,
    0.0081063751131296158,
    0.0081427982077002525,
    0.0081688407808542252,
    0.010003094561398029,
    0.011705865152180195,
    0.0,
    0.0063602258451282978,
    0.008210429921746254,
    0.0082482248544693,
    0.008273368701338768,
    0.010256129316985607,
    0.01208046730607748,
    0.0,
    0.006498096976429224,
    0.008181961253285408,
    0.0082180816680192947,
    0.0082444706931710243,
    0.0099506387487053871,
    0.011558148078620434,
    0.0,
    0.00616369629278779,
    0.0079067572951316834,
    0.0079441191628575325,
    0.0079719461500644684,
    0.0097103826701641083,
    0.011333371512591839,
    0.0,
    0.0061824899166822433,
    0.0079332413151860237,
    0.0079713258892297745,
    0.0079991035163402557,
    0.00974226277321577,
    0.011371118947863579,
    0.0,
    0.0061721163801848888,
    0.0079163098707795143,
    0.0079548759385943413,
    0.0079829003661870956,
    0.0097160153090953827,
    0.011337395757436752,
    0.0,
    0.006205742247402668,
    0.0079629272222518921,
    0.00800264161080122,
    0.0080302581191062927,
    0.00988413579761982,
    0.011601690202951431,
    0.0,
    0.0065118293277919292,
    0.0081933028995990753,
    0.0082322126254439354,
    0.0082589238882064819,
    0.00994405522942543,
    0.011540519073605537,
    0.0,
    0.0062274830415844917,
    0.00798642635345459,
    0.0080267200246453285,
    0.0080537069588899612,
    0.0097912745550274849,
    0.011426448822021484,
    0.0,
    0.0062714801169931889,
    0.0080465599894523621,
    0.00808750744909048,
    0.0081143863499164581,
    0.0098665039986372,
    0.011518048122525215,
    0.0,
    0.0062791863456368446,
    0.0080531220883131027,
    0.00809448678046465,
    0.0081215472891926765,
    0.0098694087937474251,
    0.011520146392285824,
    0.0,
    0.0063202604651451111,
    0.0081098973751068115,
    0.00815233588218689,
    0.0081798536702990532,
    0.010054233483970165,
    0.011807759292423725,
    0.0,
    0.0066411006264388561,
    0.00835475791245699,
    0.0083961924538016319,
    0.0084229661151766777,
    0.010126108303666115,
    0.011756528168916702,
    0.0,
    0.0063530071638524532,
    0.0081474864855408669,
    0.0081902788951992989,
    0.0082178013399243355,
    0.0099760955199599266,
    0.011648483574390411,
    0.0,
    0.0063924994319677353,
    0.00820108875632286,
    0.0082426629960536957,
    0.0082702385261654854,
    0.010039163753390312,
    0.011727422475814819,
    0.0030907022301107645,
    0.0060372911393642426,
    0.007478905376046896,
    0.0075133489444851875,
    0.0075365188531577587,
    0.0090457778424024582,
    0.010549410246312618,
    0.00018289301078766584,
    0.0031448979862034321,
    0.0045985262840986252,
    0.0046326750889420509,
    0.0046563595533370972,
    0.0064361640252172947,
    0.0081698847934603691,
    0.0,
    0.0069104917347431183,
    0.0088210795074701309,
    0.0088620670139789581,
    0.00889016967266798,
    0.010783015750348568,
    0.012594381347298622,
    0.0,
    0.0068648606538772583,
    0.0087789921090006828,
    0.0088203093037009239,
    0.00884862057864666,
    0.010738077573478222,
    0.012545973993837833,
    0.0,
    0.0068909428082406521,
    0.00881486851722002,
    0.0088553475216031075,
    0.0088836457580327988,
    0.010778623633086681,
    0.012596648186445236,
    0.0039610378444194794,
    0.0071494863368570805,
    0.0086833760142326355,
    0.0087171588093042374,
    0.0087409541010856628,
    0.010355585254728794,
    0.011970865540206432,
    0.0,
    0.0069208508357405663,
    0.0088531794026494026,
    0.0088941119611263275,
    0.0089226597920060158,
    0.010984943248331547,
    0.012940725311636925,
    0.0,
    0.0073764845728874207,
    0.0091980984434485435,
    0.0092374486848711967,
    0.0092648221179842949,
    0.011073305271565914,
    0.012841037474572659,
    0.0045600808225572109,
    0.0076604927890002728,
    0.0091736307367682457,
    0.0092070624232292175,
    0.0092303194105625153,
    0.010818186216056347,
    0.012425325810909271,
    0.0026962643023580313,
    0.0058264071121811867,
    0.0073545956984162331,
    0.0073883337900042534,
    0.0074114729650318623,
    0.00900841411203146,
    0.010632127523422241,
    0.0018214413430541754,
    0.0048497915267944336,
    0.0063462601974606514,
    0.0063795279711484909,
    0.0064022145234048367,
    0.0079686185345053673,
    0.0095711462199687958,
    0.00043209275463595986,
    0.0034947905223816633,
    0.0050085578113794327,
    0.0050426041707396507,
    0.0050654015503823757,
    0.0067894910462200642,
    0.0085357800126075745,
    0.00069984706351533532,
    0.0039026828017085791,
    0.0053470456041395664,
    0.0053803450427949429,
    0.0054031992331147194,
    0.00687643326818943,
    0.0083885947242379189,
    -0.00013354202383197844,
    0.0026624116580933332,
    0.0041277459822595119,
    0.0041611455380916595,
    0.004184146411716938,
    0.0056761377491056919,
    0.0072014597244560719,
    -0.0015504478942602873,
    0.0012943184701725841,
    0.0027790837921202183,
    0.002812935970723629,
    0.0028360879514366388,
    0.0043407287448644638,
    0.005880025215446949,
    -0.0022060840856283903,
    0.00056615774519741535,
    0.0020237453281879425,
    0.0020572107750922441,
    0.0020800807978957891,
    0.0035599968396127224,
    0.0050783427432179451,
    -0.0034852437674999237,
    -0.00067368888994678855,
    0.000801683752797544,
    0.00083600630750879645,
    0.00085916015086695552,
    0.0024889414198696613,
    0.0041415621526539326,
    -0.0043722297996282578,
    -0.0012059076689183712,
    0.00022594463371206075,
    0.00025953550357371569,
    0.0002822022361215204,
    0.0017132952343672514,
    0.0032082635443657637,
    -0.0048760874196887016,
    -0.0021593836136162281,
    -0.00071937765460461378,
    -0.00068608816945925355,
    -0.00066367641557008028,
    0.00075532007031142712,
    0.002261144807562232,
    -0.0058950199745595455,
    -0.0031461864709854126,
    -0.0016897263703867793,
    -0.0016561774536967278,
    -0.0016338217537850142,
    -0.00024958944413810968,
    0.0012714426266029477,
    -0.006052892655134201,
    -0.00339578278362751,
    -0.0019734639208763838,
    -0.0019405547063797712,
    -0.0019186836434528232,
    -0.00057989597553387284,
    0.00091661128681153059,
    -0.0068646022118628025,
    -0.0041731465607881546,
    -0.0027320687659084797,
    -0.0026978645473718643,
    -0.0026292474940419197,
    -0.00070398097159340978,
    0.0012606512755155563,
    0.0031051309779286385,
    0.0048247105441987514,
    0.0048557310365140438,
    0.0048760161735117435,
    0.0062438659369945526,
    0.0076408577151596546,
    0.0090068597346544266,
    0.010327483527362347,
    0.010354866273701191,
    0.010371929034590721,
    0.011476332321763039,
    0.012617692351341248,
    0.013751162216067314,
    0.014863606542348862,
    0.014887319877743721,
    0.014902372844517231,
    0.015850486233830452,
    0.016835814341902733,
    0.01782212033867836,
    0.018798079341650009,
    0.018819291144609451,
    0.018832942470908165,
    0.019674846902489662,
    0.020552417263388634,
    0.021434949710965157,
    0.022312620654702187,
    0.022332165390253067,
    0.022345172241330147,
    0.02314295805990696,
    0.02397451363503933,
    0.024812260642647743,
    0.02564733661711216,
    0.025665411725640297,
    0.025678133592009544,
    0.026379792019724846,
    0.027112865820527077,
    0.027853669598698616,
    0.0285946037620306,
    0.02861134335398674,
    0.028623679652810097,
    0.029276976361870766,
    0.029959607869386673,
    0.030650429427623749,
    0.031342595815658569,
    0.031358368694782257,
    0.031370438635349274,
    0.031983695924282074,
    0.032624460756778717,
    0.033273573964834213,
    0.03392481803894043,
    0.033939775079488754,
    0.033951543271541595,
    0.034531015902757645,
    0.035136360675096512,
    0.035750027745962143,
    0.036366347223520279,
    0.036380507051944733,
    0.036391731351614,
    0.036925822496414185,
    0.037484083324670792,
    0.038050591945648193,
    0.038620226085186005,
    0.038633681833744049,
    0.038644414395093918,
    0.039154257625341415,
    0.039687000215053558,
    0.040227875113487244,
    0.040772106498479843,
    0.040785029530525208,
    0.04079538956284523,
    0.041283883154392242,
    0.041794136166572571,
    0.042312342673540115,
    0.04283403605222702,
    0.04284648597240448,
    0.042856507003307343,
    0.043325982987880707,
    0.043816197663545609,
    0.044314153492450714,
    0.044815678149461746,
    0.044827699661254883,
    0.04483741894364357,
    0.045289818197488785,
    0.045761998742818832,
    0.046241726726293564,
    0.046725057065486908,
    0.046736691147089005,
    0.046746134757995605,
    0.0471830740571022,
    0.047638934105634689,
    0.048102129250764847,
    0.048568949103355408,
    0.048580225557088852,
    0.048589427024126053,
    0.049012292176485062,
    0.049453273415565491,
    0.049901388585567474,
    0.050353106111288071,
    0.050364069640636444,
    0.050373028963804245,
    0.050782985985279083,
    0.051210328936576843,
    0.051644593477249146,
    0.05208243802189827,
    0.052093099802732468,
    0.052101850509643555,
    0.052499905228614807,
    0.05291467159986496,
    0.053336180746555328,
    0.053761240094900131,
    0.053771618753671646,
    0.053780160844326019,
    0.054167222231626511,
    0.05457036942243576,
    0.054980069398880005,
    0.0553932823240757,
    0.05540340393781662,
    0.05541175976395607,
    0.055788625031709671,
    0.056180976331233978,
    0.056579709053039551,
    0.056981820613145828,
    0.056991759687662125,
    0.056999985128641129,
    0.057367108762264252,
    0.057749327272176743,
    0.058137774467468262,
    0.0585295632481575,
    0.058539267629384995,
    0.058547325432300568,
    0.058905526995658875,
    0.059278316795825958,
    0.059657175093889236,
    0.060039326548576355,
    0.060048818588256836,
    0.060056723654270172,
    0.060406569391489029,
    0.060770526528358459,
    0.061140403151512146,
    0.061513528227806091,
    0.061522819101810455,
    0.06153058260679245,
    0.061872582882642746,
    0.062228258699178696,
    0.062589690089225769,
    0.062954336404800415,
    0.062963441014289856,
    0.062971055507659912,
    0.063305675983428955,
    0.063653551042079926,
    0.064007043838500977,
    0.064363688230514526,
    0.064372614026069641,
    0.06438010185956955,
    0.064707756042480469,
    0.065048269927501678,
    0.065394267439842224,
    0.0657433569431305,
    0.065752118825912476,
    0.065759479999542236,
    0.066080562770366669,
    0.066414110362529755,
    0.066753022372722626,
    0.067094966769218445,
    0.0671035572886467,
    0.067110799252986908,
    0.067425645887851715,
    0.06775260716676712,
    0.068084783852100372,
    0.0684199407696724,
    0.0684283897280693,
    0.068435505032539368,
    0.06874440610408783,
    0.069065079092979431,
    0.069390840828418732,
    0.06971953809261322,
    0.0697278380393982,
    0.0697348490357399,
    0.0700380951166153,
    0.07035277783870697,
    0.0706724226474762,
    0.070994943380355835,
    0.071003101766109467,
    0.071010015904903412,
    0.07130780816078186,
    0.071616731584072113,
    0.071930505335330963,
    0.07224707305431366,
    0.072255104780197144,
    0.072261914610862732,
    0.07255447655916214,
    0.072857864201068878,
    0.073165975511074066,
    0.073476828634738922,
    0.073484726250171661,
    0.073491431772708893,
    0.073778927326202393,
    0.074076972901821136,
    0.074379608035087585,
    0.074684940278530121,
    0.074692696332931519,
    0.074699327349662781,
    0.0749819278717041,
    0.07527480274438858,
    0.075572162866592407,
    0.075872145593166351,
    0.0758797898888588,
    0.0758863091468811,
    0.076164171099662781,
    0.07645203173160553,
    0.076744258403778076,
    0.077039077877998352,
    0.077046602964401245,
    0.077053040266036987,
    0.077326282858848572,
    0.077609255909919739,
    0.077896513044834137,
    0.078186258673667908,
    0.07819366455078125,
    0.078200012445449829,
    0.078468739986419678,
    0.0787469670176506,
    0.079029366374015808,
    0.0793142020702362,
    0.07932148873806,
    0.07932773232460022,
    0.07959207147359848,
    0.079865649342536926,
    0.0801432877779007,
    0.080423325300216675,
    0.080430500209331512,
    0.080436646938323975,
    0.080696575343608856,
    0.080965556204319,
    0.0812385156750679,
    0.081513829529285431,
    0.08152085542678833,
    0.081526905298233032,
    0.081782370805740356,
    0.0820467472076416,
    0.082315050065517426,
    0.08258567750453949,
    0.082592599093914032,
    0.08259853720664978,
    0.082849569618701935,
    0.083109401166439056,
    0.083373092114925385,
    0.083639077842235565,
    0.08364587277173996,
    0.083651706576347351,
    0.083898350596427917,
    0.0841536819934845,
    0.0844128429889679,
    0.084674268960952759,
    0.0846809521317482,
    0.084686674177646637,
    0.084929011762142181,
    0.085179924964904785,
    0.085434623062610626,
    0.085691556334495544,
    0.085698120296001434,
    0.085703730583190918,
    0.085941828787326813,
    0.08618839830160141,
    0.086438693106174469,
    0.0866912230849266,
    0.086697667837142944,
    0.086703181266784668,
    0.0869370847940445,
    0.087179370224475861,
    0.0874253436923027,
    0.087673544883728027,
    0.087679855525493622,
    0.087685279548168182,
    0.0879150852560997,
    0.0881531611084938,
    0.088394917547702789,
    0.088638849556446075,
    0.088644996285438538,
    0.088650301098823547,
    0.08887607604265213,
    0.089110031723976135,
    0.089347615838050842,
    0.089587390422821045,
    0.089593492448329926,
    0.089598692953586578,
    0.0898205116391182,
    0.090050406754016876,
    0.090283907949924469,
    0.090519584715366364,
    0.090525582432746887,
    0.09053068608045578,
    0.090748593211174011,
    0.090974517166614532,
    0.091204315423965454,
    0.091435961425304413,
    0.09144185483455658,
    0.091446861624717712,
    0.091660954058170319,
    0.091882973909378052,
    0.092108540236949921,
    0.0923362523317337,
    0.09234202653169632,
    0.092346951365470886,
    0.09255729615688324,
    0.092775486409664154,
    0.092997193336486816,
    0.0932210311293602,
    0.093226715922355652,
    0.093231543898582458,
    0.093438223004341125,
    0.0936330258846283,
    0.093850955367088318,
    0.09407101571559906,
    0.094076596200466156,
    0.0940813347697258,
    0.094284407794475555,
    0.094495177268981934,
    0.094709411263465881,
    0.094925753772258759,
    0.0949312224984169,
    0.094935879111289978,
    0.095135435461997986,
    0.095342598855495453,
    0.0955531969666481,
    0.095765881240367889,
    0.095771282911300659,
    0.095775850117206573,
    0.095971941947937012,
    0.09617556631565094,
    0.096382610499858856,
    0.096591740846633911,
    0.096597015857696533,
    0.096601486206054688,
    0.096794188022613525,
    0.096994340419769287,
    0.097197882831096649,
    0.097403503954410553,
    0.097408689558506012,
    0.0974130854010582,
    0.097602449357509613,
    0.097799204289913177,
    0.097999326884746552,
    0.098201513290405273,
    0.098206609487533569,
    0.098210915923118591,
    0.098397031426429749,
    0.098590455949306488,
    0.098787218332290649,
    0.098986051976680756,
    0.098991051316261292,
    0.098995283246040344,
    0.099178187549114227,
    0.099368326365947723,
    0.099561795592308044,
    0.099757306277751923,
    0.099762238562107086,
    0.099766403436660767,
    0.099946171045303345,
    0.10013311356306076,
    0.10032334923744202,
    0.10051563382148743,
    0.10052046179771423,
    0.10052452981472015,
    0.10070119798183441,
    0.10088498890399933,
    0.10107206553220749,
    0.10126117616891861,
    0.10126591473817825,
    0.10126990079879761,
    0.10144355893135071,
    0.10162428766489029,
    0.10180826485157013,
    0.10199426114559174,
    0.10199892520904541,
    0.10200273990631104,
    0.10217344015836716,
    0.10235115885734558,
    0.10253208130598068,
    0.10271502286195755,
    0.10271959751844406,
    0.10272330045700073,
    0.10289110988378525,
    0.1030658483505249,
    0.10324379056692123,
    0.10342372953891754,
    0.10342822223901749,
    0.1034318134188652,
    0.1035967692732811,
    0.10376859456300736,
    0.1039436087012291,
    0.10412062704563141,
    0.10412504523992539,
    0.10412852466106415,
    0.10429069399833679,
    0.10445967316627502,
    0.10463180392980576,
    0.10480593889951706,
    0.10481029003858566,
    0.10481363534927368,
    0.10497305542230606,
    0.10513923317193985,
    0.10530854016542435,
    0.10547985136508942,
    0.10548410564661026,
    0.10548733919858933,
    0.1056440994143486,
    0.10580751299858093,
    0.10597405582666397,
    0.10614258050918579,
    0.10614677518606186,
    0.10614990442991257,
    0.10630399733781815,
    0.10646472871303558,
    0.10662855207920075,
    0.10679434239864349,
    0.10679846256971359,
    0.10680147260427475,
    0.10695298761129379,
    0.10711108148097992,
    0.10727223753929138,
    0.10743535310029984,
    0.10743939131498337,
    0.10744231194257736,
    0.10759127885103226,
    0.10774676501750946,
    0.10790528357028961,
    0.10806576162576675,
    0.10806974768638611,
    0.10807259380817413,
    0.10821908712387085,
    0.10837200284004211,
    0.10852794349193573,
    0.10868581384420395,
    0.10868971794843674,
    0.10869250446557999,
    0.1088365912437439,
    0.10898701101541519,
    0.10914041846990585,
    0.10929575562477112,
    0.10929961502552033,
    0.1093023493885994,
    0.10944405198097229,
    0.10959199070930481,
    0.1097429096698761,
    0.109895721077919,
    0.10989949107170105,
    0.10990215092897415,
    0.11002854257822037,
    0.11016083508729935,
    0.11029590666294098,
    0.11043275892734528,
    0.11057090759277344,
    0.11071006953716278,
    0.11085005104541779,
    0.11099070310592651,
    0.11113189160823822,
    0.11127356439828873,
    0.11141562461853027,
    0.11155801266431809,
    0.11170069128274918,
    0.11184358596801758,
    0.1119866818189621,
    0.11212991178035736,
    0.11227326840162277,
    0.11241672933101654,
    0.11256030201911926,
    0.11270388960838318,
    0.11284749954938889,
    0.11299113929271698,
    0.11313475668430328,
    0.1132783442735672,
    0.1132783442735672
  ],
  scaleMagnetit: [
    0.0,
    0.0,
    0.0,
    0.0,
    0.0,
    0.0,
    0.0,
    0.0,
    0.0,
    0.0,
    0.01018050778657198,
    0.0046132816933095455,
    0.00944406259804964,
    0.014149450697004795,
    0.017963042482733727,
    0.01801796443760395,
    0.018063412979245186,
    0.021318418905138969,
    0.02461656928062439,
    0.0,
    0.0091202529147267342,
    0.014474228024482727,
    0.018202047795057297,
    0.018255509436130524,
    0.018299488350749016,
    0.022044569253921509,
    0.025755066424608231,
    0.0,
    0.006466379389166832,
    0.011460334993898869,
    0.011503451503813267,
    0.011535954661667347,
    0.015036646276712418,
    0.018704280257225037,
    0.0,
    0.0052036913111805916,
    0.0094566876068711281,
    0.0094920303672552109,
    0.009517572820186615,
    0.012558508664369583,
    0.01589389331638813,
    0.0,
    0.0038140271790325642,
    0.0073730633594095707,
    0.0074011432006955147,
    0.0074203712865710258,
    0.010031615383923054,
    0.013023587875068188,
    0.0,
    0.0033149337396025658,
    0.0064220298081636429,
    0.0064459075219929218,
    0.0064616207964718342,
    0.0087291458621621132,
    0.011388496495783329,
    0.0,
    0.0022020034957677126,
    0.0046593127772212029,
    0.0046769077889621258,
    0.0046874405816197395,
    0.0065346457995474339,
    0.0088257938623428345,
    0.0,
    0.001781265833415091,
    0.0038934685289859772,
    0.0039079505950212479,
    0.0039159958250820637,
    0.0059640132822096348,
    0.0084952237084507942,
    0.0,
    0.0035714877303689718,
    0.0056434101425111294,
    0.0056618526577949524,
    0.0056724329479038715,
    0.00804722961038351,
    0.010856428183615208,
    0.0,
    0.0055077611468732357,
    0.00752778397873044,
    0.00754853431135416,
    0.0075608915649354458,
    0.00978607777506113,
    0.012411657720804215,
    0.0,
    0.0050716917030513287,
    0.0069597261026501656,
    0.00697927875444293,
    0.0069903628900647163,
    0.00906007457524538,
    0.011541766114532948,
    0.0,
    0.0050158821977674961,
    0.00685173599049449,
    0.0068711526691913605,
    0.0068818605504930019,
    0.0088576227426528931,
    0.011236944235861301,
    0.0,
    0.0043627070263028145,
    0.0060006515122950077,
    0.0060176625847816467,
    0.0060262284241616726,
    0.0077863512560725212,
    0.0099623855203390121,
    0.0,
    0.0041371523402631283,
    0.0056698801927268505,
    0.0056857820600271225,
    0.0056932759471237659,
    0.0073161614127457142,
    0.0093549825251102448,
    0.0,
    0.0034026196226477623,
    0.00472590746358037,
    0.0047393906861543655,
    0.0047444063238799572,
    0.0068298736587166786,
    0.0093757975846529,
    0.0,
    0.0047953100875020027,
    0.006592760793864727,
    0.0066095544025301933,
    0.00661573326215148,
    0.0086747230961918831,
    0.011203410103917122,
    0.0,
    0.0059081590734422207,
    0.0077160792425274849,
    0.0077345641329884529,
    0.0077418098226189613,
    0.0097492532804608345,
    0.012198176234960556,
    0.0,
    0.0055448003113269806,
    0.0072509255260229111,
    0.007268149871379137,
    0.00727419275790453,
    0.0091549800708889961,
    0.011479812674224377,
    0.0,
    0.0055063650943338871,
    0.0071728480979800224,
    0.00718979025259614,
    0.0071953693404793739,
    0.0090003963559865952,
    0.011241507716476917,
    0.0,
    0.0049122869968414307,
    0.0064212707802653313,
    0.0064359609968960285,
    0.006439591757953167,
    0.0082218488678336143,
    0.010454896837472916,
    0.0,
    0.00503541762009263,
    0.0064016166143119335,
    0.0064147859811782837,
    0.006417382974177599,
    0.0078815855085849762,
    0.00978926382958889,
    0.0,
    0.004361358005553484,
    0.00568224536255002,
    0.0056941360235214233,
    0.0056955520994961262,
    0.0070917056873440742,
    0.0089303683489561081,
    0.0,
    0.0036909340415149927,
    0.0048360303044319153,
    0.0048450417816638947,
    0.0048460108228027821,
    0.0060604894533753395,
    0.007725526113063097,
    0.0,
    0.0034821729641407728,
    0.0045483545400202274,
    0.0045561301521956921,
    0.0045570740476250648,
    0.005671878345310688,
    0.0072330133989453316,
    0.0,
    0.00284842518158257,
    0.0037494455464184284,
    0.0037543973885476589,
    0.00375530868768692,
    0.0048093725927174091,
    0.0063242479227483273,
    0.0,
    0.0044317184947431087,
    0.0055104400962591171,
    0.0055193775333464146,
    0.005520334467291832,
    0.0065591023303568363,
    0.0079903760924935341,
    0.0,
    0.0044108303263783455,
    0.0055833766236901283,
    0.0055934786796569824,
    0.005594487302005291,
    0.0066955490037798882,
    0.00817758496850729,
    0.0,
    0.0042582969181239605,
    0.0053964969702064991,
    0.0054060593247413635,
    0.0054070660844445229,
    0.0064693894237279892,
    0.0079112006351351738,
    0.0,
    0.0043285363353788853,
    0.005470707081258297,
    0.0054804109968245029,
    0.0054814275354146957,
    0.0065317307598888874,
    0.0079559711739420891,
    0.0,
    0.00405395170673728,
    0.0051314434967935085,
    0.0051399548538029194,
    0.0051409569568932056,
    0.00622247951105237,
    0.0076872501522302628,
    0.0,
    0.0042810728773474693,
    0.005294660571962595,
    0.00530260568484664,
    0.00530357426032424,
    0.0062176189385354519,
    0.00750008737668395,
    0.0,
    0.0038737026043236256,
    0.0048853736370801926,
    0.0048926998861134052,
    0.0048936787061393261,
    0.0057909833267331123,
    0.007057087030261755,
    0.0,
    0.0035152013879269361,
    0.0044406810775399208,
    0.0044463425874710083,
    0.0044473176822066307,
    0.005265441257506609,
    0.0064509697258472443,
    0.0,
    0.00344462669454515,
    0.0043392926454544067,
    0.0043443795293569565,
    0.0043453611433506012,
    0.0051235663704574108,
    0.0062646958976984024,
    0.0,
    0.0030892097856849432,
    0.0038974413182586432,
    0.0039007805753499269,
    0.0039017782546579838,
    0.0046748863533139229,
    0.0058214315213263035,
    0.0,
    0.0032010306604206562,
    0.00394127843901515,
    0.0039438609965145588,
    0.0039448314346373081,
    0.0045688310638070107,
    0.0055459169670939445,
    0.0,
    0.0028038963209837675,
    0.0035214440431445837,
    0.0035230531357228756,
    0.0035240519791841507,
    0.0041171424090862274,
    0.0050651389174163342,
    0.0,
    0.0024489236529916525,
    0.003077101893723011,
    0.0030786097049713135,
    0.0030796101782470942,
    0.003587222658097744,
    0.0044499859213829041,
    0.0041367379017174244,
    0.005219716578722,
    0.0056924270465970039,
    0.005693675484508276,
    0.0056945155374705791,
    0.00609791511669755,
    0.0068287765607237816,
    0.0064527895301580429,
    0.0073799910023808479,
    0.0077865840867161751,
    0.007787823211401701,
    0.0077886823564767838,
    0.0082512693479657173,
    0.00907990988343954,
    0.0,
    0.0024162305053323507,
    0.0029724929481744766,
    0.0029739798046648502,
    0.0029749993700534105,
    0.0034383581951260567,
    0.00427089910954237,
    0.0,
    0.0024380362592637539,
    0.0029960335232317448,
    0.0029975315555930138,
    0.0029985583387315273,
    0.003452245844528079,
    0.0042691701091825962,
    0.0,
    0.002186979167163372,
    0.0026846053078770638,
    0.0026860733050853014,
    0.0026870991569012403,
    0.0030793452169746161,
    0.0038340226747095585,
    0.0035208144690841436,
    0.0045152371749281883,
    0.0048978626728057861,
    0.0048990878276526928,
    0.004899950698018074,
    0.0052181296050548553,
    0.005866234190762043,
    0.0,
    0.0018667089752852917,
    0.0022782071027904749,
    0.0022796913981437683,
    0.0022807265631854534,
    0.0026464203838258982,
    0.0033893322106450796,
    0.0,
    0.0020342848729342222,
    0.002410069340839982,
    0.0024114963598549366,
    0.0024124886840581894,
    0.0026673262473195791,
    0.0032647834159433842,
    0.002964438172057271,
    0.0037014011759310961,
    0.0039715059101581573,
    0.0039727184921503067,
    0.0039735613390803337,
    0.0041639748960733414,
    0.0046698292717337608,
    0.0043169613927602768,
    0.0049143368378281593,
    0.0051262108609080315,
    0.0051274346187710762,
    0.0051282746717333794,
    0.0052557317540049553,
    0.0056981067173182964,
    0.00537854852154851,
    0.0059202695265412331,
    0.00610620342195034,
    0.0061074094846844673,
    0.0061082327738404274,
    0.006205377634614706,
    0.0066096000373363495,
    0.00627813208848238,
    0.0066879838705062866,
    0.0068183774128556252,
    0.00681961327791214,
    0.0068204398266971111,
    0.0068930299021303654,
    0.0072962939739227295,
    0.0070120887830853462,
    0.00763268768787384,
    0.0078887483105063438,
    0.0078899553045630455,
    0.0078907841816544533,
    0.00798967108130455,
    0.0083572305738925934,
    0.0080481367185711861,
    0.0085901319980621338,
    0.00885491818189621,
    0.0088561298325657845,
    0.0088569652289152145,
    0.0089639164507389069,
    0.009339907206594944,
    0.009022480808198452,
    0.0095191104337573051,
    0.0097628012299537659,
    0.0097640287131071091,
    0.0097648678347468376,
    0.0098474379628896713,
    0.010199683718383312,
    0.009906405583024025,
    0.010399799793958664,
    0.010639085434377194,
    0.010640298947691917,
    0.010641127824783325,
    0.01071842759847641,
    0.011059568263590336,
    0.0107489712536335,
    0.011177030391991139,
    0.011386171914637089,
    0.011387416161596775,
    0.011388256214559078,
    0.011465574614703655,
    0.011826952919363976,
    0.011518162675201893,
    0.012039444409310818,
    0.012245655991137028,
    0.012246874161064625,
    0.012247695587575436,
    0.012299601919949055,
    0.012588817626237869,
    0.012295603752136231,
    0.012677728198468685,
    0.012860218994319439,
    0.012861426919698715,
    0.012862239964306355,
    0.012913705781102181,
    0.013184471055865288,
    0.012888651341199875,
    0.013186303898692131,
    0.013330120593309403,
    0.01333133690059185,
    0.01333214808255434,
    0.013382352888584137,
    0.013613689690828323,
    0.013348041102290154,
    0.013620567508041859,
    0.013749494217336178,
    0.01375068724155426,
    0.013751482591032982,
    0.013800038956105709,
    0.01401060726493597,
    0.013728385791182518,
    0.013919374905526638,
    0.014010806567966938,
    0.014012047089636326,
    0.014014537446200848,
    0.014084365218877792,
    0.014432596042752266,
    0.014954219572246075,
    0.01558367908000946,
    0.015584805049002171,
    0.015585539862513542,
    0.015840759500861168,
    0.016295971348881721,
    0.016862241551280022,
    0.017503101378679276,
    0.017504563555121422,
    0.017505181953310966,
    0.017819924280047417,
    0.018299683928489685,
    0.01887078583240509,
    0.019504433497786522,
    0.01950734481215477,
    0.019507890567183495,
    0.019846688956022263,
    0.020331284031271935,
    0.020896138623356819,
    0.021516453474760056,
    0.02152024582028389,
    0.021520741283893585,
    0.021870147436857224,
    0.022352328523993492,
    0.022907130420207977,
    0.023512305691838264,
    0.023516682907938957,
    0.023517152294516563,
    0.023891400545835495,
    0.024393167346715927,
    0.024964308366179466,
    0.025583704933524132,
    0.025588583201169968,
    0.025589045137166977,
    0.025945704430341721,
    0.026416875422000885,
    0.026949768885970116,
    0.027525652199983597,
    0.027530768886208534,
    0.02753121592104435,
    0.02788642980158329,
    0.028348773717880249,
    0.028868900611996651,
    0.02942935936152935,
    0.029434673488140106,
    0.029435116797685623,
    0.029787642881274223,
    0.030241085216403008,
    0.030748892575502396,
    0.031294744461774826,
    0.031300202012062073,
    0.031300671398639679,
    0.03164980560541153,
    0.032094404101371765,
    0.0325903557240963,
    0.033122282475233078,
    0.033127840608358383,
    0.033128578215837479,
    0.033460989594459534,
    0.033881872892379761,
    0.034350093454122543,
    0.03485146164894104,
    0.034857012331485748,
    0.034857947379350662,
    0.035185225307941437,
    0.035596739500761032,
    0.03605339303612709,
    0.036541715264320374,
    0.036547295749187469,
    0.036548390984535217,
    0.036870691925287247,
    0.037273436784744263,
    0.037719264626502991,
    0.038195360451936722,
    0.038200955837965012,
    0.0382021889090538,
    0.038519561290740967,
    0.038913935422897339,
    0.0393495075404644,
    0.039814025163650513,
    0.0398196205496788,
    0.039820976555347443,
    0.040133439004421234,
    0.040519744157791138,
    0.040945529937744141,
    0.041399087756872177,
    0.041404671967029572,
    0.041406136006116867,
    0.041713755577802658,
    0.042092282325029373,
    0.04250871017575264,
    0.0429518036544323,
    0.0429573692381382,
    0.042958933860063553,
    0.043261762708425522,
    0.043632760643959045,
    0.044040169566869736,
    0.044473197311162949,
    0.044478744268417358,
    0.04448038712143898,
    0.044778425246477127,
    0.045142125338315964,
    0.045540828257799149,
    0.045964181423187256,
    0.045969694852828979,
    0.04597141221165657,
    0.046264700591564178,
    0.046621270477771759,
    0.047011580318212509,
    0.047425650060176849,
    0.047431129962205887,
    0.047432899475097656,
    0.047721508890390396,
    0.048071175813674927,
    0.048453379422426224,
    0.048858493566513062,
    0.048863939940929413,
    0.048865765333175659,
    0.0491497777402401,
    0.049492746591567993,
    0.049867115914821625,
    0.050263505429029465,
    0.050268925726413727,
    0.050270788371562958,
    0.05054967850446701,
    0.050885800272226334,
    0.051252331584692,
    0.051640179008245468,
    0.051645543426275253,
    0.051647450774908066,
    0.051921728998422623,
    0.052251365035772324,
    0.052610412240028381,
    0.052990078926086426,
    0.052995402365922928,
    0.052997339516878128,
    0.053267121315002441,
    0.053590498864650726,
    0.053942333906888962,
    0.054314121603965759,
    0.054319389164447784,
    0.054321359843015671,
    0.054586753249168396,
    0.054904062300920486,
    0.05524892359972,
    0.055613107979297638,
    0.055618323385715485,
    0.05562032014131546,
    0.055881410837173462,
    0.056192848831415176,
    0.056530967354774475,
    0.05688781663775444,
    0.056892987340688705,
    0.05689501017332077,
    0.057151880115270615,
    0.057457603514194489,
    0.057789202779531479,
    0.058138951659202576,
    0.058144062757492065,
    0.058146104216575623,
    0.058398887515068054,
    0.058699071407318115,
    0.059024352580308914,
    0.059367243200540543,
    0.059372309595346451,
    0.0593743696808815,
    0.059623144567012787,
    0.059917919337749481,
    0.060237035155296326,
    0.06057322770357132,
    0.060578253120183945,
    0.060580316931009293,
    0.06082509458065033,
    0.061114545911550522,
    0.061427593231201172,
    0.061757192015647888,
    0.061762154102325439,
    0.061764229089021683,
    0.062005043029785156,
    0.062289219349622726,
    0.062596291303634644,
    0.062919415533542633,
    0.062924310564994812,
    0.062926396727561951,
    0.063163198530673981,
    0.063442081212997437,
    0.063743166625499725,
    0.064059756696224213,
    0.064064592123031616,
    0.064066678285598755,
    0.064299359917640686,
    0.064572915434837341,
    0.064867950975894928,
    0.065177969634532928,
    0.065182752907276154,
    0.065184839069843292,
    0.065413318574428558,
    0.065681427717208862,
    0.065970353782176971,
    0.0662737786769867,
    0.066278472542762756,
    0.0662805512547493,
    0.066504701972007751,
    0.066767312586307526,
    0.067050047218799591,
    0.06734679639339447,
    0.067351415753364563,
    0.067353472113609314,
    0.067573204636573792,
    0.067830212414264679,
    0.068106681108474731,
    0.0683966875076294,
    0.068401232361793518,
    0.068403288722038269,
    0.068618446588516235,
    0.068869717419147491,
    0.069139815866947174,
    0.06942296028137207,
    0.069427430629730225,
    0.069429449737071991,
    0.0696398913860321,
    0.069885313510894775,
    0.070148900151252747,
    0.070425070822238922,
    0.070429429411888123,
    0.0704314261674881,
    0.070637024939060211,
    0.070876449346542358,
    0.071133412420749664,
    0.071402490139007568,
    0.071406751871109009,
    0.071408689022064209,
    0.071608953177928925,
    0.07184203714132309,
    0.072092078626155853,
    0.07235381007194519,
    0.072357930243015289,
    0.072359807789325714,
    0.072554148733615875,
    0.072780407965183258,
    0.073023125529289246,
    0.073277190327644348,
    0.0732811689376831,
    0.073282957077026367,
    0.073471128940582275,
    0.073690347373485565,
    0.073925547301769257,
    0.074171692132949829,
    0.074175529181957245,
    0.07417723536491394,
    0.07435905933380127,
    0.074571043252944946,
    0.074798546731472015,
    0.075036622583866119,
    0.0750403180718422,
    0.075041942298412323,
    0.075217276811599731,
    0.07542196661233902,
    0.0756416767835617,
    0.07587161660194397,
    0.075875155627727509,
    0.075876697897911072,
    0.076045498251914978,
    0.076242782175540924,
    0.076454624533653259,
    0.0766763836145401,
    0.0766797810792923,
    0.0766812190413475,
    0.076843470335006714,
    0.07703331857919693,
    0.07723727822303772,
    0.077450841665267944,
    0.0774540901184082,
    0.07745545357465744,
    0.077611096203327179,
    0.077793553471565247,
    0.077989697456359863,
    0.078195109963417053,
    0.078197993338108063,
    0.078199252486228943,
    0.078348368406295776,
    0.078523457050323486,
    0.078711792826652527,
    0.078909114003181458,
    0.078912056982517242,
    0.078913234174251556,
    0.079055860638618469,
    0.0792236402630806,
    0.079404234886169434,
    0.079593516886234283,
    0.079596325755119324,
    0.079597420990467072,
    0.079733602702617645,
    0.079894177615642548,
    0.080064281821250916,
    0.080245643854141235,
    0.08024829626083374,
    0.080249287188053131,
    0.080379143357276916,
    0.080532580614089966,
    0.080698013305664062,
    0.0808715671300888,
    0.080874070525169373,
    0.0808749869465828,
    0.080998606979846954,
    0.081145048141479492,
    0.08130308985710144,
    0.081469006836414337,
    0.081471368670463562,
    0.081472188234329224,
    0.0815897136926651,
    0.081742338836193085,
    0.081893160939216614,
    0.0820515900850296,
    0.082053817808628082,
    0.082054547965526581,
    0.082166105508804321,
    0.082299008965492249,
    0.0824427679181099,
    0.0825938880443573,
    0.082595981657505035,
    0.082596637308597565,
    0.082702375948429108,
    0.082828745245933533,
    0.082965590059757233,
    0.0831095427274704,
    0.083111502230167389,
    0.083112098276615143,
    0.083212152123451233,
    0.0833321064710617,
    0.083462230861186981,
    0.083599232137203217,
    0.083601057529449463,
    0.083601556718349457,
    0.083696067333221436,
    0.083809830248355865,
    0.083933375775814056,
    0.0840635597705841,
    0.0840652659535408,
    0.084065705537796021,
    0.08415483683347702,
    0.084262549877166748,
    0.084379717707633972,
    0.084503307938575745,
    0.084504902362823486,
    0.084505259990692139,
    0.084589175879955292,
    0.08469102531671524,
    0.084801994264125824,
    0.084919191896915436,
    0.084920652210712433,
    0.084920942783355713,
    0.084999792277812958,
    0.08509594202041626,
    0.085200920701026916,
    0.0853118821978569,
    0.085313238203525543,
    0.085313454270362854,
    0.085387416183948517,
    0.0854780450463295,
    0.085577197372913361,
    0.085682138800621033,
    0.085683383047580719,
    0.085683554410934448,
    0.085752762854099274,
    0.085838072001934052,
    0.085931606590747833,
    0.0860307365655899,
    0.086031876504421234,
    0.086031988263130188,
    0.0860966220498085,
    0.0861767902970314,
    0.08626488596200943,
    0.086358390748500824,
    0.0863594338297844,
    0.086359575390815735,
    0.08641979843378067,
    0.086495004594326019,
    0.086577855050563812,
    0.086665913462638855,
    0.086666852235794067,
    0.086666986346244812,
    0.0867229625582695,
    0.086793370544910431,
    0.086871139705181122,
    0.086953938007354736,
    0.086954794824123383,
    0.08695492148399353,
    0.087006792426109314,
    0.087072566151618958,
    0.087145455181598663,
    0.08722318708896637,
    0.08722396194934845,
    0.087224081158638,
    0.087272010743618011,
    0.0873333290219307,
    0.087401486933231354,
    0.087474338710308075,
    0.087475024163722992,
    0.087475135922431946,
    0.087519288063049316,
    0.087576285004615784,
    0.087639898061752319,
    0.087708070874214172,
    0.087708659470081329,
    0.087708771228790283,
    0.087749265134334564,
    0.087802134454250336,
    0.087861381471157074,
    0.08792499452829361,
    0.0879255086183548,
    0.087925627827644348,
    0.087962605059146881,
    0.0880114957690239,
    0.088066533207893372,
    0.088125772774219513,
    0.088126204907894135,
    0.088126316666603088,
    0.088159933686256409,
    0.0882050171494484,
    0.088255994021892548,
    0.088311024010181427,
    0.088311396539211273,
    0.088311508297920227,
    0.088341891765594482,
    0.088383287191390991,
    0.088430367410182953,
    0.0884813666343689,
    0.088481664657592773,
    0.088481754064559937,
    0.088509082794189453,
    0.08854694664478302,
    0.088590294122695923,
    0.088637381792068481,
    0.088637605309486389,
    0.088637709617614746,
    0.088662125170230865,
    0.08869662880897522,
    0.088736370205879211,
    0.088779732584953308,
    0.088779889047145844,
    0.0887799933552742,
    0.088801592588424683,
    0.08883281797170639,
    0.0888691172003746,
    0.088908873498439789,
    0.088908970355987549,
    0.0889090746641159,
    0.0889250636100769,
    0.088949739933013916,
    0.088978879153728485,
    0.089011117815971375,
    0.089045718312263489,
    0.089082218706607819,
    0.089120320975780487,
    0.08915979415178299,
    0.089200437068939209,
    0.089242137968540192,
    0.0892847403883934,
    0.08932814747095108,
    0.089372262358665466,
    0.089417017996311188,
    0.089462324976921082,
    0.089508138597011566,
    0.089554391801357269,
    0.089601039886474609,
    0.089648082852363586,
    0.089695394039154053,
    0.089742951095104218,
    0.089790746569633484,
    0.08983873575925827,
    0.089886896312236786,
    0.089886896312236786
  ],
  scaleHematit: [
    0.0,
    0.0,
    0.0,
    0.0,
    0.0,
    0.0,
    0.0,
    0.0,
    0.0,
    0.0,
    0.0085625117644667625,
    0.0075699016451835632,
    0.0094425808638334274,
    0.012123153544962406,
    0.014558058232069016,
    0.014587678015232086,
    0.014608573168516159,
    0.016717664897441864,
    0.019334528595209122,
    0.0,
    0.0036236410960555077,
    0.00665283715352416,
    0.0089371558278799057,
    0.0089648282155394554,
    0.0089842267334461212,
    0.011348114348948002,
    0.014189683832228184,
    0.0,
    0.0017119179246947169,
    0.0035674052778631449,
    0.0035808654502034187,
    0.0035894413013011217,
    0.0050218105316162109,
    0.0069107604213058949,
    0.0,
    0.001125887967646122,
    0.0024103797040879726,
    0.0024193325079977512,
    0.0024247851688414812,
    0.0034391507506370544,
    0.0048497999086976051,
    0.0,
    0.0006525539793074131,
    0.0015286913840100169,
    0.0015344711719080806,
    0.0015377914533019066,
    0.0022554374299943447,
    0.0033054607920348644,
    0.0,
    0.00051837315550073981,
    0.0012005915632471442,
    0.0012049636570736766,
    0.0012073689140379429,
    0.0017551728524267674,
    0.0025694288779050112,
    0.0,
    0.0002768773993011564,
    0.00072163256118074059,
    0.00072427390841767192,
    0.00072558567626401782,
    0.0010962182423099875,
    0.0016840683529153466,
    0.0,
    0.00020437297644093633,
    0.00055037322454154491,
    0.00055233342573046684,
    0.00055323151173070073,
    0.00094416941283270717,
    0.0015694015892222524,
    0.0,
    0.00052982039051130414,
    0.00090688595082610846,
    0.00090963306138291955,
    0.0009109238744713366,
    0.0014020566595718265,
    0.0021369007881730795,
    0.0,
    0.000982629950158298,
    0.0013869393151253462,
    0.0013903258368372917,
    0.0013919742777943611,
    0.0018714657053351402,
    0.0025702724233269691,
    0.0,
    0.00085542286979034543,
    0.0012136531295254827,
    0.0012166585074737668,
    0.0012180411722511053,
    0.0016388873336836696,
    0.0022629294544458389,
    0.0,
    0.00083998497575521469,
    0.0011825273977592588,
    0.0011854477925226092,
    0.0011867473367601633,
    0.0015772957121953368,
    0.0021567493677139282,
    0.0,
    0.0006694741896353662,
    0.00095083145424723625,
    0.00095316866645589471,
    0.00095410872017964721,
    0.0012733688345178962,
    0.0017608701018616557,
    0.0,
    0.00061546749202534556,
    0.00086832436500117183,
    0.00087041006190702319,
    0.00087118882220238447,
    0.0011511989869177342,
    0.0015854147495701909,
    0.0,
    0.00045565937762148678,
    0.00065428501693531871,
    0.00065586710115894675,
    0.00065632077166810632,
    0.0010092214215546846,
    0.0015480740694329143,
    0.0,
    0.00072951504262164235,
    0.0010230938205495477,
    0.0010251840576529503,
    0.0010257649701088667,
    0.0013889456167817116,
    0.001938688918016851,
    0.0,
    0.0009829782648012042,
    0.0012937880819663405,
    0.0012962014880031347,
    0.0012969169765710831,
    0.0016597593203186989,
    0.0021988279186189175,
    0.0,
    0.00088597298599779606,
    0.0011680342722684145,
    0.0011701807379722595,
    0.0011707430239766836,
    0.0014961777487769723,
    0.001986335963010788,
    0.0,
    0.00087615486700087786,
    0.0011483373818919063,
    0.0011504117865115404,
    0.0011509174946695566,
    0.0014565999154001474,
    0.0019179496448487043,
    0.0,
    0.00073012104257941246,
    0.00096143968403339386,
    0.00096310826484113932,
    0.00096340343588963151,
    0.0012505564372986555,
    0.0016894324216991663,
    0.0,
    0.00073838629759848118,
    0.00094160361913964152,
    0.00094304524827748537,
    0.00094324233941733837,
    0.001165923080407083,
    0.0015192624414339662,
    0.0,
    0.00060706981457769871,
    0.000795211992226541,
    0.00079644890502095222,
    0.00079654081491753459,
    0.00099989364389330149,
    0.001326839323155582,
    0.0,
    0.00047246611211448908,
    0.00062353274552151561,
    0.000624387408606708,
    0.000624387408606708,
    0.00078830483835190535,
    0.0010641675908118486,
    0.0,
    0.00043385644676163793,
    0.00056996248895302415,
    0.00057066790759563446,
    0.00057066790759563446,
    0.00071533286245539784,
    0.000964204897172749,
    0.0,
    0.00032571106567047536,
    0.00043215628829784691,
    0.00043255710625089705,
    0.00043255710625089705,
    0.0005616223206743598,
    0.00079053803347051144,
    0.0,
    0.00060664484044536948,
    0.00075002748053520918,
    0.00075087067671120167,
    0.00075087067671120167,
    0.00088577473070472479,
    0.0011103044962510467,
    0.0,
    0.00061799370450899,
    0.00077745300950482488,
    0.00077843014150857925,
    0.00077843014150857925,
    0.00092401501024141908,
    0.0011597549309954047,
    0.0,
    0.00058566487859934568,
    0.00073799031088128686,
    0.00073889613850042224,
    0.00073889613850042224,
    0.00087692000670358539,
    0.001102381618693471,
    0.0,
    0.00060052511980757117,
    0.000753899454139173,
    0.00075481930980458856,
    0.00075481930980458856,
    0.00089093716815114021,
    0.0011127280304208398,
    0.0,
    0.00054376025218516588,
    0.00068414362613111734,
    0.00068491656566038728,
    0.00068491656566038728,
    0.00082273036241531372,
    0.0010472325375303626,
    0.0,
    0.00057593605015426874,
    0.0007069827988743782,
    0.0007076954934746027,
    0.0007076954934746027,
    0.00082056736573576927,
    0.0010110356379300356,
    0.0,
    0.0005079827387817204,
    0.00063609599601477385,
    0.00063673488330096006,
    0.00063673488330096006,
    0.000745291355997324,
    0.00092980498448014259,
    0.0,
    0.000440196949057281,
    0.00055260484805330634,
    0.00055306858848780394,
    0.00055306858848780394,
    0.00064813316566869617,
    0.00081448687706142664,
    0.0,
    0.0004274057864677161,
    0.00053460145136341453,
    0.0005350076244212687,
    0.0005350076244212687,
    0.00062379625160247087,
    0.00078101683175191283,
    0.0,
    0.00036549038486555219,
    0.00045820546802133322,
    0.00045844749547541142,
    0.00045844749547541142,
    0.000544185983017087,
    0.00069803866790607572,
    0.0,
    0.0003766255103982985,
    0.00046012821258045733,
    0.00046030530938878655,
    0.00046030530938878655,
    0.00052668567514047027,
    0.00065268325852230191,
    0.0,
    0.00031884928466752172,
    0.000397542113205418,
    0.00039763658423908055,
    0.00039763658423908055,
    0.00045901999692432582,
    0.0005783130181953311,
    0.0,
    0.00026454345788806677,
    0.000330184557242319,
    0.000330184557242319,
    0.000330184557242319,
    0.00038014972233213484,
    0.00048408002476207912,
    0.00048408002476207912,
    0.00059940881328657269,
    0.00064788945019245148,
    0.00064788945019245148,
    0.00064788945019245148,
    0.000686782761476934,
    0.00077306153252720833,
    0.00077306153252720833,
    0.00086677196668460965,
    0.00090649258345365524,
    0.00090649258345365524,
    0.00090649258345365524,
    0.00095074001001194119,
    0.0010479107731953263,
    0.0,
    0.00025303548318333924,
    0.00030802618130110204,
    0.00030802618130110204,
    0.00030802618130110204,
    0.00035161641426384449,
    0.00044776624417863786,
    0.0,
    0.00025664738495834172,
    0.00031183057581074536,
    0.00031183057581074536,
    0.00031183057581074536,
    0.00035428765113465488,
    0.00044801901094615459,
    0.0,
    0.00022198993247002363,
    0.00026952344342134893,
    0.00026952344342134893,
    0.00026952344342134893,
    0.00030483459704555571,
    0.00038872400182299316,
    0.00038872400182299316,
    0.00048911396879702806,
    0.00052529585082083941,
    0.00052529585082083941,
    0.00052529585082083941,
    0.00055358948884531856,
    0.00062475330196321011,
    0.0,
    0.00018044719763565809,
    0.00021775922505185008,
    0.00021775922505185008,
    0.00021775922505185008,
    0.00024945885525085032,
    0.0003294751513749361,
    0.0,
    0.00019791212980635464,
    0.00023171173234004527,
    0.00023171173234004527,
    0.00023171173234004527,
    0.00025267380988225341,
    0.00031454933923669159,
    0.00031454933923669159,
    0.00038332675467245281,
    0.000406777166062966,
    0.000406777166062966,
    0.000406777166062966,
    0.00042189896339550614,
    0.00047311244998127222,
    0.00047311244998127222,
    0.00052581721683964133,
    0.00054318283218890429,
    0.00054318283218890429,
    0.00054318283218890429,
    0.00055244815303012729,
    0.00059533462626859546,
    0.00059533462626859546,
    0.00064239086350426078,
    0.00065727037144824862,
    0.00065727037144824862,
    0.00065727037144824862,
    0.00066392205189913511,
    0.00070226372918114066,
    0.00070226372918114066,
    0.00073559564771130681,
    0.00074523623334243894,
    0.00074523623334243894,
    0.00074523623334243894,
    0.00074967596447095275,
    0.00078702130122110248,
    0.00078702130122110248,
    0.00084197876276448369,
    0.00086417363490909338,
    0.00086417363490909338,
    0.00086417363490909338,
    0.00087107729632407427,
    0.00090558850206434727,
    0.00090558850206434727,
    0.00095359061378985643,
    0.00097664643544703722,
    0.00097664643544703722,
    0.00097664643544703722,
    0.00098423147574067116,
    0.0010196699295192957,
    0.0010196699295192957,
    0.0010625611757859588,
    0.0010833073174580932,
    0.0010833073174580932,
    0.0010833073174580932,
    0.0010887822136282921,
    0.0011213243706151843,
    0.0011213243706151843,
    0.0011641313321888447,
    0.0011845038970932365,
    0.0011845038970932365,
    0.0011845038970932365,
    0.0011895589996129274,
    0.0012209230335429311,
    0.0012209230335429311,
    0.0012567464727908373,
    0.0012739724479615688,
    0.0012739724479615688,
    0.0012739724479615688,
    0.0012788872700184584,
    0.0013118784409016371,
    0.0013118784409016371,
    0.0013562883250415325,
    0.0013733322266489267,
    0.0013733322266489267,
    0.0013733322266489267,
    0.0013733322266489267,
    0.0013989335857331753,
    0.0013989335857331753,
    0.0014303532661870122,
    0.0014450198505073786,
    0.0014450198505073786,
    0.0014450198505073786,
    0.0014450198505073786,
    0.0014685684582218528,
    0.0014685684582218528,
    0.0014916949439793825,
    0.0015026497421786189,
    0.0015026497421786189,
    0.0015026497421786189,
    0.0015026497421786189,
    0.0015220050700008869,
    0.0015220050700008869,
    0.0015429331688210368,
    0.0015525736380368471,
    0.0015525736380368471,
    0.0015525736380368471,
    0.0015525736380368471,
    0.001569888205267489,
    0.001569888205267489,
    0.0015834016958251596,
    0.0015896973200142384,
    0.0015896973200142384,
    0.0015896973200142384,
    0.0015896973200142384,
    0.0016198804369196296,
    0.0016710034105926752,
    0.0017379437340423465,
    0.0017379437340423465,
    0.0017379437340423465,
    0.0017603383166715503,
    0.0018069311045110226,
    0.0018698462517932057,
    0.0019454510184004903,
    0.0019455471774563193,
    0.0019455471774563193,
    0.0019764641765505075,
    0.0020300396718084812,
    0.0020984399598091841,
    0.0021783425472676754,
    0.002178574912250042,
    0.002178574912250042,
    0.0022143132518976927,
    0.002271789126098156,
    0.0023431859444826841,
    0.0024253386072814465,
    0.0024256680626422167,
    0.0024256680626422167,
    0.0024645051453262568,
    0.0025243747513741255,
    0.002597480546683073,
    0.00268075754866004,
    0.0026811594143509865,
    0.0026811594143509865,
    0.002724725054576993,
    0.0027896275278180838,
    0.0028677957598119974,
    0.0029561291448771954,
    0.0029566008597612381,
    0.0029566008597612381,
    0.0029994705691933632,
    0.0030621937476098537,
    0.0031370464712381363,
    0.0032211344223469496,
    0.0032216478139162064,
    0.0032216478139162064,
    0.003265557112172246,
    0.003328636521473527,
    0.0034033698029816151,
    0.0034869529772549868,
    0.0034875026904046535,
    0.0034875026904046535,
    0.0035321349278092384,
    0.00359531887806952,
    0.0036697131581604481,
    0.0037526062224060297,
    0.0037531862035393715,
    0.0037532146088778973,
    0.0037983441725373268,
    0.0038614389486610889,
    0.00393532682210207,
    0.00401737866923213,
    0.0040179830975830555,
    0.0040180343203246593,
    0.0040616365149617195,
    0.0041221505962312222,
    0.0041927318088710308,
    0.0042708911933004856,
    0.0042715072631835938,
    0.00427157711237669,
    0.0043151187710464,
    0.0043750163167715073,
    0.00444463174790144,
    0.0045215613208711147,
    0.0045221904292702675,
    0.0045222756452858448,
    0.0045657078735530376,
    0.0046249795705080032,
    0.0046936329454183578,
    0.0047693368978798389,
    0.0047699771821498871,
    0.0047700763680040836,
    0.00481333676725626,
    0.0048719528131186962,
    0.0049396278336644173,
    0.0050141019746661186,
    0.0050147511065006256,
    0.0050148628652095795,
    0.0050578946247696877,
    0.0051158145070075989,
    0.0051824953407049179,
    0.0052557415328919888,
    0.0052563976496458054,
    0.0052565210498869419,
    0.0052992785349488258,
    0.005356478039175272,
    0.0054221511818468571,
    0.0054941680282354355,
    0.0054948292672634125,
    0.00549496291205287,
    0.0055374065414071083,
    0.00559385959059,
    0.0056585078127682209,
    0.0057292822748422623,
    0.0057299481704831123,
    0.0057300911284983158,
    0.0057721743360161781,
    0.0058278562501072884,
    0.0058914660476148129,
    0.0059609962627291679,
    0.0059616640210151672,
    0.0059618158265948296,
    0.006003502756357193,
    0.00605838792398572,
    0.0061209551058709621,
    0.00618924992159009,
    0.0061899200081825256,
    0.0061900797300040722,
    0.0062313461676239967,
    0.006285427138209343,
    0.00634694704785943,
    0.0064140092581510544,
    0.0064146798104047775,
    0.0064148465171456337,
    0.0064556729048490524,
    0.0065089412964880466,
    0.0065694167278707027,
    0.0066352393478155136,
    0.0066359103657305241,
    0.0066360821947455406,
    0.0066763339564204216,
    0.00672870920971036,
    0.0067880847491323948,
    0.0068526435643434525,
    0.0068533122539520264,
    0.0068534896709024906,
    0.0068932357244193554,
    0.0069447550922632217,
    0.0070030600763857365,
    0.00706638814881444,
    0.0070670549757778645,
    0.0070672375150024891,
    0.00710647227242589,
    0.0071571441367268562,
    0.0072143962606787682,
    0.0072765164077281952,
    0.0072771799750626087,
    0.0072773671709001064,
    0.0073160845786333084,
    0.0073659168556332588,
    0.0074221310205757618,
    0.0074830641970038414,
    0.0074837240390479565,
    0.00748391542583704,
    0.0075221140868961811,
    0.0075711165554821491,
    0.0076263095252215862,
    0.00768607622012496,
    0.0076867328025400639,
    0.0076869283802807331,
    0.0077246031723916531,
    0.0077727818861603737,
    0.0078269708901643753,
    0.007885592058300972,
    0.0078862449154257774,
    0.00788644328713417,
    0.0079235965386033058,
    0.0079709636047482491,
    0.0080241663381457329,
    0.0080816671252250671,
    0.008082315325737,
    0.0080825174227356911,
    0.0081191500648856163,
    0.0081657106056809425,
    0.0082179317250847816,
    0.00827431958168745,
    0.0082749640569090843,
    0.0082751680165529251,
    0.0083112651482224464,
    0.0083570117130875587,
    0.0084082493558526039,
    0.0084635224193334579,
    0.00846416037529707,
    0.0084643671289086342,
    0.00849991850554943,
    0.0085448464378714561,
    0.0085950959473848343,
    0.0086492523550987244,
    0.0086498847231268883,
    0.0086500933393836021,
    0.00868507381528616,
    0.008729156106710434,
    0.00877839233726263,
    0.00883140042424202,
    0.0088320253416895866,
    0.0088322348892688751,
    0.0088666146621108055,
    0.0089098243042826653,
    0.0089580146595835686,
    0.00900984462350607,
    0.0090104630216956139,
    0.009010673500597477,
    0.00904441811144352,
    0.0090867169201374054,
    0.0091338269412517548,
    0.0091844480484724045,
    0.0091850562021136284,
    0.0091852657496929169,
    0.0092183388769626617,
    0.0092596933245658875,
    0.0093056913465261459,
    0.0093550672754645348,
    0.0093556661158800125,
    0.009355875663459301,
    0.0093882465735077858,
    0.00942862220108509,
    0.0094734728336334229,
    0.0095215747132897377,
    0.0095221633091568947,
    0.0095223709940910339,
    0.00955399964004755,
    0.0095933601260185242,
    0.00963702891021967,
    0.0096838120371103287,
    0.0096843885257840157,
    0.00968459527939558,
    0.0097154434770345688,
    0.0097537469118833542,
    0.00979618914425373,
    0.0098416153341531754,
    0.0098421797156333923,
    0.0098423836752772331,
    0.00987241417169571,
    0.0099096223711967468,
    0.0099508017301559448,
    0.0099948374554514885,
    0.009995386004447937,
    0.00999558437615633,
    0.010024694725871086,
    0.010060722939670086,
    0.010100565850734711,
    0.010143142193555832,
    0.010143669322133064,
    0.010143858380615711,
    0.010171926580369473,
    0.010206672362983227,
    0.010245083831250668,
    0.010286119766533375,
    0.010286625474691391,
    0.010286804288625717,
    0.010313780046999455,
    0.010347189381718636,
    0.010384120047092438,
    0.010423560626804829,
    0.010424043983221054,
    0.010424213483929634,
    0.010450060479342938,
    0.010482093319296837,
    0.01051750686019659,
    0.010555321350693703,
    0.010555783286690712,
    0.010555941611528397,
    0.010580642148852348,
    0.010611284524202347,
    0.010645164176821709,
    0.010681338608264923,
    0.010681776329874992,
    0.010681924410164356,
    0.010705470107495785,
    0.010734709911048412,
    0.010767046362161636,
    0.01080157607793808,
    0.010801990516483784,
    0.010802128352224827,
    0.010824515484273434,
    0.01085235457867384,
    0.010883151553571224,
    0.01091604121029377,
    0.010916432365775108,
    0.010916559956967831,
    0.010937797836959362,
    0.010964249260723591,
    0.010993529111146927,
    0.011024801060557365,
    0.011025127954781056,
    0.011025245301425457,
    0.011045349761843681,
    0.0110704330727458,
    0.011098210699856281,
    0.011127887293696404,
    0.011128233745694161,
    0.011128340847790241,
    0.011147331446409226,
    0.011171068996191025,
    0.01119737233966589,
    0.011225483380258083,
    0.011225808411836624,
    0.011225907132029533,
    0.011243805289268494,
    0.011266225948929787,
    0.011290457099676132,
    0.011317036114633083,
    0.011317339725792408,
    0.011317428201436997,
    0.011334263719618321,
    0.011355401948094368,
    0.011378861963748932,
    0.011403955519199371,
    0.011404238641262054,
    0.011404317803680897,
    0.011420122347772121,
    0.011440015397965908,
    0.011462116613984108,
    0.011485770344734192,
    0.011486032977700234,
    0.011486103758215904,
    0.011500912718474865,
    0.011521445587277412,
    0.011542235501110554,
    0.011564499698579311,
    0.011564743705093861,
    0.011564805172383785,
    0.011578654870390892,
    0.011596190743148327,
    0.011615718714892864,
    0.011636643670499325,
    0.011636869050562382,
    0.011636923998594284,
    0.011649852618575096,
    0.011666275560855866,
    0.01168458629399538,
    0.011704221367835999,
    0.011704429052770138,
    0.011704476550221443,
    0.011716521345078945,
    0.011731875129044056,
    0.011749017983675003,
    0.011767415329813957,
    0.011767606250941753,
    0.011767646297812462,
    0.011778844520449638,
    0.011793175712227821,
    0.011809199117124081,
    0.011826411820948124,
    0.011826585978269577,
    0.011826618574559689,
    0.011837009340524674,
    0.011850361712276936,
    0.011865316890180111,
    0.011881396174430847,
    0.011881556361913681,
    0.01188158243894577,
    0.011891203001141548,
    0.01190362311899662,
    0.011917558498680592,
    0.011932558380067349,
    0.011932704597711563,
    0.011932725086808205,
    0.011941613629460335,
    0.011953145265579224,
    0.011966110207140446,
    0.011980080045759678,
    0.011980211362242699,
    0.011980227194726467,
    0.011988420970737934,
    0.011999108828604221,
    0.012011150829494,
    0.01202414371073246,
    0.012024262920022011,
    0.012024273164570332,
    0.012031808495521545,
    0.012041697278618813,
    0.012052864767611027,
    0.01206493191421032,
    0.012065038084983826,
    0.012065043672919273,
    0.012071957811713219,
    0.012081091292202473,
    0.012091429904103279,
    0.01210261881351471,
    0.01210271380841732,
    0.01210271380841732,
    0.012109041213989258,
    0.012117459438741207,
    0.012127014808356762,
    0.012137370184063911,
    0.012137454934418201,
    0.012137454934418201,
    0.012143228203058243,
    0.012150969356298447,
    0.012159782461822033,
    0.012169349007308483,
    0.012169423513114452,
    0.012169423513114452,
    0.012174675241112709,
    0.012181776575744152,
    0.012189888395369053,
    0.012198712676763535,
    0.012198777869343758,
    0.012198777869343758,
    0.012203538790345192,
    0.012210038490593433,
    0.012217488139867783,
    0.012225610204041004,
    0.012225667014718056,
    0.012225667014718056,
    0.012229966931045055,
    0.012235899455845356,
    0.012242726050317287,
    0.012250186875462532,
    0.012250235304236412,
    0.012250235304236412,
    0.012254102155566216,
    0.012259501032531261,
    0.01226574182510376,
    0.012272578664124012,
    0.012272619642317295,
    0.012272619642317295,
    0.012276080437004566,
    0.012280979193747044,
    0.012286667712032795,
    0.01229291595518589,
    0.012292949482798576,
    0.012292949482798576,
    0.012296031229197979,
    0.012300459668040276,
    0.012305629439651966,
    0.012311327271163464,
    0.012311354279518127,
    0.012311354279518127,
    0.012314080260694027,
    0.012318067252635956,
    0.012322749942541122,
    0.012327928096055985,
    0.012327948585152626,
    0.012327948585152626,
    0.012330345809459686,
    0.012333919294178486,
    0.012338144704699516,
    0.012342835776507855,
    0.012342850677669048,
    0.012342850677669048,
    0.012344946153461933,
    0.012348134070634842,
    0.012351932004094124,
    0.012356165796518326,
    0.012356176041066647,
    0.012356176041066647,
    0.0123579828068614,
    0.012360808439552784,
    0.012364205904304981,
    0.012368010357022286,
    0.012368015944957733,
    0.012368015944957733,
    0.012369301170110703,
    0.012371464632451534,
    0.012374116107821465,
    0.012377116829156876,
    0.012380391359329224,
    0.012383892200887203,
    0.012387584894895554,
    0.012391445226967335,
    0.012395450845360756,
    0.012399584986269474,
    0.012403834611177445,
    0.012408185750246048,
    0.012412627227604389,
    0.012417150661349297,
    0.012421747669577599,
    0.012426408939063549,
    0.012431127950549126,
    0.012435900047421455,
    0.012440713122487068,
    0.012445571832358837,
    0.012450465932488442,
    0.012455391697585583,
    0.012460343539714813,
    0.012465317733585835,
    0.012465317733585835
  ],
  straighteningArea: 19,
  coolZones: [
    [1.09, 1600],
    [3.545, 1600],
    [6.372, 1600],
    [10.662, 1600],
    [13.877, 1600],
    [17, 1600],
  ],
  segmentLine: [
    [0.925, 1600],
    [3.545, 1600],
    [4.942, 1600],
    [6.372, 1600],
    [7.8020000000000005, 1600],
    [9.232, 1600],
    [10.662, 1600],
    [12.259, 1600],
    [13.877, 1600],
    [15.495000000000001, 1600],
    [17.113, 1600],
    [18.9428, 1600],
    [20.782799999999998, 1600],
    [22.5578, 1600],
    [24.3328, 1600],
    [26.1078, 1600],
    [27.8828, 1600],
    [29.657799999999998, 1600],
    [31.4328, 1600],
    [33.207800000000006, 1600],
    [33.5628, 1600],
    [34.982800000000005, 1600],
    [36.7578, 1600],
    [38.5328, 1600],
    [40.3078, 1600],
    [42.082800000000006, 1600],
  ],
  segments: [
    0.925,
    3.545,
    4.942,
    6.372,
    7.8020000000000005,
    9.232,
    10.662,
    12.259,
    13.877,
    15.495000000000001,
    17.113,
    18.9428,
    20.782799999999998,
    22.5578,
    24.3328,
    26.1078,
    27.8828,
    29.657799999999998,
    31.4328,
    33.207800000000006,
    34.982800000000005,
    36.7578,
    38.5328,
    40.3078,
    42.082800000000006
  ],
  solidification: 26,
  warning: 43.1,
  warm: 43.6,
  endOfStrandGuide: 43.9,
  coolZone: [
    {
      label: 'Narrowside',
      value: 0.5
    },
    {  
      label: 'Ring',
      value: 1
    },
    {
      label: 'S1',
      value: 3
    },
    {
      label: 'S2-3',
      value: 5
    },
    {
      label: 'S4-6',
      value: 9
    },
    {
      label: 'S7-8',
      value: 13
    },
    {
      label: 'S9-10',
      value: 16
    },
  ]
}; 
 

export const scaleCompositionChartReducer = (
  state = initState, action: ScaleCompositionAction
): ScaleCompositionState => {

  switch (action.type) {
  case ScaleCompositionActionTypes.SET_SCALE_FAYALIT_DATA:
    return {...state, scaleFayalit: action.payload};
  case ScaleCompositionActionTypes.SET_SCALE_WUSTIT_DATA:
    return {...state, scaleWustit: action.payload};
  case ScaleCompositionActionTypes.SET_SCALE_MAGNETIT_DATA:
    return {...state, scaleMagnetit: action.payload};
  case ScaleCompositionActionTypes.SET_SCALE_HEMATIT_DATA:
    return {...state, scaleHematit: action.payload};
  default:
    return state;
  }
  
};