import React from 'react';
import { DefaultTabs } from '../common/DefaultTabs/DefaultTabs';
import { DefaultTabPanel } from '../common/DefaultTabs/DefaultTabPanel/DefaultTabPanel';
import { DialogueTranslation } from './DialogueTranslation/DialogueTranslation'; 
import { UnitConversation } from './UnitConversation/UnitConversation';
import { useTranslation } from 'react-i18next';

export const Configuration: React.FC = (): JSX.Element => {
 
  const { t } = useTranslation(); 

  enum ConfigurationTabsID {
    DIALOGUE_TRANSLATION = 'Dialogue Translation',
    UNIT_CONVERSATION = 'Unit Conversation', 
  }
 
  const tabs = [
    {id: ConfigurationTabsID.DIALOGUE_TRANSLATION, label: t('DIALOGUE_TRANSLATION')},
    {id: ConfigurationTabsID.UNIT_CONVERSATION, label: t('UNIT_CONVERSATION')},
  ];

  return (       
    <DefaultTabs
      tabs={tabs}  
      defaultActive={ConfigurationTabsID.DIALOGUE_TRANSLATION}
      renderContent={(active: number | string) => (
        <>
          <DefaultTabPanel tabID={ConfigurationTabsID.DIALOGUE_TRANSLATION} active={active}>
            <DialogueTranslation />
          </DefaultTabPanel> 
          <DefaultTabPanel tabID={ConfigurationTabsID.UNIT_CONVERSATION} active={active}>
            <UnitConversation />
          </DefaultTabPanel> 
        </>
      )}  
    /> 
  ); 
}; 

