import React, { useEffect, useMemo } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { defaultOptions } from './defaultOptions';
import { defaultOptions as defaultOptionsSolidification } from '../SolidificationArrowChart/defaultOptions';
import { 
  getShell,
  getShellMaxLength
} from '../../../store/selectors/charts/shell/shellSelector';
import zip from 'lodash/zip';

import segementMarker from '../../../assets/images/segement_symbol_pink.png';
import coolMarker from '../../../assets/images/cool_symbol.png';
import { generateConstraintLines } from '../../../utils/charts/generateConstraintLines';
import { useDispatch, useSelector } from 'react-redux';
import { SolidificationArrowChart } from '../SolidificationArrowChart/SolidificationArrowChart';
import {
  getSolidificationArrowChartLine1,
  getSolidificationArrowChartLine2,
  getSolidificationArrowChartLine3,
  getSolidificationArrowChartLine4,
  getSolidificationArrowChartLine5
} from '../../../store/selectors/charts/solidificationArrowChart/solidificationArrowChartSelector';
import NavigationTwoToneIcon from '../../../assets/images/arrow_solid_mini_chart.svg';
import { fetchShellDataRequest } from '../../../store/actions/charts/shell/shellAction';
import { Spinner } from '../../common/Spinner/Spinner';
import { makeStyles } from '@material-ui/core';
import { getCommonLines } from '../../../store/selectors/charts/commonLines/commonLinesSelector';
import { getHeaderTopMenuBtnStatus } from '../../../store/selectors/header/headerSelector';
import { getStatusRightPanel } from '../../../store/selectors/rightPanel/rightPanel';
import { getStatusLeftPanel } from '../../../store/selectors/leftPanel/leftPanelSelector';
import { isFullHD } from '../../../utils/helper/detectScreen';

const useStyles = makeStyles({
  spinnerContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
  }
});

export const ShellChart = (): JSX.Element => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const shell = useSelector(getShell);
  const shellMaxLength = useSelector(getShellMaxLength);
  const line1MiniChartData = useSelector(getSolidificationArrowChartLine1);
  const line2MiniChartData = useSelector(getSolidificationArrowChartLine2);
  const line3MiniChartData = useSelector(getSolidificationArrowChartLine3);
  const line4MiniChartData = useSelector(getSolidificationArrowChartLine4);
  const line5MiniChartData = useSelector(getSolidificationArrowChartLine5);
  const commonLines = useSelector(getCommonLines);

  const topMenuIsHide = useSelector(getHeaderTopMenuBtnStatus);
  const rightMenuIsHide = useSelector(getStatusRightPanel);
  const leftMenuIsHide = useSelector(getStatusLeftPanel);
  

  useEffect(() => {
    if (!shell.data) {
      dispatch(fetchShellDataRequest());
    }
    
  }, [dispatch, shell.data]);


  const segmentSeries = useMemo(() => ({
    type: 'column',
    name: 'Segment',
    color: '#edc928',
    data: commonLines?.segmentPasslineCoor && commonLines?.segmentPasslineCoor.map(item => [item, 125]),
  }), [commonLines?.segmentPasslineCoor]);

  const coolzoneSeries = useMemo(() => ({
    type: 'column',
    name: 'Coolzone',
    color: '#2d2efd',
    data: commonLines?.coolzonePasslineCoor && commonLines?.coolzonePasslineCoor.map(item => [item, 125]),
  }), [commonLines?.coolzonePasslineCoor]);

  const thicknessBottomSeries = useMemo(() => ({
    type: 'line',
    name: 'ThicknessBottom',
    color: '#000',
    data: zip(shell.data?.Length, shell.data?.ThicknessBottom),
  }), [shell.data?.Length, shell.data?.ThicknessBottom]);

  const thicknessTopSeries = useMemo(() => ({
    type: 'line',
    name: 'ThicknessTop',
    color: '#a32545',
    data: zip(shell.data?.Length, shell.data?.ThicknessTop),
  }), [shell.data?.Length, shell.data?.ThicknessTop]);

  const narrowThicknessSeries = useMemo(() => ({
    type: 'line',
    name: 'ThicknessNarrow',
    color: '#65f3f2',
    data: zip(shell.data?.Length, shell.data?.CenterNarrowShellThickness),
  }), [shell.data?.Length, shell.data?.CenterNarrowShellThickness]);

  const segmentPointsSeries = useMemo(() => ({
    type: 'line',
    name: 'SegmentPoints',
    lineWidth: 0,
    marker: {
      enabled: true,
      symbol: `url(${segementMarker})`,
    },
    dataLabels: {
      enabled: true,
      allowOverlap: true,
      align: 'left',
      verticalAlign: 'middle',
      backgroundColor: 'transparent',
      style: {
        fontSize: '10px',
      },
      formatter: function(): string {
        const that = this as any;
        return `Seg. ${that.point.index + 1}`;
      }
    },
    states: {
      hover: {
        enabled: false,
      }
    },
    data: commonLines?.segmentPasslineCoor?.map(item => [item, 2]),
  }), [commonLines?.segmentPasslineCoor]);

  const coolzonesPointsSeries = useMemo(() => ({
    type: 'line',
    name: 'CoolzonePoints',
    dataLabels: {
      enabled: true,
    },
    lineWidth: 0,
    marker: {
      enabled: true,
      symbol: `url(${coolMarker})`,
    },
    states: {
      hover: {
        enabled: false,
      }
    },
    data: commonLines?.coolzonePasslineCoor?.map((item, index) => ({
      x: item,
      y: 5,
      name: `S${index + 1}`,
      dataLabels: {
        enabled: true,
        format: `S${index + 1}-${index + 2}`,
        style: {
          fontSize: '10px',
          fontWeight: 400,
        } 
      },
    }))
  }), [commonLines?.coolzonePasslineCoor]);

  const solidificationLineSeries = generateConstraintLines(commonLines?.solidificationLength, 0, 125, 'Solidification', '#BA0392', 'left');
  const warningLineSeries = generateConstraintLines(commonLines?.solidificationWarning, 0, 125, 'Warning', '#BA0392');
  const warmLineSeries = generateConstraintLines(commonLines?.solidificationAlarm, 0, 125, 'Warm', '#BA0392');
  const endOfStrandLineSeries = generateConstraintLines(commonLines?.endOfStrendGuide, 0, 125, 'End Of Strand Guide', '#000000');

  const shellOptions = useMemo(() => ({
    ...defaultOptions,
    xAxis: defaultOptions.xAxis.map(item => ({
      ...item,
      max: shellMaxLength && Math.round(shellMaxLength),
    })),
    series: [
      solidificationLineSeries, 
      warningLineSeries,
      warmLineSeries,
      endOfStrandLineSeries,
      segmentSeries,
      coolzoneSeries,
      thicknessBottomSeries,
      thicknessTopSeries,
      narrowThicknessSeries,
      coolzonesPointsSeries,
      segmentPointsSeries,
    ]
  }), [
    solidificationLineSeries,
    warningLineSeries,
    warmLineSeries,
    endOfStrandLineSeries,
    segmentSeries,
    coolzoneSeries,
    thicknessBottomSeries,
    thicknessTopSeries,
    narrowThicknessSeries,
    coolzonesPointsSeries,
    segmentPointsSeries,
    shellMaxLength,
  ]);

  const line1 = useMemo(() => ({
    name: 'line1',
    type: 'spline',
    color: '#fffd8f',
    lineWidth: 30,
    data: line1MiniChartData.map(data => [data, 75])
  }), [line1MiniChartData]);

  const line2 = useMemo(() => ({
    name: 'line2',
    type: 'spline',
    color: '#ebde7f',
    lineWidth: 30,
    data: line2MiniChartData.map(data => [data, 75])
  }), [line2MiniChartData]);

  const line3 = useMemo(() => ({
    name: 'line3',
    type: 'spline',
    color: '#94602f',
    lineWidth: 30,
    zIndex: 2,
    data: line3MiniChartData.map(data => ({
      x: data,
      y: 75,
      marker: {
        enabled: true,
        symbol: `url(${NavigationTwoToneIcon})`,
      }
    }))
  }), [line3MiniChartData]);

  const line4 = useMemo(() => ({
    name: 'line4',
    type: 'spline',
    color: '#ebde7f',
    zIndex: 1,
    lineWidth: 30,
    data: line4MiniChartData.map(data => [data, 75])
      
  }), [line4MiniChartData]);

  const line5 = useMemo(() => ({
    name: 'line5',
    type: 'spline',
    color: '#43a894',
    lineWidth: 30,
    data: line5MiniChartData.map(data => [data, 35])
  }), [line5MiniChartData]);

  const solidificationCoolzones = useMemo(() => ({
    name: 'coolZone',
    type: 'column',
    color: '#2f6fed', 
    maxPointWidth: 1, 
    zIndex: -98,
    data: commonLines?.coolzonePasslineCoor?.map(item => [item, 125]),
  }), [commonLines?.coolzonePasslineCoor]);

  const solidificationSegments = useMemo(() => ({
    name: 'Segment Line',
    type: 'column',
    color: '#edc928',
    maxPointWidth: 1, 
    zIndex: -99,
    data: commonLines?.segmentPasslineCoor?.map(item => [item, 125]),
  }), [commonLines?.segmentPasslineCoor]);


  const solidificationArrowOptions = useMemo(() => ({
    title: {
      ...defaultOptionsSolidification.title,
      text: 'Strand Shell Thickness'
    },
    yAxis: defaultOptionsSolidification.yAxis.map(item => ({
      ...item,
      min: 0,
      max: 125,
      tickInterval: 25,
    })),
    series: [
      solidificationCoolzones,
      solidificationSegments,
      line1,
      line2,
      line3,
      line4,
      line5,
    ]
  }), [
    solidificationCoolzones,
    solidificationSegments,
    line1,
    line2,
    line3,
    line4,
    line5,
  ]);

  if (!shell.data) {
    return <div className={classes.spinnerContainer}><Spinner /></div>;
  }

  const styles = topMenuIsHide ? { height: 'calc( 100vh - 260px )', minHeight: '500px'} : { height: 'calc( 100vh - 155px - 260px )', minHeight: '500px'};

  const chartKey = `${topMenuIsHide}${rightMenuIsHide}${leftMenuIsHide}`;

  return (
    <>
      <SolidificationArrowChart key={`${chartKey}_top`} otherOptions={solidificationArrowOptions} />
      <HighchartsReact
        key={chartKey}
        containerProps={{ style: styles }} 
        highcharts={Highcharts}  
        options={shellOptions}
      />
    </> 
  );
};
