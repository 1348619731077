import { TempDistributionAction, TempDistributionActionsTypes, DataTempDistributionState } from '../../../types/charts/tempDistribution'; 

const initState: DataTempDistributionState = {
  lengthX: [
    0.0,
    5.2083358764648438,
    10.416664123535156,
    15.625,
    20.833328247070312,
    26.041664123535156,
    31.25,
    36.458328247070312,
    41.666664123535156,
    46.875,
    52.083328247070312,
    57.291664123535156,
    62.5,
    67.708335876464844,
    72.916664123535156,
    78.125,
    83.333335876464844,
    88.541664123535156,
    93.75,
    98.958335876464844,
    104.16666412353516,
    109.37500762939453,
    114.58333587646484,
    119.79166412353516,
    125.0,
    130.20834350585938,
    135.41665649414063,
    140.625,
    145.83334350585938,
    151.04165649414063,
    156.25,
    161.45834350585938,
    166.66665649414063,
    171.875,
    177.08334350585938,
    182.29165649414063,
    187.5,
    192.70834350585938,
    197.91665649414063,
    203.125,
    208.33334350585938,
    213.54165649414063,
    218.75,
    223.95834350585938,
    229.16665649414063,
    234.375,
    239.58334350585938,
    244.79165649414063,
    250.0
  ],
  lengthXForSolid: [
    0.0,
    0.0,
    0.0,
    0.0,
    0.0,
    0.0,
    0.0,
    5.2083358764648438,
    10.416664123535156,
    15.625,
    20.833328247070312,
    26.041664123535156,
    31.25,
    36.458328247070312,
    41.666664123535156,
    46.875,
    52.083328247070312,
    57.291664123535156,
    62.5,
    67.708335876464844,
    72.916664123535156,
    78.125,
    83.333335876464844,
    88.541664123535156,
    93.75,
    98.958335876464844,
    104.16666412353516,
    109.37500762939453,
    114.58333587646484,
    119.79166412353516,
    125.0,
    130.20834350585938,
    135.41665649414063,
    140.625,
    145.83334350585938,
    151.04165649414063,
    156.25,
    161.45834350585938,
    166.66665649414063,
    171.875,
    177.08334350585938,
    182.29165649414063,
    187.5,
    192.70834350585938,
    197.91665649414063,
    203.125,
    208.33334350585938,
    213.54165649414063,
    218.75,
    223.95834350585938,
    229.16665649414063,
    234.375,
    239.58334350585938,
    244.79165649414063,
    250.0,
    250.0,
    250.0,
    250.0,
    250.0,
    250.0
  ],
  scannerAct2: 932.0,
  scannerAct2_v1: 932.0,
  scannerAct2_v2: 932.0, 
  scannerAct2_v3: 932.0,
  temperatureSetpoint: 932.0,
  scannerMax: 932.0,
  data: null
}; 


export const tempDistributionReducer = (state = initState, action: TempDistributionAction): DataTempDistributionState => {

  switch (action.type) {
  case TempDistributionActionsTypes.SET_TEMP_DISTRIBUTION_DATA:
    return {...state,
      data: action.payload
    }; 
  default:
    return state;
  }
};