import React from 'react';
import { 
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles } 
  from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { closeModalAction } from '../../../store/actions/modal/modalAction';
import { useAppDispatch } from '../../../hooks/useAppDispatch';

 interface StyleProps { 
  minWidth?: number, 
  minHeight?: number,
}   
 interface Props extends StyleProps {
  isOpen?: boolean;
  children?: React.ReactNode | React.ReactNode[];
  title: string;
  name: string
}
 
const useStyles = makeStyles({
  
  closeButton: {
    position: 'absolute',
    right: 0,
    top: '50%',
    transform: 'translateY(-50%)',
    marginLeft: 15,
    marginRight: 15,
  },
  modal: {
    width: (props: StyleProps) => props.minWidth,
    minHeight: (props: StyleProps) =>props.minHeight,
    backgroundColor: '#EFEFEF',
  },
  modalHeader: {
    position: 'relative',
    background: '#DDDDDD',
    padding: '10px 15px',
  },
  modalTitle: {
    fontSize: 16,
    fontWeight: 700,
    lineHeight: '20px',
    textTransform: 'uppercase',
    padding: 0,
  },
  modalFooter: {
    padding: '10px 15px',
    background: '#DDDDDD',
  },
  modalActions: {
    padding: 0,
  },
  modalContent: {
    padding: 0,
  },
  
});

export const Modal: React.FC<Props> = ({
  minWidth = 960,
  minHeight = 800,
  title, 
  children,
  name
}): JSX.Element => {
  const classes = useStyles({minWidth, minHeight});

  const {activeModal} = useAppSelector(state => state.modal);
  const dispatch = useAppDispatch();
 
  const isVisible = activeModal === name;


  const onClose = () => dispatch(closeModalAction()); 
 
  return (  
    <Dialog  
      classes={{paper: classes.modal}}
      maxWidth={false}
      open={isVisible}
      onClose={onClose}  
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      disableEnforceFocus
      
    > 
      <div className={classes.modalHeader}>
        <DialogTitle 
          disableTypography  
          className={classes.modalTitle}  
        >
          {title} 
        </DialogTitle> 
        <IconButton 
          className={classes.closeButton} 
          onClick={onClose} 
          disableRipple
          disableFocusRipple
          size={'small'}
        >
          <CloseIcon />
        </IconButton>
      </div>
      
      <DialogContent classes={{root: classes.modalContent}}>
        {children}
      </DialogContent>
      <div className={classes.modalFooter}>
        <DialogActions classes={{root: classes.modalActions}}>
          <Button variant="contained" color="primary">OK</Button>
        </DialogActions>
      </div>
    </Dialog>    
  );
};
