import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import { DefaultDataGrid } from '../common/DefaultDataGrid/DefaultDataGrid';
import { getWaterflowData } from '../../store/selectors/charts/waterflow/waterflowSelector';
import { useAppSelector } from '../../hooks/useAppSelector';
import { GridRowId, GridRowParams } from '@material-ui/data-grid';
import { head } from 'lodash';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { selectWaterflowChartAction } from '../../store/actions/charts/waterflow/waterflowAction';
import { SelectedWaterflowItem } from '../../store/types/charts/waterflow';

const useStyles = makeStyles({
  tableContainer: {
    padding: '30px 15px',
  },
  table: {
    width: '100%',
    height: 500
  },
});

const WaterTable = (): JSX.Element => {
  const [selectionModel, setSelectionModel] = useState<GridRowId[]>([]);
  const waterflowData = useAppSelector(getWaterflowData);
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const { t } = useTranslation();

  const headers = [
    {field: 'ctrl', headerName: t('CTRL'), width: 80},
    {field: 'loopName', headerName: t('LOOP_NAME'), width: 130},
    {field: 'min', headerName: t('MIN'), width: 80},
    {field: 'max', headerName: t('MAX'), width: 80},
    {field: 'water1', headerName: t('WATER1'), width: 100},
    {field: 'water2', headerName: t('WATER2'), width: 100},
    {field: 'water3', headerName: t('WATER3'), width: 100},
    {field: 'water4', headerName: t('WATER4'), width: 100},
    {field: 'water5', headerName: t('WATER5'), width: 100},
    {field: 'water6', headerName: t('WATER6'), width: 100},
  ];

  useEffect(() => {
    const firstWaterflowItem = head(waterflowData) || null;

    if (firstWaterflowItem) {
      dispatch(selectWaterflowChartAction(firstWaterflowItem));
      setSelectionModel([firstWaterflowItem.id]);
    }

  }, [waterflowData, dispatch]);


  const handleClickRow = ({id, row}: GridRowParams) => {
    setSelectionModel([id]);
    dispatch(selectWaterflowChartAction(row as SelectedWaterflowItem));
  };

  return (
    <div className={classes.tableContainer}>
      <div className={classes.table}>
        <DefaultDataGrid 
          isUppercaseTitle
          title={`${t('WATERFLOW_SETPOINTS')} / l / ${t('MIN')}`}
          rows={waterflowData}  
          columns={headers}
          selectionModel={selectionModel}
          onRowClick={handleClickRow}
          enablePointerOnRowHover
        /> 
      </div> 
    </div>
  );
};

export default WaterTable;