import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles} from '@material-ui/core/styles';
import { Formik, Form, Field} from 'formik';
import * as Yup from 'yup';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import { uniq } from 'lodash';
import { VALIDATION_MESSAGE } from '../../constants/ValidationTypes/validationTypes';
import { ValidationErrorMessage } from '../common/ValidationErrorMessage/ValidationErrorMessage';
import { useTranslation } from 'react-i18next';
import { FormValuesRotationMoment } from '../../interfaces/IRotationMomentValue';
import { DefaultDataGrid } from '../common/DefaultDataGrid/DefaultDataGrid';
import { TemperaturesChart } from '../../components/charts/TemperaturesChart/TemperaturesChart';

const useStyles = makeStyles({   
  title: {   
    fontSize: '14px', 
    fontWeight: 'bold',
    paddingBottom: '10px' 
  }, 
  text: {
    color: '#000',  
    fontSize: '14px',
    lineHeight: '16px', 
    height: '100%',
    marginTop: '10px'
  },
  inputs: {
    width: '100%',
    '& .MuiInputBase-input': {
      background: '#fff',
      border: '0.5px solid #979797',
      boxSizing: 'border-box',
      borderRadius: '4px',
      padding: '20px 15px',
      color: 'black',
      letterSpacing: '0.5px',
      fontFamily: 'Arial',
      fontSize: '14px',
    },
    '& .MuiSelect-select': {
      padding: '10px 15px',
    }    
  },
  validText: {
    color: 'red',
  },
  buttonSubmit: {
    background: '#0076BD',
    boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24)',
    borderRadius: '2px',
    marginTop: '8px',
    textTransform: 'capitalize',
  },
  wrappTable: {
    marginTop: '30px',
  },
  checkBoxWrapp: { 
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    alignItems: 'flex-tart', 
  },
  checkBoxText: { 
    display: 'flex',
    alignItems: 'center',
    textTransform: 'capitalize',
    minWidth: '110px', 
  },
  checkBox: { 
    '& .MuiIconButton-label': { 
      border: '3px solid #3B3E43',
      borderRadius: '4px',
    }
  },
  checkedBox: {
    width: '16px',
    height: '16px',  
    background: '#0076BD', 
  },
  iconCheckBox: {
    width: '16px',
    height: '16px',
    borderRadius: '4px',
  },
  table: {
    width: '100%',
    height: 300
  }
});

 
const initialValue: FormValuesRotationMoment = {
  time: ''
};



const dataTime = [
  {
    id: 1,
    time: 5613,
    DrSegm2oben: 12.5989,
    DrSegm2unten: 14.9917,
    DrSegm3oben: 21.0003,
    DrSegm3unten: 22.2959,
    DrSegm4oben: 24.0938,
    DrSegm4unten: 20.9408
  },
  {
    id: 2,
    time: 5612,
    DrSegm2oben: 12.5989,
    DrSegm2unten: 14.9917,
    DrSegm3oben: 21.0003,
    DrSegm3unten: 22.2959,
    DrSegm4oben: 24.0938,
    DrSegm4unten: 20.9408
  },
  {
    id: 3,
    time: 5613,
    DrSegm2oben: 12.5989,
    DrSegm2unten: 14.9917,
    DrSegm3oben: 21.0003,
    DrSegm3unten: 22.2959,
    DrSegm4oben: 24.0938,
    DrSegm4unten: 20.9408
  },
  {
    id: 4,
    time: 5612,
    DrSegm2oben: 12.5989,
    DrSegm2unten: 14.9917,
    DrSegm3oben: 21.0003,
    DrSegm3unten: 22.2959,
    DrSegm4oben: 24.0938,
    DrSegm4unten: 20.9408
  },
  {
    id: 5,
    time: 5613,
    DrSegm2oben: 12.5989,
    DrSegm2unten: 14.9917,
    DrSegm3oben: 21.0003,
    DrSegm3unten: 22.2959,
    DrSegm4oben: 24.0938,
    DrSegm4unten: 20.9408
  },
  {
    id: 6,
    time: 5612,
    DrSegm2oben: 12.5989,
    DrSegm2unten: 14.9917,
    DrSegm3oben: 21.0003,
    DrSegm3unten: 22.2959,
    DrSegm4oben: 24.0938,
    DrSegm4unten: 20.9408
  },
  {
    id: 7,
    time: 5613,
    DrSegm2oben: 12.5989,
    DrSegm2unten: 14.9917,
    DrSegm3oben: 21.0003,
    DrSegm3unten: 22.2959,
    DrSegm4oben: 24.0938,
    DrSegm4unten: 20.9408
  },  
]; 
 



function initState(dataTime: {[key: string]: number}[]): {[key: string]: boolean} {
  return uniq(dataTime.map((item: {[key: string]: number}) =>Object.keys(item)).flat())
    .slice(2)
    .map((item: string) => ({ [item]: false }))
    .reduce((acc, obj) =>({ ...acc, ...obj}), {});
}   


function uniqArrForCheckBox(initData: {[key: string]: number}[]) { 
  return uniq(initData.map((item: {[key: string]: number}) =>Object.keys(item)).flat()).slice(2);
} 
         
      
const RotationMoment: React.FC = () => { 
  const classes = useStyles({});
  const { t } = useTranslation();

  const [state, setState] = React.useState(()=>initState(dataTime));
  const handleChangeCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState(state => ({ ...state, [event.target.name]: event.target.checked }));
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSubmit = (values: FormValuesRotationMoment, {resetForm}: any): void => { 
    console.log(JSON.stringify(values, null, 2)); 
    resetForm({values: {   
      time: ''  
    }});  
  }; 


  const headers = [
    {field: 'time', headerName: t('TIME'), editable: true},
    {field: 'DrSegm2oben', headerName: 'DrSegm2oben', flex: 1, editable: true},
    {field: 'DrSegm2unten', headerName: 'DrSegm2unten', flex: 1, editable: true},
    {field: 'DrSegm3oben', headerName: 'DrSegm3oben', flex: 1, editable: true},
    {field: 'DrSegm3unten', headerName: 'DrSegm3unten', flex: 1, editable: true},
    {field: 'DrSegm4unten', headerName: 'DrSegm4unten', flex: 1, editable: true},
  ];

  
  const authShema = Yup.object().shape({
    time: Yup.number()
      .required(VALIDATION_MESSAGE.REQUIRED)
      .typeError(VALIDATION_MESSAGE.INTEGER_TYPE_ERROR)
      .positive(VALIDATION_MESSAGE.INTEGER_POSITIVE)
      .integer(VALIDATION_MESSAGE.INTEGER),
  });


  return (
    <Grid container alignItems="flex-start" spacing={1}>
      <Grid item lg={6}>
        <Grid container>
          <Grid
            item xs={12} sm={12}
            lg={12}
          >
            <Grid container>
              <Grid
                item xs={12} sm={12}
                lg={4}
              >
                <Formik
                  initialValues={initialValue}
                  onSubmit={handleSubmit}
                  validationSchema={authShema}
                >
                  {({values, handleChange}) =>{
                      
                    return (
                      <Form>
                        <Grid container spacing={1}>
                          <Grid
                            item xs={12} sm={12}
                            lg={6}
                            container
                            alignItems="center"
                          >
                            <Grid
                              item xs={12} sm={12}
                              lg={4}
                              container
                              className={classes.text}
                            >
                              <div className={classes.text}>{`${t('TIME')}:`}</div>
                            </Grid>
                            <Grid
                              item xs={12} sm={12}
                              lg={8}
                            >
                              <Field 
                                as={TextField} 
                                type="text"
                                className={classes.inputs} 
                                id="standard-basic" 
                                placeholder="90"
                                name="time"
                                value={values.time}
                                onChange={handleChange}
                                helperText={<ValidationErrorMessage name="time" />}
                              /> 
                            </Grid> 
                          </Grid>
                          <Grid
                            item xs={12} sm={12}
                            lg={6}
                            container
                            alignItems="flex-start"
                            justify="flex-start"
                          > 
                            <Button
                              className={classes.buttonSubmit} type="submit" variant="contained"
                              color="primary"
                            >{t('SET_TIME')}</Button>
                          </Grid> 
                        </Grid> 
                      </Form> 
                    );
                  }}
                </Formik>
              </Grid>
              <Grid 
                item lg={8} container
                alignItems="flex-start"
                justify="space-around" 
              > 
                {
                  dataTime && uniqArrForCheckBox(dataTime).map((el, index) => {
                    
                    return (    
                      <div className={classes.checkBoxWrapp} key={index}>
                        <Checkbox     
                          checked={state[el]}
                          onChange={handleChangeCheckBox}
                          name={el}   
                          color="primary"
                          className={classes.checkBox}
                          checkedIcon={<div className={classes.checkedBox}></div>}
                          icon={<div className={classes.iconCheckBox}></div>}
                        />  
                        <div className={classes.checkBoxText}>{el}</div>
                      </div> 
                    );   
                  })   
                } 
              </Grid>
            </Grid>
          </Grid>
          <Grid 
            item xs={12} sm={12}
            lg={12}
            className={classes.wrappTable}
          > 
            <div className={classes.table}>
              <DefaultDataGrid
                textAlignTitle={'left'} 
                title={t('MESSAGES')}
                columns={headers}  
                rows={dataTime}
              />
            </div>
          </Grid>  
        </Grid>   
      </Grid>
      <Grid item lg={6}>
        <div className={classes.title} >
          {t('ROTATION_MOMENT')}
        </div>
        {/* <Chart cordinates={[
          0.2, 0.1, 0.1, 0.1, 0.3, 0.2, 0.3, 0.1, 0.7, 0.3, 0.2, 0.2,
          0.3, 0.1, 0.3, 0.4, 0.3, 0.2, 0.3, 0.2, 0.4, 0.0, 0.9, 0.3,
          0.7, 1.1, 1.8, 1.2, 1.4, 1.2, 0.9, 0.8, 0.9, 0.2, 0.4, 1.2,
          0.3, 2.3, 1.0, 0.7, 1.0, 0.8, 2.0, 1.2, 1.4, 3.7, 2.1, 2.0,
          1.5   
        ]}
        />         */}
        <TemperaturesChart />
      </Grid>
    </Grid> 
  ); 
};

export default RotationMoment;
 