export const generateConstraintLines = (x: number | undefined, minY: number,  maxY: number, name: string, color: string, align = 'center'): any => {
  const alignState: {[key: string]: number} = {
    'center': 0,
    'left': -10,
    'right': 10
  };

  return {
    name,
    type: 'spline',
    color,
    maxWidth: 1,
    zIndex: 2,
    tooltip: {
      headerFormat: '',
      pointFormat: '{point.x}',
    },
    dataLabels: {
      enabled: false,
    },
    data: [
      {
        x,
        y: minY,
        dataLabels: {
          enabled: true,
          allowOverlap: true,
          color,
          align: 'left',
          formatter: function(): string {
            const that = this as any;
            return `${name} @ ${that.x.toFixed(2)} m `;
          },
          rotation: -90,
          x: alignState[align],
          y: -10,
        },
      },
      {
        x,
        y: maxY
      }
    ],
  };
};