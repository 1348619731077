import React, { useEffect, useMemo } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { defaultOptions } from './defaultOptions';
import { SolidificationSegmentsChart } from '../SolidificationSegmentsChart/SolidificationSegmentsChart';
import { getRollGap } from '../../../store/selectors/charts/rollgap/rollgapSelector';
import zip from 'lodash/zip';
import { fetchRollGapRequest } from '../../../store/actions/charts/rollGap/rollGapActions';
import segementMarker from '../../../assets/images/segement_symbol.png';
import coolMarker from '../../../assets/images/cool_symbol.png';
import { useDispatch, useSelector } from 'react-redux';
import { max } from 'lodash';
import { Spinner } from '../../common/Spinner/Spinner';
import { makeStyles } from '@material-ui/core';
import { getCommonLines } from '../../../store/selectors/charts/commonLines/commonLinesSelector';
import { getHeaderTopMenuBtnStatus } from '../../../store/selectors/header/headerSelector';
import { getStatusRightPanel } from '../../../store/selectors/rightPanel/rightPanel';
import { getStatusLeftPanel } from '../../../store/selectors/leftPanel/leftPanelSelector';

const useStyles = makeStyles({
  spinnerContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
  }
});

export const RollGapChart = (): JSX.Element => {
  const rollGapData = useSelector(getRollGap);
  const rollgapMaxLengthX = useMemo(() => max(rollGapData.data?.Length), [rollGapData.data?.Length]);
  const classes = useStyles();
  const dispatch = useDispatch();
  const commonLines = useSelector(getCommonLines);
  const topMenuIsHide = useSelector(getHeaderTopMenuBtnStatus); 
  const rightMenuIsHide = useSelector(getStatusRightPanel);
  const leftMenuIsHide = useSelector(getStatusLeftPanel);
  
  useEffect(() => {
    if (rollGapData.data === null) {
      dispatch(fetchRollGapRequest());
    }
  }, [dispatch, rollGapData.data]);

  const segmentSeries = useMemo(() => ({
    type: 'column',
    name: 'Segment',
    color: '#edc928',
    data: rollGapData.data?.SegmentGapCoorEnd && rollGapData.data?.SegmentGapCoorEnd.map(item => [item, 500]),
  }), [rollGapData.data?.SegmentGapCoorEnd]);

  const coolzonesSeries = useMemo(() => ({
    type: 'column',
    name: 'Coolzone',
    color: '#2d2efd',
    data: commonLines?.coolzonePasslineCoor && commonLines?.coolzonePasslineCoor?.map(item => [item, 500]),
  }), [commonLines?.coolzonePasslineCoor]);

  const rollgapsSeries = useMemo(() => ({
    type: 'line',
    name: 'Roll Gaps',
    color: '#8fbc8f',
    dataLabels: {
      enabled: true,
      backgroundColor: '#8fbc8f',
      borderRadius: 10,
      color: '#fff',
      y: -5,
      format: '{point.y:.2f}',
      style: {
        textOutline: 'none' 
      }
    },
    marker: {
      enabled: true,
      symbol: 'circle',
      radius: 5,
    },
    data: rollGapData.data?.RollGaps && zip(rollGapData.data?.SegmentGapCoorEnd, rollGapData.data?.RollGaps)
  }), [rollGapData.data?.SegmentGapCoorEnd, rollGapData.data?.RollGaps]);

  const segmentPointsSeries = useMemo(() => ({
    type: 'line',
    name: 'SegmentPoins',
    lineWidth: 0,
    marker: {
      enabled: true,
      symbol: `url(${segementMarker})`,
    },
    dataLabels: {
      enabled: true,
      allowOverlap: true,
      align: 'left',
      verticalAlign: 'middle',
      backgroundColor: 'transparent',
      style: {
        fontSize: 11,
      },
      formatter: function(): string {
        const that = this as any;
        return `Seg. ${that.point.index + 1}`;
      }
    },
    states: {
      hover: {
        enabled: false,
      }
    },
    data: rollGapData.data?.SegmentGapCoorEnd?.map(item => [item, 250]),
  }), [rollGapData.data?.SegmentGapCoorEnd]);

  const coolzonesPointsSeries = useMemo(() => ({
    type: 'line',
    name: 'CoolzonePoints',
    dataLabels: {
      enabled: true,
    },
    lineWidth: 0,
    marker: {
      enabled: true,
      symbol: `url(${coolMarker})`,
    },
    states: {
      hover: {
        enabled: false,
      }
    },
    data: commonLines?.coolzonePasslineCoor?.map((item, index) => ({
      x: item,
      y: 380,
      name: `S${index + 1}`,
      dataLabels: {
        enabled: true,
        format: `S${index + 1}-${index + 2}`,
        style: {
          fontWeight: 400
        }
      },
    }))
  }), [commonLines?.coolzonePasslineCoor]);

  const rollerPasslineMinSeries = useMemo(() => ({
    type: 'line',
    name: 'RollerPasslineMin',
    marker: {
      enabled: true,
      radius: 4,
      symbol: 'circle',
      fillColor: '#698fbd',
    },
    dataLabels: {
      allowOverlap: true,
      enabled: true,
      align: 'left',
      rotation: -90,
      y: -5,
      style: {
        fontWeight: '400',
      },
      color: '#000',
      formatter: function() {
        const that = this as any;
        return `${that.point.index + 1}`;
      }
    },
    lineWidth: 0,
    data: rollGapData.data?.RollPasslineCoor?.map(item => [item, 50]),
  }), [rollGapData.data?.RollPasslineCoor]);

  const rollerPasslineMaxSeries = useMemo(() => ({
    type: 'line',
    name: 'RollerPasslineMax',
    marker: {
      enabled: true,
      radius: 4,
      symbol: 'circle',
      fillColor: '#698fbd',
    },
    lineWidth: 0,
    data: rollGapData.data?.RollPasslineCoor?.map(item => [item, 175]),
  }), [rollGapData.data?.RollPasslineCoor]);

  const rollGapsOptions = useMemo(() => ({
    ...defaultOptions,
    xAxis: defaultOptions.xAxis.map(item => ({
      ...item,
      max: rollgapMaxLengthX && Math.floor(rollgapMaxLengthX),
    })),
    series: [segmentSeries, coolzonesSeries, rollgapsSeries],
  }), [segmentSeries, coolzonesSeries, rollgapsSeries, rollgapMaxLengthX]);

  const rollerGapsOptions = useMemo(() => ({
    chart: {
      height: 195,
    },
    series: [
      segmentSeries, 
      coolzonesSeries, 
      segmentPointsSeries, 
      rollerPasslineMinSeries,
      rollerPasslineMaxSeries,
      coolzonesPointsSeries,
    ]
  }), [
    segmentSeries, 
    coolzonesSeries, 
    segmentPointsSeries, 
    rollerPasslineMinSeries,
    rollerPasslineMaxSeries,
    coolzonesPointsSeries,
  ]);

  if (!rollGapData.data) {
    return <div className={classes.spinnerContainer}><Spinner /></div>;
  }

  const styles = topMenuIsHide ? { height: 'calc( 100vh - 50px - 260px )', minHeight: '460px'} : { height: 'calc( 100vh - 200px - 260px )', minHeight: '460px'};
  const chartKey = `${topMenuIsHide}${rightMenuIsHide}${leftMenuIsHide}`;

  return (
    <>
      <SolidificationSegmentsChart key={`${chartKey}_top`} otherOptions={rollerGapsOptions} />
      <HighchartsReact
        key={chartKey}
        containerProps={{ style: styles }}
        highcharts={Highcharts}  
        options={rollGapsOptions}
      /> 
    </>
  );
};
