import { LeftPanelAction, LeftPanelActionTypes, LeftPanelState } from '../../types/leftPanel';

const initState: LeftPanelState = {  
  mode: 'Casting', 
  heatID: '000.000',
  castLength: 571.73, 
  steelGrade: 'LC2',
  castingTemperature: [1557, 25.0],
  castDimensiton: [1.800, 250.0],
  solidificationLength: 25.84,
  speed: 1.2,
  spraywater: 3548,
  moldWaterVolume: [8000, 1000],
  moldWaterTemperature: [7.90, 7.70],
  level: 100,
  isOpen: false,
};    
  
export const leftPanelReducer = (state = initState, action: LeftPanelAction): LeftPanelState =>{
  switch (action.type) {
  case LeftPanelActionTypes.SET_IS_OPEN:
    return {...state, isOpen: action.payload};
  case LeftPanelActionTypes.SET_CAST_LENGTH:
    return {...state, castLength: action.payload};
  case LeftPanelActionTypes.SET_SPEED:
    return {...state, speed: action.payload};
  case LeftPanelActionTypes.SET_SPRAYWATER:
    return {...state, spraywater: action.payload};
  case LeftPanelActionTypes.SET_CASTING_TEMP: 
    return {...state, castingTemperature: action.payload};
  case LeftPanelActionTypes.SET_MOLD_WATER_TEMP: 
    return {...state, moldWaterTemperature: action.payload};
  case LeftPanelActionTypes.SET_SOLIDIFICATION_LENGTH: 
    return {...state, solidificationLength: action.payload};
  default:
    return state;
  }
};  
