import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { defaultOptions } from './defaultOptions';
 
interface Props {
  otherOptions: any
}
  
export const WaterSpecificMiniChart = (props: Props): JSX.Element => {
  const options = {...defaultOptions, ...props.otherOptions};

  return (
    <div className="chart-without-yAxis">
      <HighchartsReact
        highcharts={Highcharts}   
        options={options}
      /> 
    </div>
  );
};
