import React from 'react';
import { DefaultTabs } from '../../components/common/DefaultTabs/DefaultTabs';
import { DefaultTabPanel } from '../../components/common/DefaultTabs/DefaultTabPanel/DefaultTabPanel';
import { Parameter } from '../../components/Parameter/Parameter';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { DefaultDataGrid } from '../common/DefaultDataGrid/DefaultDataGrid';

enum AboutTabsID {
  PRODUCT_INFO = 'product-info',
  USER_INFO = 'user-info'
}
 
interface IUser {
  id: number;
  username: string;
  name: string;
  dialogName: string;
  activated: string;
  finished: string;
  comment: string;
}

const useStyles = makeStyles(theme => ({
  aboutWrapp: {
    margin: 20,
  },
  table: {
    height: 500,
    width: '100%'
    
  },
  userPanel: {
    marginBottom: '30px',
  },
  userPanelTitle: {
    marginBottom: 25,
    fontSize: 16,
    fontWeight: 500,  
    color: theme.palette.primary.main,
  },
  userParameterBox: {
    width: 330,
    marginBottom: 10,
  }
}));

export const About: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation(); 

  const onEditCellChangeCommitted = React.useCallback((params) => {
    console.log(params);
  }, []);

  const tabs = [
    {id: AboutTabsID.USER_INFO, label: 'User-Info'},
  ];

  const userParameters = [
    {label: 'MACHINE', value: 'DESKTOP'},
    {label: 'WORKSTATION', value: '-'},
    {label: 'LOGIN_TIME', value: '25.03.2019 12:34:56'},
  ];

  const headers = [
    {field: 'username', headerName: t('USER_NAME'), flex: 1, editable: true},
    {field: 'name', headerName: t('NAME'), flex: 1, editable: true},
    {field: 'dialogName', headerName: t('DIALOG_NAME'), flex: 1, editable: true},
    {field: 'activated', headerName: t('ACTIVATED'), flex: 1, editable: true},
    {field: 'finished', headerName: t('FINISHED'), flex: 1, editable: true},
    {field: 'comment', headerName: t('COMMENT'), flex: 1, editable: true},
  ]; 
   
  const usersItems: IUser[] = [
    { 
      id: 1,
      username: 'admin',
      name: 'admin',
      dialogName: 'admin',
      activated: '21.02.2020',
      finished: '25.02.2020',
      comment: 'comment'
    },
  ];

  return (

    <DefaultTabs
      tabs={tabs}
      defaultActive={AboutTabsID.USER_INFO}
      renderContent={(active) => (
        <div className={classes.aboutWrapp}>
          <DefaultTabPanel tabID={AboutTabsID.USER_INFO} active={active}>
            <div className={classes.userPanel}>
              <div className={classes.userPanelTitle}>{t('GUEST')}</div>
              <div> 
                {
                  userParameters.map((parameter, index) => (
                    <div key={index} className={classes.userParameterBox}>
                      <Parameter label={parameter.label} value={parameter.value} />
                    </div>
                  ))
                } 
              </div>
            </div>
            <div className={classes.table}>
              <DefaultDataGrid  
                title={'logbook message - table'}
                isUppercaseTitle
                textAlignTitle={'left'}
                rows={usersItems} 
                columns={headers}
                onEditCellChangeCommitted={onEditCellChangeCommitted}
              /> 
            </div>
          </DefaultTabPanel>  
        </div>
      )}
    />
    
  );
};