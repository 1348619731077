import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { Dispatch } from 'redux';

const baseUrl = 'https://hmi-back.azurewebsites.net/simulationHub';
export default class Socket {

  public connection: HubConnection | null = null;

  private build(): HubConnection {
    return new HubConnectionBuilder()
      .withUrl(baseUrl)
      .withAutomaticReconnect([2000])
      .build();
  }

  public start(): Promise<void> {
    this.connection = this.build();
    return this.connection.start();
  }

  public emmit(name: string, values: string, minRandValue: number, maxRandValue: number): Promise<void> {
    if (!this.connection) {
      return Promise.prototype;
    }
    return  this.connection.invoke(name, values, minRandValue, maxRandValue);
  }

  public on(dispatch: Dispatch<any>, action: any, name: string): void {
    this.connection?.on(name, 
      function(data) {
        dispatch(action(data));
      }
    );
  }

  public disconnect(): void {
    this.connection?.stop();
  }

}
