import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { IRoute } from './interfaces/IRoute';

interface Props { 
  routes: IRoute[];
}

const Routes: React.FC<Props> = ({routes}) => {
  return (
    <Switch>
      {
        routes.map(route => (
          <Route 
            path={route.path}
            key={route.title}
            exact={route.exact}
            render={() => route.render}
          />
        ))
      }
      <Route path="*" render={() => <div>404</div>} />
    </Switch>
  );
};

export default Routes;
