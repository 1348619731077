import React, { useMemo } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { SolidificationArrowChart } from '../SolidificationArrowChart/SolidificationArrowChart';
import { options as defaultOptions } from './options';
import { defaultOptions as optionsArrowChart } from '../SolidificationArrowChart/defaultOptions'; 
import { useSelector } from 'react-redux';
import { getScaleCompositionChart } from '../../../store/selectors/charts/scaleComposition/scaleCompositionSelector';
import {
  getSolidificationArrowChartLine1,
  getSolidificationArrowChartLine2,
  getSolidificationArrowChartLine3,
  getSolidificationArrowChartLine4,
  getSolidificationArrowChartLine5,
  // getSolidificationArrowChartArrow,
} from '../../../store/selectors/charts/solidificationArrowChart/solidificationArrowChartSelector';
import zip from 'lodash/zip';
import max from 'lodash/max';
// import { generateConstraintLines } from '../../../utils/charts/generateConstraintLines';
import segementMarker from '../../../assets/images/segement_symbol_pink.png';
// import { updateLinesByParts } from '../../../utils/charts/updateLines';
// import { setScaleFayalitData, setScaleWustitData, setScaleMagnetitData, setScaleHematitData } from '../../../store/actions/charts/scaleComposition/scaleCompositionActions';e
import NavigationTwoToneIcon from '../../../assets/images/arrow_solid_mini_chart.svg';
import coolMarker from '../../../assets/images/cool_symbol.png';
import { getHeaderTopMenuBtnStatus } from '../../../store/selectors/header/headerSelector';
import { getStatusRightPanel } from '../../../store/selectors/rightPanel/rightPanel';
import { getStatusLeftPanel } from '../../../store/selectors/leftPanel/leftPanelSelector';

export const ScaleCompositionChart = (): JSX.Element => {
  // const dispatch = useDispatch();

  const scaleCompositionData = useSelector(getScaleCompositionChart);
 
  const lengthX = scaleCompositionData.lengthX;
  const maxLengthX = useMemo(()=> max(lengthX), [lengthX]);
  const straighteningAreaData = scaleCompositionData.straighteningArea;
  const coolZoneData = scaleCompositionData.coolZones;
  const segmentLineData = scaleCompositionData.segmentLine;
  const segmentsData = scaleCompositionData.segments;
  // const solidificationData = scaleCompositionData.solidification;
  // const warningData = scaleCompositionData.warning;
  // const warmData = scaleCompositionData.warm;
  // const endOfStrandGuideData = scaleCompositionData.endOfStrandGuide;
  
  // solidificationArrowChartSelector
  const line1MiniChartData = useSelector(getSolidificationArrowChartLine1);
  const line2MiniChartData = useSelector(getSolidificationArrowChartLine2);
  const line3MiniChartData = useSelector(getSolidificationArrowChartLine3);
  const line4MiniChartData = useSelector(getSolidificationArrowChartLine4);
  const line5MiniChartData = useSelector(getSolidificationArrowChartLine5);
  // const arrowMiniChartData = useSelector(getSolidificationArrowChartArrow);

  const topMenuIsHide = useSelector(getHeaderTopMenuBtnStatus);
  const rightMenuIsHide = useSelector(getStatusRightPanel);
  const leftMenuIsHide = useSelector(getStatusLeftPanel);


  const coolzonesPointsSeriesChart = useMemo(() => ({
    type: 'line',
    name: 'CoolzonePoints',
    dataLabels: {
      enabled: true,
    },
    lineWidth: 0,
    marker: {
      enabled: true,
      symbol: `url(${coolMarker})`, 
    },
    states: {
      hover: {
        enabled: false,
      }
    },
    data: scaleCompositionData.coolZone?.map((item) => ({
      x: item.value,
      y: 615,
      name: item.label,
      dataLabels: {
        format: item.label,
        style: { 
          fontWeight: 400
        }
      }, 
    }))
  }), [scaleCompositionData.coolZone]);

  const lineScaleFayalit1 = useMemo(() => ({
    name: 'Scale Fayalit',
    type: 'spline',
    color: '#ff0000',
    data: [[2, 700], [15, 900]],
    yAxis: 0
  }), []); 

  const lineScaleFayalit = useMemo(() => ({
    name: 'Scale Fayalit',
    type: 'spline',
    color: '#ff0000',
    data: scaleCompositionData.scaleFayalit && zip(lengthX, scaleCompositionData.scaleFayalit),
    yAxis: 1
  }), [scaleCompositionData.scaleFayalit, lengthX]); 

  const lineStraighteningArea = useMemo(() => ({
    name: 'Straightening Area',
    type: 'column',
    color: '#ff0077', 
    opacity: 0.4,
    pointWidth: 50,
    zIndex: -99,
    data: [[straighteningAreaData, 1600]],
    yAxis: 0

  }), [straighteningAreaData]);

  const coolZone = useMemo(() => ({
    name: 'coolZone',
    type: 'column',
    color: '#2f6fed', 
    maxPointWidth: 1, 
    zIndex: -98,
    data: coolZoneData,
    yAxis: 0

  }), [coolZoneData]);

  const segmentLine = useMemo(() => ({
    name: 'Segment Line',
    type: 'column',
    color: '#edc928',
    maxPointWidth: 0.1, 
    zIndex: -99,
    data: segmentLineData,
    yAxis: 0

  }), [segmentLineData]);

  const segmentsIcons = useMemo(() => ({
    name: 'Segments',
    color: '#BA0392',
    type: 'line',
    lineWidth: 0,
    marker: {
      enabled: true,
      symbol: `url(${segementMarker})`,
    },
    zIndex: 99,
    states: {
      hover: {
        enabled: false,
      }
    },
    data: segmentsData?.map((data, index) => ({
      type: 'spline',
      x: data,
      y: 620,
      dataLabels: {
        enabled: true, 
        color: '#000',
        align: 'left',
        verticalAlign: 'middle',
        format: `seg. ${index + 1}`,
        shape: null,
        style: {
          fontWeight: 400,
        }
      },
    })),
    yAxis: 0

  }), [segmentsData]);

  // const solidificationLineSeries = useMemo(() => {
  //   return generateConstraintLines(solidificationData!, 600, 1600, 'Solidification', '#BA0392', 'left');
  // }, [solidificationData]);
  // const warningLineSeries = useMemo(() => {
  //   return generateConstraintLines(warningData!, 600, 1600, 'Warning', '#BA0392');
  // }, [warningData]);
  // const warmLineSeries = useMemo(() => {
  //   return generateConstraintLines(warmData!, 600, 1600, 'Warm', '#BA0392');
  // }, [warmData]);
  // const endOfStrandLineSeries = useMemo(() => {
  //   return generateConstraintLines(endOfStrandGuideData!, 600, 1600, 'End Of Strand Guide', '#000000');
  // }, [endOfStrandGuideData]);

  const tempDistributionOptions = useMemo(() => ({
    ...defaultOptions,
    xAxis: defaultOptions.xAxis.map(item => ({
      ...item,
      max: maxLengthX && Math.round(maxLengthX),
    })),
    series: [
      coolzonesPointsSeriesChart,
      lineScaleFayalit1,
      lineScaleFayalit,
      lineStraighteningArea,
      // solidificationLineSeries,
      // warningLineSeries,
      // warmLineSeries,
      // endOfStrandLineSeries,
      coolZone,
      segmentLine,
      segmentsIcons,
    ],
  }), 
  [
    coolzonesPointsSeriesChart,
    lineScaleFayalit1,
    lineScaleFayalit,
    lineStraighteningArea,
    // solidificationLineSeries,
    // warningLineSeries,
    // warmLineSeries,
    // endOfStrandLineSeries,
    maxLengthX,
    coolZone,
    segmentLine,
    segmentsIcons
  ]);

  const line1 = useMemo(() => ({
    name: 'line1',
    type: 'spline',
    color: '#fffd8f', 
    lineWidth: 30,
    data: line1MiniChartData.map(data => [data, 1050])
  }), [line1MiniChartData]);

  const line2 = useMemo(() => ({
    name: 'line2',
    type: 'spline',
    color: '#ebde7f',
    lineWidth: 30,
    data: line2MiniChartData.map(data => [data, 1050])
  }), [line2MiniChartData]);

  const line3 = useMemo(() => ({
    name: 'line3',
    type: 'spline',
    color: '#94602f',
    lineWidth: 30,
    zIndex: 2,
    data: line3MiniChartData.map(data => ({
      x: data,
      y: 1050,
      marker: {
        enabled: true,
        symbol: `url(${NavigationTwoToneIcon})`,
      }
    }))
  }), [line3MiniChartData]);

  const line4 = useMemo(() => ({
    name: 'line4',
    type: 'spline', 
    color: '#ebde7f',
    zIndex: 1,
    lineWidth: 30,
    data: line4MiniChartData.map(data => [data, 1050])
      
  }), [line4MiniChartData]);

  const line5 = useMemo(() => ({
    name: 'line5',
    type: 'spline',
    color: '#43a894',
    lineWidth: 30,
    data: line5MiniChartData.map(data => [data, 950])
  }), [line5MiniChartData]);


  // const arrowMiniChart = useMemo(() => ({
  //   name: 'arrow',
  //   type: 'spline',
  //   color: '#43a894',
  //   lineWidth: 30,
  //   data: {
  //     x: arrowMiniChartData,
  //     y: 1050,
  //   }
  // }), [arrowMiniChartData]);


  const temperaturesMiniChartOptions = useMemo(() => ({
    ...optionsArrowChart,
    title: {
      ...optionsArrowChart.title,
      text: 'Scale-Composition'
    },
    series: [
      segmentLine,
      coolZone,
      line1,
      line2,
      line3,
      line4,
      line5,
      
    ]
  }), [
    segmentLine,
    coolZone,
    line1,
    line2,
    line3,
    line4,
    line5,
    
  ]);

  const styles = topMenuIsHide ? { height: 'calc( 100vh - 50px - 260px )', minHeight: '500px'} : { height: 'calc( 100vh - 200px - 260px )', minHeight: '500px'};
  const chartKey = `${topMenuIsHide}${rightMenuIsHide}${leftMenuIsHide}`;

  return ( 
    <>
    
      <SolidificationArrowChart key={`${chartKey}_top`} otherOptions={temperaturesMiniChartOptions} />
      <div className="temperatureChart">
        <HighchartsReact 
          key={chartKey}
          containerProps={{ style: styles }} 
          highcharts={Highcharts}  
          options={tempDistributionOptions} 
        /> 
      </div>
    </>
  );
};
  