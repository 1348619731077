import {all, AllEffect} from 'redux-saga/effects';
import {airLoopWatcher} from './airLoop/airLoopSaga';
import { commonWatcher } from './commonLines/commonLinesSaga';
import {softReductionWatcher} from './softReduction/softReduction';
import { shellWatcher } from './solidification/solidificationSaga';

export function* rootWatcher(): Generator<AllEffect<unknown>, void, unknown> {
  yield all([
    airLoopWatcher(),
    softReductionWatcher(),
    shellWatcher(),
    commonWatcher()
  ]);
} 