import React from 'react';
import { ErrorMessage } from 'formik';
import { makeStyles} from '@material-ui/core/styles';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles({
  validText: {
    color: (props: StyleProps) => props.colorText || '#ff0000',
    fontSize: '14px',
  },
});  

interface StyleProps {
    colorText?: string,
     
}
  
interface Props extends StyleProps{
    name: string,
    colorText?: string,
}

export const ValidationErrorMessage: React.FC<Props> = ({name, colorText}): JSX.Element => {

  const classes = useStyles({colorText});

  const { t } = useTranslation();

  return (
    <ErrorMessage
      name={name} 
      render={msg => <span className={classes.validText}>{t(msg)}</span>}
    />
  );
};

