
export type TypeWaterSpecificData = {
  CastingSpeedSetPoints: number[] | null,
  WaterSpecificFix: number[] | null,
  WaterSpecificLoose: number[] | null,
}

export interface DataWaterSpecificState {
  data: TypeWaterSpecificData | null,
  castingSpeedSetpoints: number[] | null,
  coolZones: {label: string, value: number}[];
}

export enum WaterSpecificActionsTypes {
  SET_WATER_SPECIFIC_DATA = 'SET_WATER_SPECIFIC_DATA',
  FETCH_WATER_SPECIFIC_REQUEST = 'FETCH_WATER_SPECIFIC_REQUEST'
}
 

type FetchWaterSpecificRequest = {
  type: WaterSpecificActionsTypes.FETCH_WATER_SPECIFIC_REQUEST
};

type SetWaterSpecificData = {
  type: WaterSpecificActionsTypes.SET_WATER_SPECIFIC_DATA,
  payload: TypeWaterSpecificData  
}
 
export type WaterSpecificAction = 
FetchWaterSpecificRequest |
SetWaterSpecificData;