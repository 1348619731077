import React, { useMemo } from 'react';
import Button from '@material-ui/core/Button';
import { Link as NavLink, useLocation } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import PrintIcon from '@material-ui/icons/Print';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import {useTranslation} from 'react-i18next';
import { IFooterLink } from '../../interfaces/IFooterLink';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { closeRightPanel } from '../../store/actions/rightPanel/rightPanelAction';
import ConfigParser from '../../utils/helper/configParser';

const LayoutFooter: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const footerIcons = [
    {name: 'Print', link: '/', icon: <PrintIcon />},
    {name: 'Photo', link: '/',  icon: <PhotoCameraIcon />},
  ];
  
  const config = ConfigParser.getInstance();
  const bottomPanelLinks: IFooterLink[] = useMemo(() => {
    return config.getBottomPanelLinks();
  }, [config]);

  const isActiveLink = (name: string) => location.pathname.includes(name);
  
  const handleClick = () => {
    dispatch(closeRightPanel());
  };
  
  return ( 
    <div className="layout-footer">
      <div className="container">
        <div className="layout-footer__inner">
          <div className="layout-footer__panel">
            <ul className="nav-links">
              {!!bottomPanelLinks && bottomPanelLinks.map(link =>
                <li className="nav-links__item" key={link.id}>
                  <Button
                    component={NavLink}
                    color="inherit"
                    to={link.url}
                    disabled={isActiveLink(link.name)}
                    className={classNames('nav-links__link', {active: isActiveLink(link.name)})}
                    onClick={handleClick}
                  >
                    {t(link.label)}
                  </Button>
                </li>
              )
              }
            </ul>
          </div>
          <div className="layout-footer__panel">
            <ul className="nav-links nav-links_md">
              {footerIcons.map((item, i) =>
                <li className="nav-links__item" key={i}>
                  <IconButton disabled color="inherit" component="span">
                    {item.icon}
                  </IconButton>
                </li>
              )
              }
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LayoutFooter;
