import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import { makeStyles} from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import SaveRoundedIcon from '@material-ui/icons/SaveRounded';
import IconButton from '@material-ui/core/IconButton';
import CachedRoundedIcon from '@material-ui/icons/CachedRounded';
import { DefaultDataGrid } from '../../common/DefaultDataGrid/DefaultDataGrid';

 
const useStyles = makeStyles({  
  wrapper: {  
    padding: '20px',
  },
  wrappTable: {
    marginTop: '20px',
  },
  topLineTable: {
    paddingTop: '20px' 
  },
  table: { 
    height: 500,
    width: '100%'
  }
    
}); 


 
export const UnitConversation: React.FC = (): JSX.Element => { 

  const [unit, setUnit] = React.useState('metric'); 

  const classes = useStyles({}); 

  const { t } = useTranslation(); 
   
  const handleChangeUnit = (event: React.ChangeEvent<{ value: unknown }>) => {
    setUnit(event.target.value as string);
  }; 

    
  const itemArr = [
    { 
      id: 1,
      process: 'Adaptayion Mode',
      INPUT: 'NONE',
      OUTPUT: 'NONE',
    },  
    { 
      id: 2,
      process: 'Adaptayion Mode',
      INPUT: 'NONE',
      OUTPUT: 'NONE',
    }, 
    { 
      id: 3,
      process: 'Adaptayion Mode',
      INPUT: 'NONE',
      OUTPUT: 'NONE',
    }, 
    {
      id: 4,
      process: 'ScnaerTemperature',
      INPUT: 'C',
      OUTPUT: 'C',
    },
    {
      id: 5,
      process: 'CasterName',
      INPUT: 'NONE',
      OUTPUT: 'NONE',
    },
    {
      id: 6,
      process: 'CasterLength',
      INPUT: 'm',
      OUTPUT: 'm',
    },
    {
      id: 7,
      process: 'CasterMode',
      INPUT: 'NONE',
      OUTPUT: 'NONE',
    },
    {
      id: 8,
      process: 'CasterSpeed',
      INPUT: 'm/mm',
      OUTPUT: 'm/mm',
    },
    {
      id: 9,
      process: 'Adaptayion Mode',
      INPUT: 'NONE',
      OUTPUT: 'NONE',
    },
    {
      id: 10,
      process: 'AmbietTemp',
      INPUT: 'C',
      OUTPUT: 'C',
    }, 
     
  ];  

 

  const headers = [
    {field: 'process', headerName: 'Process Parameter name', flex: 1, editable: true},
    {field: 'INPUT', headerName: 'INPUT', flex: 1, editable: true},
    {field: 'OUTPUT', headerName: 'OUTPUT', flex: 1, editable: true},
  ]; 

  const toolsButton = [
    {name: 'edit', icon: <EditRoundedIcon />, isDisabled: true},
    {name: 'save', icon: <SaveRoundedIcon />, isDisabled: true},
    {name: 'refresh', icon: <CachedRoundedIcon />, isDisabled: true},
  ];
   
  return (   
    <Grid className={classes.wrapper} container> 
      <Grid
        container alignItems="center" item
        lg={2}  
      > 
        <div>{t('MEASUREMENT')}</div> 
      </Grid>
      <Grid item lg={2}>  
        <Select 
          labelId="select-language" 
          id="select-language"
          value={unit}
          onChange={handleChangeUnit} 
        >  
          <MenuItem value={'metric'}>{t('METRIC')}</MenuItem>
          <MenuItem value={'celsius'}>{t('CELSIUS')}</MenuItem>
          <MenuItem value={'fahrenheit'}>{t('FAHRENHEIT')}</MenuItem>  
        </Select>
      </Grid>
      <Grid container className={classes.topLineTable} >
        <Grid 
          container
          justify="flex-end" 
          alignItems="center" 
          item 
          lg={6}
        >
          <div>{t('UNIT_CONVERSATION')}</div>
        </Grid> 
        <Grid 
          item
          container
          justify="flex-end"
          alignItems="center"
          lg={6}
        >
          {toolsButton.map((button, index) =>  
            <IconButton
              color="inherit" component="span"
              key={index}
              disabled={button.isDisabled} 
            >         
              {button.icon}       
            </IconButton>
          )}
        </Grid>
      </Grid>
      <Grid className={classes.wrappTable} item lg={12}>
        <div 
          className={classes.table}
        >
          <DefaultDataGrid
            rows={itemArr}  
            columns={headers}
          />
        </div>
      </Grid>      
    </Grid>
  );  
}; 




