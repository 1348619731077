import { combineReducers } from 'redux';
import { waterflowReducer, rollgapReducer,  strandShellThicknessReducer, tempDistributionReducer, temperaturesChartReducer, solidificationArrowChartReducer, airPressureReducer, WaterSpecificReducer, scaleCompositionChartReducer, commonLinesReducer } from './charts';
import { shellReducer } from './charts/shell/shellReducer';
import { modalReducer } from './modal/modalReducer';
import { headerReducer } from './header/headerReducer';
import { leftPanelReducer } from './leftPanel/leftPanelReducer';
import { rightPanelReducer } from './rightPanel/rightPanelReducer';
import { parameterPanelReducer } from './parameterPanel/parameterPanelReducer';

export const rootReducer = combineReducers({
  modal: modalReducer,
  header: headerReducer,  
  leftPanel: leftPanelReducer,
  charts: combineReducers({ 
    waterflow: waterflowReducer,
    rollgap: rollgapReducer,
    strandShellThickness: strandShellThicknessReducer,
    tempDistribution: tempDistributionReducer,
    temperaturesChart: temperaturesChartReducer,
    solidificationArrowChart: solidificationArrowChartReducer,
    shell: shellReducer,
    airPressure: airPressureReducer,
    waterSpecific: WaterSpecificReducer,
    scaleComposition: scaleCompositionChartReducer,
    commonLines: commonLinesReducer
  }),
  rightPanel: rightPanelReducer,
  parameterPanel: parameterPanelReducer, 
});