import React, { useEffect, useMemo } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { defaultOptions } from './defaultOptions';
import { useDispatch, useSelector } from 'react-redux';
import { max } from 'lodash';
import { getAirPressureChart } from '../../../store/selectors/charts/airPressure/airPressureSelector';   
import { fetchAirPressureRequest } from '../../../store/actions/charts/airPressure/airPressureActions';
import { Spinner } from '../../common/Spinner/Spinner';
import { makeStyles } from '@material-ui/core';
import { getHeaderTopMenuBtnStatus } from '../../../store/selectors/header/headerSelector';
import { getStatusRightPanel } from '../../../store/selectors/rightPanel/rightPanel';
import { getStatusLeftPanel } from '../../../store/selectors/leftPanel/leftPanelSelector';

const useStyles = makeStyles({
  spinnerContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
  }
});

export const AirPressure = (): JSX.Element => {  
 
  const airPressure = useSelector(getAirPressureChart);
  const maxLengthX = useMemo(()=> max(airPressure.index), [airPressure.index]);
  const dispatch = useDispatch();
  const classes = useStyles();
  const topMenuIsHide = useSelector(getHeaderTopMenuBtnStatus);
  const rightMenuIsHide = useSelector(getStatusRightPanel);
  const leftMenuIsHide = useSelector(getStatusLeftPanel);


  useEffect(() => {
    if (airPressure.data === null) {
      dispatch(fetchAirPressureRequest());
    }
  }, [airPressure.data, dispatch]); 
  

  const pressureColumn = useMemo(() =>({
    name: 'Air Pressure',
    color: {
      linearGradient: { x1: 1, x2: 0, y1: 0, y2: 0 },
      stops: [
        [0, '#00256e'],
        [1, '#003bb0']
      ]
    },
    data: airPressure?.data?.Pressure.map((item: number, index: number) => (
      {
        type: 'column',
        x: airPressure.index && airPressure.index[index],
        y: item,
        dataLabels: {
          backgroundColor: '#003bb0',
          color: '#fff',
        },
      }
    )),
    yAxis: 0
  }), [airPressure.data?.Pressure, airPressure.index]);

  const amountColumn = useMemo(() =>({
    name: 'Air Flow',
    color: {
      linearGradient: { x1: 1, x2: 0, y1: 1, y2: 1 },
      stops: [
        [0, '#a3a3a3'],
        [1, '#dedede']
      ]
    },
    data: airPressure.data?.Amount.map((item: number, index: number) => (
      {
        type: 'column',
        x: airPressure.index && airPressure.index[index],
        y: item,
        dataLabels: {
          backgroundColor: '#dedede',
          color: '#000',
        },
      }
    )),
    yAxis: 1
  }), [airPressure.data?.Amount, airPressure.index]);

  const strandShellThicknessOptions = useMemo(() => ({
    ...defaultOptions,
    xAxis: defaultOptions.xAxis.map(item => ({
      ...item, 
      max: maxLengthX && Math.round(maxLengthX) + 1,
    })),
    series: [pressureColumn, amountColumn],
  }), [pressureColumn, amountColumn, maxLengthX]); 

  if (!airPressure.data) {
    return <div className={classes.spinnerContainer}><Spinner /></div>;
  }
 
  const styles = topMenuIsHide ? { height: 'calc( 100vh - 100px )', minHeight: '660px'} : { height: 'calc( 100vh - 260px )', minHeight: '660px'};
  const chartKey = `${topMenuIsHide}${rightMenuIsHide}${leftMenuIsHide}`;

  return ( 
    
    <div className="AirPressureChart">
      <HighchartsReact 
        key={chartKey}
        containerProps={{ style: styles }} 
        highcharts={Highcharts}  
        options={strandShellThicknessOptions}
      /> 
    </div> 
  );
};
