import { TemperaturesChartAction, TemperaturesChartActionsTypes, TypeTemperaturesChart, TypeTemperaturesChartStaticLines } from '../../../types/charts/temperaturesChart';
export const setTemperaturesChartData = (data: TypeTemperaturesChart): TemperaturesChartAction => ({
  type: TemperaturesChartActionsTypes.SET_TEMPERATURES_CHART_DATA,
  payload: data, 
}); 
 
export const fetchTemperaturesChartRequest = (): TemperaturesChartAction => ({
  type: TemperaturesChartActionsTypes.FETCH_TEMPERATURES_CHART_REQUEST,
});

export const setTemperaturesChartStaticLinesData = (data: TypeTemperaturesChartStaticLines): TemperaturesChartAction => ({
  type: TemperaturesChartActionsTypes.SET_TEMPERATURES_CHART_STATIC_LINES_DATA,
  payload: data, 
}); 
 
export const fetchTemperaturesChartStatiLinesRequest = (): TemperaturesChartAction => ({
  type: TemperaturesChartActionsTypes.FETCH_TEMPERATURES_CHART_STATIC_LINES_REQUEST,
});